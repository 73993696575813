import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  ViewChild,
} from '@angular/core';
import SignaturePad from 'signature_pad';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

export interface SignaturePadComponentData {
  width: number;
  height: number;
}

export interface SignaturePadResult {
  svgPath: string;
  pngDataURL: string;
}

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent {
  @ViewChild('canvasSignArea') canvasSignArea: ElementRef<HTMLCanvasElement>;

  signaturePad: SignaturePad;

  showHint: boolean = false;

  width: number;
  height: number;
  aspectRatio: number;

  constructor(
    public dialogRef: MatDialogRef<SignaturePadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignaturePadComponentData
  ) {
    this.width = Math.min(window.innerWidth * 0.9, 600);
    this.aspectRatio = this.data.width / this.data.height;
    this.height = this.width / this.aspectRatio;
  }

  // Adjust canvas coordinate space taking into account pixel ratio, to make it look crisp on mobile devices.
  // This also causes canvas to be cleared.
  @HostListener('window:resize')
  resizeCanvas(): void {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvasSignArea.nativeElement.width =
      this.canvasSignArea.nativeElement.offsetWidth * ratio;
    this.canvasSignArea.nativeElement.height =
      this.canvasSignArea.nativeElement.offsetHeight * ratio;
    this.canvasSignArea.nativeElement.getContext('2d').scale(ratio, ratio);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.signaturePad = new SignaturePad(this.canvasSignArea.nativeElement, {
        minWidth: 1,
      });
    }, 0);
  }

  savePNG(): void {
    if (this.signaturePad.isEmpty()) {
      this.showHint = true;
      setTimeout(() => {
        this.showHint = false;
      }, 3000);
      return;
    }
    const data: SignaturePadResult = {
      pngDataURL: this.signaturePad.toDataURL(),
      svgPath: this.signaturePad.toSVG(),
    };
    this.dialogRef.close(data);
  }

  clear(): void {
    this.signaturePad.clear();
  }

  undo(): void {
    const data = this.signaturePad.toData();
    if (data) {
      // remove the last dot or line
      data.pop();
      this.signaturePad.fromData(data);
    }
  }
}
