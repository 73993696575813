<h1 mat-dialog-title translate>
  {{ data.title }}
</h1>

<div class="file-input">
  <ngx-dropzone (change)="onSelectFile($event)" [multiple]="false" [maxFileSize]="maxFileSize"
    [accept]="auth.isClient || !auth.loggedIn ? ALLOWED_FILE_STRING : '*'" [disabled]="uploadProgress !== -1">
    <ngx-dropzone-label>
      <mat-icon>file_upload</mat-icon>
      <span class="hint">{{
        "client.process.fileUpload.hint" | translate
        }}</span>
      <div class="more-info">
        <span>{{ "client.process.fileUpload.allowedFormats" | translate }}
          {{ auth.isClient || !auth.loggedIn ? 'pdf, png, jpeg' : '*' }}
        </span>
        <span>{{ "client.process.fileUpload.maxFileSize" | translate }}</span>
      </div>
    </ngx-dropzone-label>
    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onFileRemove($event)">
      <ngx-dropzone-label [class.isImage]="filePreview !== null">
        <div class="preview-image" *ngIf="filePreview">
          <img [src]="filePreview" />
        </div>
        <div class="filename-preview" *ngIf="!filePreview">
          <mat-icon>attachment</mat-icon><span>{{ f.name }}</span>
        </div>
      </ngx-dropzone-label>
    </ngx-dropzone-preview>
  </ngx-dropzone>
  <mat-progress-bar [mode]="uploadProgress === 0 ? 'indeterminate' : 'determinate'" [value]="uploadProgress * 100"
    *ngIf="uploadProgress !== -1"></mat-progress-bar>
</div>

<mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()" [disabled]="uploadProgress !== -1">
    {{ "common.button.abort" | translate }}
  </button>
  <button mat-raised-button (click)="saveFile()" [disabled]="files.length <= 0 || uploadProgress !== -1"
    cdkFocusRegionStart>
    <mat-icon> save </mat-icon>
    {{ "common.button.save" | translate }}
  </button>
</mat-dialog-actions>