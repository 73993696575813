import { ElementRef, Injectable } from '@angular/core';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';

@Injectable({
  providedIn: 'root',
})
export class AdornmentsService {
  private _header: HeaderComponent;
  private _footer: FooterComponent;

  public registerHeader(header: HeaderComponent) {
    this._header = header;
  }

  public registerFooter(footer: FooterComponent) {
    this._footer = footer;
  }

  public setHeaderTo(element: ElementRef<any>) {
    if (!this._header) return;
    this._header.replaceHeader(element);
  }

  public removeHeader(element: ElementRef<any>) {
    if (!this._header) return;
    this._header.removeHeader(element);
  }

  public hideFooter() {
    this._footer.hidden = true;
  }

  public showFooter() {
    this._footer.hidden = false;
  }

}
