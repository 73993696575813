<h1 mat-dialog-title>{{ "client.dashboard.downloadAnswer" | translate }}</h1>

<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>{{ "node.label.format" | translate }}</mat-label>
    <mat-select [formControl]="exportForm.get('format')">
      <mat-select-trigger class="format-select-trigger">
        <mat-icon *ngIf="
            exportForm.get('format').value?.icon &&
            exportForm.get('format').value?.icon[0] !== '/'
          ">{{ exportForm.get("format").value?.icon }}</mat-icon>
        <img class="adv-export-logo-icon" *ngIf="
            exportForm.get('format').value?.icon &&
            exportForm.get('format').value?.icon[0] === '/'
          " [src]="exportForm.get('format').value?.icon" />
        {{ exportForm.get("format").value?.label | translate }}
      </mat-select-trigger>
      <ng-container *ngFor="let option of exportOptions; let i = index">
        <mat-option *ngIf="i === 0 || !auth.isClient" [value]="option">
          <mat-icon *ngIf="option.icon && option.icon[0] !== '/'">{{
            option.icon
            }}</mat-icon>
          <img class="adv-export-logo-icon" *ngIf="option.icon && option.icon[0] === '/'" [src]="option.icon" />
          {{ option.label | translate }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
  <mat-checkbox [formControl]="exportForm.get('includeUploadedDocuments')" *ngIf="
      exportForm
        .get('format')
        .value?.supports?.includes('includeUploadedDocuments')
    ">
    {{ "common.label.exportUploadedDocuments" | translate }}
  </mat-checkbox>
  <!-- ToDo: Include message protocol in export -->
  <!-- <mat-checkbox [formControl]="exportForm.get('includeProtocol')" *ngIf="
      exportForm.get('format').value?.supports?.includes('includeProtocol')
    ">
    {{ "common.label.exportProtocol" | translate }}
  </mat-checkbox> -->
  <mat-form-field appearance="outline">
    <mat-label>{{ "common.label.exportName" | translate }}</mat-label>
    <input matInput [formControl]="exportForm.get('exportName')" />
  </mat-form-field>
  <button mat-raised-button (click)="export()" *ngIf="data?.service && data?.store">
    {{ "common.button.continue" | translate }}
  </button>
  <button mat-raised-button (click)="saveConfig()" *ngIf="!data?.service || !data?.store">
    {{ "common.button.save" | translate }}
  </button>
</mat-dialog-content>