/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnalyticsDataViewRequest } from './analyticsDataViewRequest';
import { DataViewTableDefinition } from './dataViewTableDefinition';
import { FullDataViewFields } from './fullDataViewFields';


export interface DataView { 
    definition?: DataViewTableDefinition;
    name?: string;
    override_definition?: DataViewTableDefinition;
    source?: string;
    type?: DataView.TypeEnum;
    view_mode?: string;
    cache_updated_at?: string;
    can_edit?: boolean;
    created_at?: string;
    created_by?: string;
    id?: string;
    override_updated_at?: string;
    updated_at?: string;
}
export namespace DataView {
    export type TypeEnum = 'STANDARD' | 'CACHED';
    export const TypeEnum = {
        Standard: 'STANDARD' as TypeEnum,
        Cached: 'CACHED' as TypeEnum
    };
}


