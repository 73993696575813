import { AvailableFilter } from 'advoprocess/lib/types/filter';

export const templatesFilters: AvailableFilter[] = [
  {
    id: 'template_name',
    label: 'lawyer.dashboard.templates.filter.name',
    internal_name: 'name',
    icon: 'label',
    type: 'string',
  },
];
