<div class="general-info">
  <span class="explanation">
    Dieser Nutzer fällt unter die folgenden Sicherheitsrichtlinien:
  </span>
  <ul>
    <li *ngFor="let permission of permissions; let i = index">
      <button mat-button (click)="focusPermissionIndex = i; buildPolicyTable()"
        [class.active]="focusPermissionIndex === i">
        <mat-icon>{{i !== 0 ? 'add_circle' : ''}}</mat-icon>
        <span>{{permission.name}}</span>
        <mat-icon *ngIf="focusPermissionIndex === i">visibility</mat-icon>
      </button>
      <app-search-menu [entries]="queryPermissions.bind(this)" (entrySelected)="setUserPermission($event, permission)">
        <button class="edit" mat-icon-button *ngIf="permission?.via === 'user' || entity === 'role'">
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
      </app-search-menu>
    </li>
    <li *ngIf="!userPermissions?.length">
      <app-search-menu style="width: 100%" [entries]="queryPermissions.bind(this)"
        (entrySelected)="setUserPermission($event)">
        <button mat-button style="width: 100%"><mat-icon>add</mat-icon>Sicherheitsrichtlinie hinzufügen</button>
      </app-search-menu>
    </li>
    <li>
      <hr />
    </li>
    <li>
      <button mat-button [class.active]="focusPermissionIndex === -1"
        (click)="focusPermissionIndex = -1; buildPolicyTable()">
        <mat-icon>subdirectory_arrow_right</mat-icon>
        <span>Alle Berechtigungen</span>
        <mat-icon *ngIf="focusPermissionIndex === -1">visibility</mat-icon>
      </button>
    </li>
  </ul>
</div>
<table class="permissions-table">
  <tr>
    <th></th>
    <th><mat-icon>visibility</mat-icon>Einsehen</th>
    <th><mat-icon>edit</mat-icon>Bearbeiten / Erstellen / Löschen</th>
  </tr>
  <tr *ngFor="let policy of policies">
    <td>{{('lawyer.permissions.models.' + policy.model) | translate }}</td>
    <td><ng-container
        *ngTemplateOutlet="editSinglePermission; context: {rule: policy.read, mode: 'read'}"></ng-container></td>
    <td><ng-container
        *ngTemplateOutlet="editSinglePermission; context: {rule: policy.write, mode: 'write'}"></ng-container></td>
  </tr>
</table>

<ng-template #editSinglePermission let-rule="rule" let-mode="mode">
  <div class="rule-edit disabled" *ngIf="!rule">
    <span class="rule-name not-specified">
      {{ (mode === 'write' ? 'lawyer.permissions.modes.FORBID' : 'lawyer.permissions.modes.ALLOW') | translate }}
      ({{ 'node.label.default' | translate }})
    </span>
  </div>
  <button mat-button class="rule-edit" [matMenuTriggerFor]="modeSelectMenu" *ngIf="rule" disabled>
    <ng-container *ngTemplateOutlet="modeIcon; context: {mode: rule.mode}"></ng-container>
    <span class="rule-name">
      {{('lawyer.permissions.modes.' + rule.mode) | translate}}
    </span>
    <!-- <mat-icon>arrow_drop_down</mat-icon> -->
  </button>
  <div class="rule-filters" *ngIf="rule?.mode === 'FILTER' && rule.filters">
    <app-search-breadcrumbs [filters]="rule.extendedFilters" [availableFilters]="rule.availableFilters"
      [initialJoinString]="'common.label.and'"></app-search-breadcrumbs>
  </div>
  <mat-menu #modeSelectMenu="matMenu">
    <button mat-menu-item *ngFor="let mode of possibleModes" (click)="setMode(rule, mode)">
      <ng-container *ngTemplateOutlet="modeIcon; context: {mode: mode}"></ng-container>
      <span>{{('lawyer.permissions.modes.' + mode) | translate}}</span>
    </button>
  </mat-menu>
</ng-template>

<ng-template #modeIcon let-mode="mode">
  <mat-icon *ngIf="mode === 'ALLOW'">done</mat-icon>
  <mat-icon *ngIf="mode === 'FORBID'">remove_circle</mat-icon>
  <mat-icon *ngIf="mode === 'FILTER'">filter_alt</mat-icon>
</ng-template>