import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, map, startWith, take } from 'rxjs';
import { PublicService } from 'src/api';
import * as _ from 'lodash';
import { Notification } from 'src/api/model/notification';
import { ApplicationHook, HookContext, HookNames } from './hooks';
import { AuthService } from '../auth/auth.service';
import { Subject } from 'rxjs';
import { setEnvironmentVar } from 'advoprocess/lib/environment';
import { AddOn, AddOnsService } from 'src/api';
import { environment } from 'src/environments/environment';

// Use this service to store information that should
// be available in the application context for logged in users

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  notifications: Notification[] = [];
  addonsFetched: Subject<AddOn[]> = new Subject<AddOn[]>();
  addons: (AddOn & { editing?: boolean })[] = [];

  hooks: { [key in HookNames]?: ApplicationHook[] } = {};

  private featureFlags: { [key: string]: any } = {};

  private flagsReady$ = new BehaviorSubject<boolean>(false);
  private hooksReady$ = new BehaviorSubject<boolean>(false);

  constructor(
    private publicService: PublicService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private addonApi: AddOnsService,
    private injector: Injector
  ) {
    if (this.auth.loggedIn && !this.auth.isClient && !this.addons?.length) {
      this.fetchAddons();
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          return this.activatedRoute?.children[0].snapshot.paramMap?.get(
            'realm'
          );
        }),
        startWith(this.activatedRoute?.children[0].snapshot.paramMap?.get(
          'realm'
        )),
        distinctUntilChanged(),
      ).subscribe((realm) => {
        this.initializeFeatureFlags(realm);
      })
  }

  private initializeFeatureFlags(realm: string) {
    if (!realm) {
      this.featureFlags = {};
      return;
    }
    this.publicService.getFeatureFlagsForRealm({
      realm
    }).subscribe((flags) => {
      this.featureFlags = flags.flags ?? {};
      const customLimits = flags.limits;
      if (customLimits.MAX_UPLOAD_SIZE_MB) {
        environment.MAX_UPLOAD_SIZE_MB = customLimits.MAX_UPLOAD_SIZE_MB;
      }
      if (customLimits.ALLOWED_FILE_TYPES) {
        environment.ALLOWED_FILE_FORMATS = customLimits.ALLOWED_FILE_TYPES;
      }
      this.flagsReady$.next(true);
    });
  }

  public async checkFeatureFlag(feature: string): Promise<boolean> {
    if (!this.flagsReady$.value) {
      await firstValueFrom(this.flagsReady$.pipe(filter(a => !!a)));
    }
    return this.checkFeatureFlagSync(feature);
  }

  public checkFeatureFlagSync(feature: string): boolean {
    const flag = _.get(this.featureFlags, feature);
    if (!_.isNil(flag)) return !!flag;
    return true;
  }

  /**
   * Fetch Addons.
   */
  fetchAddons() {
    this.addonApi.listAddons({}).subscribe((addons) => {
      this.addons = addons ?? [];
      this.addons.sort((a, b) => a.id - b.id);
      this.addonsFetched.next(addons);
      this.onLoadAddons();
    });
    setEnvironmentVar('getAddOns', () => {
      return this.addons ?? [];
    });
  }

  getAddonById(id: number) {
    return this.addons.find(a => a.id === id)
  }

  /**
   * Execute a specific action before or after an event in the application occurs.
   * The action gets activtiy specific parameters and can also return specific information to the
   * caller.
   * @param hookName The name of the hook for which to execute an action
   * @param hookHandler The handler to execute when the specific action occurs
   */
  hookInto(hookName: HookNames, hookHandler: ApplicationHook): void {
    if (!this.hooks[hookName]) {
      this.hooks[hookName] = [];
    }
    this.hooks[hookName].push(hookHandler);
  }

  /**
   * 
   * @param hookName The name of the activity
   * @param beforeAfter Is it before or after the activity has occured?
   * @param parameters Any parameters that the hook recipient would need.
   * @returns A promise of an array of HookResponses
   */
  triggerHook(hookName: HookNames, beforeAfter: 'before' | 'after' = 'before', parameters: { [key: string]: any }): void {
    const onHookActivate = () => {
      if (!this.hooks[hookName]) return;
      const context: HookContext = {
        hookName,
        parameters
      }
      this.hooks[hookName].forEach(h => h[beforeAfter]?.(context));
    };

    if (!this.hooksReady$.value) {
      this.hooksReady$.pipe(filter(r => !!r), take(1)).subscribe(() => onHookActivate());
      return;
    }
    onHookActivate();
  }

  private async onLoadAddons() {
    //Advoware Addon
    const advowareAddOn = this.addons.find(a => a.name.includes('Advoware') && !!a.active);
    if (advowareAddOn) {
      try {
        const module = await import('src/app/addons/advoware/advoware.module');
        await module.AdvowareModule.onInit(this.injector);
      } catch (err) {
        console.error('Error loading module:', err);
      }
    }
    this.hooksReady$.next(true);
  }
}
