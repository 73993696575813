import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, GuardsCheckStart, NavigationEnd, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject, from, Observable, of, timer } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { AuthorizationTokenWithExpiration } from 'src/api/model/authorizationTokenWithExpiration';
import { SecurityGuestService } from 'src/api/api/securityGuest.service';
import { WebSocketService } from '../common/websocket.service';
import Keycloak from 'keycloak-js';
import { environment } from 'src/environments/environment';
import { DestroyNotifier } from '../common/destroy-notifier';
import * as Sentry from '@sentry/angular-ivy';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends DestroyNotifier {
  jwtToken$: BehaviorSubject<string | undefined> = new BehaviorSubject(
    undefined
  );

  public keycloak: Keycloak;

  public ready$ = new BehaviorSubject<boolean>(false);

  public loggedIn: boolean = false;
  public userName: string = null;
  public userMail: string = null;
  public enabledNotifications: boolean = false;
  public rawDecoded: any = null;
  public isClient: boolean = false;
  public isGuest: boolean = false;
  public userId: string = '';

  constructor(
    private guestAPI: SecurityGuestService,
    private snackBar: MatSnackBar,
    private socket: WebSocketService,
    private injector: Injector,
    router: Router,
    activatedRoute: ActivatedRoute
  ) {
    super();
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd || event instanceof GuardsCheckStart),
        map((event) => {
          let sn: ActivatedRouteSnapshot = activatedRoute.snapshot;
          if (event instanceof GuardsCheckStart) {
            sn = event.state.root;
          }
          return (
            sn.firstChild?.paramMap?.get('realm') ??
            sn.queryParamMap?.get('realm')
          );
        }),
        startWith(sessionStorage.getItem('realm') ?? null),
        filter(realm => realm !== 'auth'),
        distinctUntilChanged(),
        skipWhile((realm) => !realm)
      )
      .subscribe((realm) => {
        if (!realm) {
          sessionStorage.removeItem('realm');
          this.ready$.next(true);
        } else {
          sessionStorage.setItem('realm', realm);
          this.initKeycloak(realm);
        }
      });
    this.jwtToken$
      .pipe(
        map((t) => Boolean(t)),
        distinctUntilChanged(),
        delay(50),
        map((_) => this.jwtToken$.value)
      )
      .subscribe((t) => {
        if (!t) {
          this.socket.disconnect();
        } else {
          this.socket.connect(this.isClient ? 'client' : 'lawyer', this.userId);
        }
      });
    timer(0, 10000).subscribe(() => {
      this.tryRefresh().catch(() => {
        this.logOut();
      });
    });
  }

  public initKeycloak(realm: string): Promise<boolean> {
    const keycloak = new Keycloak({
      url: environment.KEYCLOAK_URL,
      clientId: 'silberfluss-frontend',
      realm,
    });
    return keycloak
      .init({
        adapter: 'default',
        silentCheckSsoFallback: false,
        enableLogging: true,
        flow: 'standard',
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      })
      .then(() => {
        if (keycloak.token) {
          this.jwtToken$.next(keycloak.token);
        }
        this.keycloak = keycloak;

        this.updateJWTInfo();
        this.ready$.next(true);
        return keycloak.authenticated;
      })
      .catch((err) => {
        console.error(err);
        this.ready$.next(true);
        return false;
      });
  }

  private updateJWTInfo() {
    this.loggedIn = this.keycloak?.authenticated;
    if (!this.keycloak?.authenticated) {
      this.userName = this.rawDecoded = this.userMail = null;
      this.isClient = this.isGuest = false;
      this.userId = '';
      Sentry.setUser(null);
    } else {
      const decoded = this.keycloak.tokenParsed;
      if (decoded?.realm_access?.roles?.includes('technical-user')) {
        this.logOut();
      }
      this.userName = decoded.name;
      this.userMail = decoded.email;
      this.enabledNotifications = decoded.enabledNotifications;
      this.rawDecoded = decoded;
      this.isClient = !decoded.realm_access?.roles?.includes('lawyer') ?? false;
      this.isGuest =
        !!decoded.email?.match(/@guests\.[^\.]*\.silberfluss\.io$/gm) ?? false;
      this.userId = decoded.id;
      this.initLanguage();
      Sentry.setUser({
        email: this.isClient || this.isGuest ? null : this.userMail,
        id: this.userId,
        username: `${decoded.realm ?? ''}::${this.userId}`,
      });
    }
  }

  private initLanguage() {
    const translator = this.injector.get(TranslateService);
    if (!this.rawDecoded?.locale) {
      // Set initial user locale
      const targetLanguage = translator.currentLang === 'en' ? 'en' : 'de';
      console.log(`Setting initial account locale to ${targetLanguage}`);
      this.setCurrentUserLocale(targetLanguage).subscribe(() => { });
    } else {
      const preferredLanguage = this.rawDecoded?.locale ?? 'de';
      translator.use(preferredLanguage);
    }
  }

  logInGuest(
    token: string,
    clientId: string,
    realm: string
  ): Promise<string | boolean> {
    return new Promise(async (resolve) => {
      if (this.loggedIn) {
        resolve('common.login.error.sessionExists');
        return;
      }
      this.guestAPI
        .ottLogin({
          realm,
          oneTimeLinkAuth: {
            token,
            user_id: clientId,
          },
        })
        .pipe(
          catchError((err) => {
            if (err.error.error === 'Username or password are wrong') {
              resolve('common.login.error.generic');
            } else {
              resolve('common.login.error.unknown');
            }
            return of({ expiresin: '-1', token: null });
          })
        )
        .subscribe(
          async (token: AuthorizationTokenWithExpiration) => {
            const resp = await this.afterGuestAuth(token, realm);
            if (resp !== null) {
              this.snackBar.open('ERROR');
              return;
            }
            resolve(true);
          },
          () => {
            resolve('common.login.error.unknown');
          }
        );
    });
  }

  registerGuest(legalProcessId: string, realm: string): Promise<string> {
    return new Promise(async (resolve) => {
      this.guestAPI
        .registerGuest({
          realm,
          legalProcessID: { id: legalProcessId },
        })
        .subscribe(
          async (token) => {
            const returnCode = await this.afterGuestAuth(token, realm);
            resolve(returnCode);
          },
          (error) => {
            resolve(`${error.error.error}`);
          }
        );
    });
  }

  private afterGuestAuth(
    token: AuthorizationTokenWithExpiration,
    realm: string
  ): Promise<string | null> {
    return new Promise(async (resolve) => {
      let parsed;
      try {
        parsed = jwt_decode(token.access_token);
      } catch (e) {
        parsed = null;
      }
      if (!parsed) {
        resolve('common.login.error.invalidToken');
        return;
      }

      const updateToken = await fetch(
        `${environment.KEYCLOAK_URL}realms/${realm}/token-to-cookie/sso`,
        {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
          credentials: 'include',
        }
      ).then((resp) => resp.ok);

      if (updateToken) {
        await this.initKeycloak(realm);
      }

      resolve(null);
    });
  }

  logIn(): void {
    this.keycloak.login({
      redirectUri: window.location.href,
      locale: 'de',
    });
  }

  tryRefresh(): Promise<boolean> {
    if (!this.keycloak || !this.keycloak?.refreshToken) return Promise.resolve(false);
    return this.keycloak.updateToken(30).then(() => {
      this.jwtToken$.next(this.keycloak.token);
      return true;
    });
  }

  logOut(): void {
    const onAfterLogOut = () => {
      this.jwtToken$.next(undefined);
      this.updateJWTInfo();
      location.reload();
    };
    if (!this.keycloak || !this.keycloak.authenticated) {
      onAfterLogOut();
    }
    this.keycloak.logout().then(() => {
      onAfterLogOut();
    });
  }

  setCurrentUserLocale(locale: 'en' | 'de'): Observable<Response> {
    // set the locale for the whole document (html tag)
    document.documentElement.lang = locale;

    if (!this.loggedIn) return;
    const realm = this.keycloak.realm;
    const url = `${environment.KEYCLOAK_URL}realms/${realm}/account/`;

    return from(fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.jwtToken$.value}`
      }
    })).pipe(
      switchMap(resp => resp.json()),
      switchMap((data) => {
        data.attributes = { ...data.attributes, locale: [locale] };
        return fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.jwtToken$.value}`
          },
          body: JSON.stringify(data)
        })
      })
    );
  }
}
