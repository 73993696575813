<div class="edit-condition">
  <button
    mat-button
    color="primary"
    *ngIf="currentCondition?.elmnt"
    (click)="parent.removeCurrentCondition()"
    [attr.aria-label]="'aria.label.deleteCondition' | translate"
  >
    <mat-icon aria-hidden="true">delete</mat-icon>
    {{ "document.conditional.delete" | translate }}
  </button>
</div>
<div class="written-condition">
  <div class="condition-input" [attr.aria-label]="'aria.label.conditionalStatementEditor' | translate">
    {{ "document.conditional.title" | translate }}

    <ng-container
      *ngFor="let conditiongroup of currentCondition.cond; let j = index"
    >
      <span *ngIf="j > 0" class="or-text">{{
        "common.label.or" | translate
      }}</span>
      <div class="groupcontainer">
        <ng-container *ngFor="let condition of conditiongroup; let i = index">
          <span *ngIf="i > 0" class="and-text">{{
            "common.label.and" | translate
          }}</span>
          <div class="condition">
            <app-comparison-input
              [condition]="condition"
              [dataSource]="dataSource"
              (conditionChanged)="
                parent?.saveCondition(
                  currentCondition.id,
                  currentCondition.cond
                )
              "
              [canBeDeleted]="conditiongroup.length > 1 || j > 0"
              (deleted)="
                (conditiongroup.length > 1
                  ? conditiongroup.splice(i, 1)
                  : currentCondition.cond.splice(j, 1)) &&
                  parent?.saveCondition(
                    currentCondition.id,
                    currentCondition.cond
                  )
              "
              (editNode)="parent?.previewById($event, condition.refId)"
              [attr.aria-label]="'aria.label.conditionGroup' | translate:{condNum: (i + 1), groupNum: (j + 1)}"
            ></app-comparison-input>
          </div>
        </ng-container>
        <div
          class="add-cond"
          role="button"
          tabindex="0"
          (click)="conditiongroup.push({ refId: '', operator: '', value: '' })"
          (keyup.enter)="conditiongroup.push({ refId: '', operator: '', value: '' })"
          [attr.aria-label]="'aria.label.addAnotherCondition' | translate"
        >
          {{ "document.conditional.andAnother" | translate }}
        </div>
      </div>
    </ng-container>
    <div
      class="or-cond"
      role="button"
      tabindex="0"
      style="margin-bottom: 1rem"
      (click)="
        currentCondition.cond.push([{ refId: '', operator: '', value: '' }])
      "
      (keyup.enter)="currentCondition.cond.push([{ refId: '', operator: '', value: '' }])"
      [attr.aria-label]="'aria.label.addConditionToGroup' | translate"
    >
      {{ "document.conditional.orOther" | translate }}
    </div>
  </div>
</div>
