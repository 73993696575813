import { AvailableFilter } from 'advoprocess/lib/types/filter';

export const processFilters: AvailableFilter[] = [
  {
    id: 'process_name',
    label: 'process.filter.label.name',
    internal_name: 'node_template.info.name',
    icon: 'label',
    grouping_clause: 'node_template.info.name.0',
    type: 'string',
  },
  {
    id: 'process_created_at',
    label: 'process.filter.label.created_at',
    internal_name: 'created_at',
    icon: 'today',
    grouping_clause: 'created_at_date',
    type: 'date',
  },
  {
    id: 'process_updated_at',
    label: 'process.filter.label.updated_at',
    internal_name: 'modified_at',
    icon: 'history',
    grouping_clause: 'modified_at_date',
    type: 'date',
  },
  {
    id: 'process_id',
    label: 'process.filter.label.id',
    internal_name: 'id',
    icon: 'tag',
    type: 'string',
  },
  {
    id: 'process_file_count',
    label: 'process.filter.label.fileCount',
    internal_name: 'execution_states!.count',
    icon: 'folder',
    grouping_clause: 'execution_states!.count',
    type: 'number',
  },
  {
    id: 'process_variables',
    label: (params) => {
      if (params?.name) {
        return `${params.name}`;
      } else {
        return 'process.filter.label.variables';
      }
    },
    icon: 'tag',
    type: 'string',
    parameters: [
      {
        label: 'process.filter.label.variableName',
        name: 'name',
      },
    ],
    internal_name: (value: string, params: { [key: string]: string }) => {
      const split = params?.name?.split(/>/gm) ?? [''];
      return [
        {
          operand: `variables(name eq ${split[0]}).value`,
          label: `${params.name}`,
          operator: 'contains',
          value,
        },
      ];
    },
    grouping_clause: (value, params) => {
      const split = params?.name?.split(/>/gm) ?? [''];
      return {
        property_name_1: `variables(name eq ${split[0]}).value`,
        label: `${params.name}`,
      };
    },
  },
];
