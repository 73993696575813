import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  ChatMessage,
  ProcessParser,
  RawThread,
  Widget,
  SubProcessDefinition,
  ProcessNode,
  Sketch,
  CommonWebSocket,
} from 'advoprocess';
import {
  nodeOutputsSomething,
  recursiveShortestPath,
} from 'advoprocess/lib/helpers/parser';
import DataStore from 'advoprocess/lib/parser/data-store';
import { Operation, applyPatch, compare } from 'fast-json-patch';
import { BehaviorSubject, Subject, Subscription, combineLatest, fromEvent, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  Event,
  EventsService,
  ExecutionService,
  ExecutionState,
  ExecutionStateParticipant,
  ExecutionStateThreadsResponse,
  FileInfo,
  FilesService,
  LegalProcess,
} from 'src/api';
import { EnhancedFeaturesService } from 'src/api/api/enhancedFeatures.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Thread as APIThread } from 'src/api';
import * as _ from 'lodash';
import { Activity, parseActivities } from './process-path';
import { WebSocketService } from 'src/app/common/websocket.service';
import { ProcessSaveManager } from './process-save-manager';
import { DestroyNotifier } from '../../common/destroy-notifier';
import dayjs, { Dayjs } from 'dayjs';
import {
  SchedulePriority,
  ScheduledNodeEventType,
} from 'advoprocess/lib/types/postActions';
import {
  DEFAULT_EVENT_FIELDS,
  ScheduledNode,
  ScheduledNodeDefinition,
  getEventParser,
  getThreadEvents,
  isAutothrow,
} from './event-parsing';
import { Router } from '@angular/router';
import { DossierNotificationService } from 'src/app/services/notification/dossier-notification.service';

export enum SaveState {
  NOT_SAVED,
  SAVING,
  SAVED,
}

export type ParsedExecutionState = Omit<
  Omit<ExecutionState, 'dataStore'>,
  'widgets'
> & {
  dataStore: DataStore;
  widgets: Widget[];
};

export type HistoryEntries = { top: RawThread; prev: Operation[][] };

@Injectable()
export class ProcessService extends DestroyNotifier {
  public parser: ProcessParser;

  public executionState: ParsedExecutionState;
  public participants: ExecutionStateParticipant[] = [];
  public messageNotifierLoadingState: boolean = false;
  public files: FileInfo[] = [];

  private _events: Event[] = [];

  public processDirectSource?: {
    nodes?: ProcessNode[];
    info?: Sketch;
    subs?: SubProcessDefinition[];
    raw: LegalProcess;
  } = undefined;

  private _scopesAndThreads: ExecutionStateThreadsResponse = {
    scopes: [],
    threads: [],
  };
  private _activities: Activity = undefined;

  get events(): Event[] {
    return this._events;
  }

  scheduledNodes: { [thread: string]: ScheduledNode[] } = {};
  activeScheduledNodes: ScheduledNode[] | undefined = undefined;

  get activities(): Activity {
    return this._activities;
  }

  get isSaved(): boolean {
    return this.saveState === SaveState.SAVED && this.pendingSaves.size === 0;
  }

  get activeActivityParent(): Activity {
    const scope = this.parser?.thread?.scope ?? undefined;
    if (!scope) return this.activities;
    const newActivity = findScopeInActivity(this.activities, scope.id);
    if (!newActivity) return this.activities;
    return newActivity;
  }

  get activeActivity(): (Activity[] | APIThread)[] | undefined {
    return this.activeActivityParent?.children;
  }

  get scopesAndThreads(): ExecutionStateThreadsResponse {
    return this._scopesAndThreads;
  }

  set scopesAndThreads(scopesAndThreads: ExecutionStateThreadsResponse) {
    this._scopesAndThreads = scopesAndThreads;
    this._activities = parseActivities(
      scopesAndThreads.scopes,
      scopesAndThreads.threads,
      this.auth,
      this
    );
  }

  public saveState: SaveState = SaveState.SAVED;
  public pendingSaves: Set<string> = new Set();
  public onSaveRequest$ = new Subject<void>();
  public onLoadState$ = new Subject<void>();

  totalSteps: number = 0;
  hideHeader: boolean = false;

  public redoHistory: { [threadid: string]: HistoryEntries } = {};
  public undoHistory: { [threadid: string]: HistoryEntries } = {};

  public processRunning = new BehaviorSubject<boolean>(false);

  onRequireAuth = new Subject<{
    resolve: (v: void) => void;
    reject: (v: void) => void;
  }>();
  onMessage = new Subject<ChatMessage[]>();
  onThreadOpen = new Subject<void>();

  private messageSubscription: Subscription = null;
  private finishSubscription: Subscription = null;
  private authSubscription: Subscription = null;

  isPreview = false;
  noUserInteraction = true;

  saveManager = new ProcessSaveManager(
    this.auth,
    this,
    this.snackBar,
    this.stateAPI,
    this.translator,
    this.filesService,
    this.notifications
  );

  constructor(
    private translator: TranslateService,
    private api: EnhancedFeaturesService,
    private auth: AuthService,
    private filesService: FilesService,
    private stateAPI: ExecutionService,
    private snackBar: MatSnackBar,
    private socket: WebSocketService,
    private eventsService: EventsService,
    private router: Router,
    private notifications: DossierNotificationService
  ) {
    super();

    combineLatest([
      fromEvent(window, 'resize').pipe(startWith(null)),
      this.processRunning.pipe(startWith(null)),
      this.router.events
    ]).pipe(
      takeUntil(this.destroy$),
      debounceTime(50)
    ).subscribe(() => {
      this.hideHeader = window.innerWidth <= 650 && !!this.parser?.thread && window.location.href.includes('messages');
    });
  }

  initParser() {
    this.parser = new ProcessParser({
      mediatorService: this.api,
      fileService: this.filesService,
    });
    this.socket.socket$
      .pipe(
        takeUntil(this.destroy$),
        filter((s) => !!s)
      )
      .subscribe((socket) => {
        this.parser.connectionSocket = socket as CommonWebSocket;
      });
    this.parser.requestReconnect = async () => {
      this.socket.reconnect();
      await this.socket.socket$
        .pipe(
          filter((s) => !!s),
          take(1)
        )
        .toPromise();
    };

    this.parser.jwt$ = this.auth.jwtToken$;
  }

  async setupState(state: ExecutionState) {
    const dataStore = new DataStore();
    dataStore.parseFrom(state.dataStore);
    this.executionState = {
      ...state,
      widgets: state.widgets as Widget[],
      dataStore,
    };
    await this.initParticipants();
    await this.initThreads();
    this.onLoadState$.next();
  }

  async initParticipants() {
    return new Promise<void>((resolve) => {
      this.stateAPI
        .getStateParticipants({
          stateid: this.stateId,
        })
        .subscribe((partipants) => {
          this.participants = partipants;
          resolve();
        });
    });
  }

  initThreads(): Promise<void> {
    return new Promise((resolve) => {
      this.stateAPI
        .getThreadsForExecutionState({
          stateid: this.stateId,
        })
        .pipe(
          catchError((error) => {
            if (error.status !== 404) {
              this.snackBar.open(error.error.error);
            }
            return of({
              scopes: [],
              threads: [],
            });
          })
        )
        .subscribe((resp) => {
          this.scopesAndThreads = resp;
          resolve();
        });
    });
  }

  public setupEvents(state: ExecutionState) {
    return this.eventsService
      .queryEvents({
        filterViewPagination: {
          filter: [
            {
              operand: 'states!.id',
              operator: 'eq',
              value: state.id,
            },
            {
              operand: 'event_type',
              operator: 'includedIn',
              value: ScheduledNodeEventType,
            },
          ],
          pagination: {
            page: 1,
            rows_per_page: 100,
          },
          view: {
            sort_by: {
              by: 'created_at',
              direction: 'asc',
            },
            displayed_columns: DEFAULT_EVENT_FIELDS,
          },
        },
      })
      .pipe(tap((result) => {
        this.extendEvents(result.events);
      }));
  }

  onEventsUpdate = new Subject<void>();

  /**
   * Integrate newEvents into existsing events array
   * so that the order is preserved
   * 
   * @param newEvents New events to integrate
   */
  async extendEvents(newEvents: Event[]) {
    dayjs.extend(customParseFormat);

    newEvents.sort(this.sortEvents);

    if (!this._events.length) {
      this._events = newEvents;
    } else {
      let i = 0;
      for (const entry of newEvents) {
        //Replace event if it already exists
        const existingEventIndex = this._events.findIndex(
          (evt) => entry.id === evt.id
        );
        if (existingEventIndex !== -1) {
          this._events.splice(existingEventIndex, 1, entry);
          continue;
        }

        //Find index where to insert new event
        while (this._events[i]?.due_at ?? this._events[i]?.created_at < entry.due_at ?? entry.created_at) {
          i++;
        }

        this._events.splice(i, 0, entry);
      }
    }

    if (this.parser?.thread?.id) {
      await this.parseScheduledNodes();
    }
  }

  private sortEvents(a: Event, b: Event) {
    return dayjs(
      (a.due_at ?? a.created_at).slice(0, 23),
      'DD.MM.YYYY HH:mm:ss.SSS'
    ).diff(
      dayjs((b.due_at ?? b.created_at).slice(0, 23), 'DD.MM.YYYY HH:mm:ss.SSS'),
      'milliseconds'
    );
  }

  private sortScheduledNodes(nodes: ScheduledNode[]) {
    nodes.sort((a, b) => {
      return this.sortEvents(a.event, b.event);
    });
  }

  private async parseScheduledNodes() {
    dayjs.extend(customParseFormat);
    const threadId = this.parser.thread.id;
    this.scheduledNodes[threadId] = await Promise.all(
      this._events
        .filter((evt) => {
          return (
            ScheduledNodeEventType.includes(evt.event_type as any) &&
            !!evt.event_content &&
            (evt as any).thread_ids === threadId
          );
        })
        .map(async (evt) => {
          const parser = await getEventParser(
            evt,
            this.auth,
            threadId,
            this.eventsService,
            async () => {
              this._events.sort(this.sortEvents);
              await this.parseScheduledNodes();
              this.activeScheduledNodes =
                this.scheduledNodes?.[this.parser?.thread?.id];
            }
          );
          return {
            event: evt,
            parser,
            chat: parser.thread.chat,
          };
        })
    );
    this.sortScheduledNodes(this.scheduledNodes[threadId]);
    this.onEventsUpdate.next();
  }

  startProcess(state?: RawThread) {
    this.saveState = SaveState.SAVED;
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.finishSubscription) {
      this.finishSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    this.parser.reset();
    this.messageSubscription = this.parser.onMessage.subscribe((messages) => {
      this.onMessage.next(messages);
    });
    this.parser.onSaveCallback = (thread) => {
      this.onSaveRequest$.next();
      if (thread.widgets) {
        this.socket.messages$.next({
          operation: 'info',
          payload: {
            stateId: this.stateId,
            action: 'widgetupdate',
          },
        });
      }
      return this.saveManager.save(thread);
    };

    this.destroy$.subscribe(() => {
      this.parser.onSaveCallback = undefined;
    });
    this.authSubscription = this.parser.onRequireAuth.subscribe((state) => {
      if (this.auth.loggedIn) {
        state.resolve();
        return;
      }
      this.onRequireAuth.next(state);
    });
    this.parser.onTransfer.subscribe(() => {
      this.resetHistory();
    });
    this.undoHistory = {};
    this.redoHistory = {};
    state.fileName = this.executionState?.name;
    state.meta = this.executionState?.meta;
    state.widgets = this.executionState?.widgets;
    this.parser.setupThread(state);
    this.startParser();
    this.onThreadOpen.next();
    this.calculateTotalSteps();
  }

  private startParser() {
    if (this.parser.thread.status !== 'OPEN') {
      this.processRunning.next(false);
    } else {
      this.processRunning.next(true);
    }
    this.parser.start(() => {
      this.processRunning.next(false);
    });
    this.onEventsUpdate.next();
  }

  switchThread(index: number) {
    if (
      this.parser.adjacentThreads.findIndex(
        (t) => this.parser.thread === t.current
      ) === index
    )
      return;
    this.parser.switchToThread(index);
    this.startParser();
    this.onThreadOpen.next();
  }

  reply(answer: ChatMessage, delay = 750): Promise<void> {
    this.processRunning.next(true);
    this.noUserInteraction = false;
    return this.parser.reply(answer, delay);
  }

  get stateId(): string | undefined {
    return this.executionState?.id;
  }

  redo() {
    const relevantHistory = this.redoHistory[this.parser.thread.id];
    if (!relevantHistory) return;
    const newThread = _.cloneDeep(relevantHistory.top);
    if (!newThread) {
      return;
    }

    if (relevantHistory.prev.length) {
      const prevDiff = relevantHistory.prev.pop();
      relevantHistory.top = applyPatch(
        newThread,
        prevDiff,
        false,
        false
      ).newDocument;
    } else {
      this.redoHistory[this.parser.thread.id] = undefined;
    }

    this.pushUndoState();

    newThread.fileName = this.executionState?.name;
    newThread.meta = this.executionState?.meta;
    newThread.widgets = this.executionState?.widgets;
    const existingThread = this.parser?.adjacentThreads?.find(
      (t) => t?.current?.id === newThread?.id
    );
    const prevLastSaved = _.cloneDeep(existingThread?.lastSaved);
    this.parser.setupThread(newThread);
    existingThread.lastSaved = prevLastSaved;
    this.startParser();
  }

  undo() {
    const relevantHistory = this.undoHistory[this.parser.thread.id];
    if (!relevantHistory) return;
    const newThread = _.cloneDeep(relevantHistory.top);
    if (!newThread) {
      return;
    }

    if (relevantHistory.prev.length) {
      const prevDiff = relevantHistory.prev.pop();
      relevantHistory.top = applyPatch(
        newThread,
        prevDiff,
        false,
        false
      ).newDocument;
    } else {
      this.undoHistory[this.parser.thread.id] = undefined;
    }

    this.pushRedoState();

    newThread.fileName = this.executionState?.name;
    newThread.meta = this.executionState?.meta;
    newThread.widgets = this.executionState?.widgets;
    const existingThread = this.parser?.adjacentThreads?.find(
      (t) => t?.current?.id === newThread?.id
    );
    const prevLastSaved = _.cloneDeep(existingThread?.lastSaved);
    this.parser.setupThread(newThread);
    existingThread.lastSaved = prevLastSaved;
    this.startParser();
  }

  pushUndoState() {
    const currentThread = this.parser.getRawThread(
      this.parser.thread.methodKey,
      this.parser.thread.methodParams
    );
    let relevantHistory = this.undoHistory[this.parser.thread.id];
    if (!relevantHistory) {
      relevantHistory = { top: undefined, prev: [] };
      this.undoHistory[this.parser.thread.id] = relevantHistory;
    }
    if (relevantHistory.top) {
      const diff = compare(currentThread, relevantHistory.top);
      relevantHistory.prev.push(diff);
    }
    relevantHistory.top = currentThread;
  }

  private pushRedoState() {
    const currentThread = this.parser.getRawThread(
      this.parser.thread.methodKey,
      this.parser.thread.methodParams
    );
    let relevantHistory = this.redoHistory[this.parser.thread.id];
    if (!relevantHistory) {
      relevantHistory = { top: undefined, prev: [] };
      this.redoHistory[this.parser.thread.id] = relevantHistory;
    }
    if (relevantHistory.top) {
      const diff = compare(currentThread, relevantHistory.top);
      relevantHistory.prev.push(diff);
    }
    relevantHistory.top = currentThread;
  }

  resetHistory() {
    // ToDo: See where this is called
    this.undoHistory = {};
    this.redoHistory = {};
    this.calculateTotalSteps();
  }

  get doneSteps(): number {
    return (
      (this.parser?.thread?.chat?.filter((c) => c.sender !== 'bot')?.length ??
        0) + 1
    );
  }

  approximateTime(): string {
    const percentage =
      (Math.min(this.doneSteps - 1, this.totalSteps) / this.totalSteps) * 100;
    if (Number.isNaN(percentage)) {
      return `0 %`;
    }
    return `${Math.min(Math.round(percentage), 100).toFixed(0)} %`;
  }

  calculateTotalSteps(): number {
    const chat = this.parser.thread.chat;
    const answers = chat.filter((msg) => {
      return msg.sender !== 'bot';
    }).length;
    const currentNode = this.parser?.visitor?.currentNode;
    if (!currentNode) {
      return answers;
    }
    const sPath = recursiveShortestPath(
      currentNode,
      0,
      this.parser.thread.nodeTemplate.nodes
    );
    const remainingNodes = sPath.filter((n) => nodeOutputsSomething(n)).length;
    this.totalSteps = answers + remainingNodes;
  }

  postNode(node: ProcessNode): Promise<void> {
    if (!this.parser.thread.lastNode?.length) return;
    const path = this.parser.thread.lastNode + '/' + 0;
    return new Promise<void>((resolve) => {
      this.stateAPI
        .updateThread({
          stateid: this.stateId,
          threadid: this.parser.thread.id,
          threadRequest: {
            nodeTemplate: [
              {
                op: 'add',
                path,
                value: node,
              },
            ],
          },
        })
        .pipe(
          catchError((err) => {
            console.log(err);
            this.snackBar.open(
              this.translator.instant('common.error.errorWhilePosting')
            );
            return of(null);
          }),
          switchMap((data) => {
            if (data !== null) {
              return this.stateAPI.getThreadInState({
                stateid: this.stateId,
                threadid: this.parser.thread.id,
              });
            } else {
              return of(null);
            }
          })
        )
        .subscribe((thread: RawThread) => {
          if (!thread?.id) {
            resolve();
            return;
          }
          thread.fileName = this.executionState?.name;
          thread.meta = this.executionState?.meta;
          thread.widgets = this.executionState?.widgets;
          this.parser.setupThread(thread);
          this.startParser();
          this.calculateTotalSteps();
          resolve();
        });
    });
  }

  postSimpleMessage(message: ChatMessage): Promise<void> {
    if (!this.parser.thread.lastNode?.length) return;
    this.parser.thread.chat.push(message);
    this.parser.onMessage.next(this.parser.thread.chat);
    return this.parser.save();
  }

  async scheduleNode(
    node: any,
    dueAt?: Dayjs,
    durationMinutes?: number,
    type: typeof ScheduledNodeEventType[number] = 'task',
    priority = SchedulePriority.MEDIUM
  ) {
    const eventContent: ScheduledNodeDefinition = {
      node,
      chat: [],
    };
    const autothrow = isAutothrow(type);
    this.eventsService
      .planEvent({
        stateid: this.stateId,
        threadid: this.parser?.thread?.id,
        event: {
          done: dueAt && autothrow && dueAt.isBefore(dayjs()) ? true : false,
          priority: priority,
          event_type: type ?? 'task',
          autothrow,
          due_at: dueAt?.toISOString() ?? undefined,
          durationMinutes,
          event_content: eventContent,
        },
      })
      .pipe(
        switchMap(() =>
          getThreadEvents(
            this.parser.thread.id,
            this.stateId,
            this.eventsService
          )
        )
      )
      .subscribe((events) => {
        this.extendEvents(events.events).then(() => {
          this.activeScheduledNodes =
            this.scheduledNodes?.[this.parser?.thread?.id];
        });
      });
  }

  public getStateFiles() {
    return this.stateAPI
      .getStateFiles({
        stateid: this.stateId,
      })
      .pipe(
        tap((files) => {
          this.files = files;
        })
      );
  }
}

function findScopeInActivity(rootActivity: Activity, scopeId: string) {
  let o: Activity;
  rootActivity.children.some(function iter(a) {
    if (!Array.isArray(a)) {
      return a.path === scopeId;
    }
    return a.some((activity) => {
      if (activity.scope === scopeId) {
        o = activity;
        return true;
      }
      const childHas = activity.children.some((child) => iter(child));
      if (childHas && !o) {
        o = activity;
      }
      return childHas;
    });
  });
  return o;
}
