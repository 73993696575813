/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ExecutionStateParticipantRequestParent { 
    id: string;
    source: ExecutionStateParticipantRequestParent.SourceEnum;
}
export namespace ExecutionStateParticipantRequestParent {
    export type SourceEnum = 'lawyer' | 'client';
    export const SourceEnum = {
        Lawyer: 'lawyer' as SourceEnum,
        Client: 'client' as SourceEnum
    };
}


