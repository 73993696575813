/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcessTrigger } from './processTrigger';
import { LockState } from './lockState';
import { LegalProcessPermission } from './legalProcessPermission';


/**
 * A legal process
 */
export interface LegalProcess { 
    created_at?: string;
    created_by?: string;
    data?: object;
    id?: string;
    is_public?: boolean;
    lock_state?: LockState;
    modified_at?: string;
    permission?: LegalProcessPermission;
    permission_policy_can_edit?: boolean;
    published?: any | null;
    slug?: string;
    triggers?: Array<ProcessTrigger>;
}

