import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, from, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public matSnackBar: MatSnackBar,
    public router: Router,
    private injector: Injector
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth = this.injector.get(AuthService);

    if (
      !request.url.includes('api') ||
      request.url.includes('/public/') ||
      request.url.includes('authentication-guest') ||
      request.url.match(/\/legal-process\/.*\/realm/gm)
    ) {
      return next.handle(request);
    }
    let ret: Observable<HttpEvent<any>>;
    if (auth.ready$.value) {
      const req = this.prepareRequest(auth, request);
      ret = next.handle(req);
    } else {
      ret = auth.ready$.pipe(
        filter((t) => !!t),
        take(1),
        map(() => this.prepareRequest(auth, request)),
        switchMap((req) => next.handle(req))
      );
    }

    return ret.pipe(
      catchError((error) => {
        if (error.status === 401) {
          return from(auth.tryRefresh()).pipe(
            switchMap((refreshSuccessful) => {
              if (refreshSuccessful) {
                const req = this.prepareRequest(auth, request);
                return next.handle(req);
              } else {
                return throwError(error);
              }
            }),
            catchError(() => {
              auth.logOut();
              this.matSnackBar.open(error.error.error, null, { duration: 3000 });
              return throwError(error);
            }),
          );
        }
        return throwError(error);
      })
    );
  }

  private prepareRequest(auth: AuthService, request: HttpRequest<any>) {
    const currentToken = auth.jwtToken$.value;
    let req: HttpRequest<any>;
    if (!currentToken || currentToken.length <= 0) {
      req = request;
    } else {
      req = request.clone({
        setHeaders: { 'X-Auth-Token': `Bearer ${currentToken}` },
      });
    }
    return req;
  }
}
