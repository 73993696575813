import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { QuestionAnswersComponent } from './question-answers.component';
import { TextInputComponent } from './inputs/text-input.component';
import { OptionsInputComponent } from './inputs/options-input.component';
import { CalendarInputComponent } from './inputs/calendar-input.component';
import { FileUploadInputComponent } from './inputs/file-upload-input.component';
import { MultitextInputComponent } from './inputs/multitext-input.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FormRendererComponent } from './form-renderer/form-renderer.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { sanitizeHtmlPipe } from 'src/app/pipes/sanitize.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SignatureInputComponent } from './inputs/signature/signature-input.component';
// Caution: Do not remove this, otherwise the code does not compile due to circular imports
import { SignOnPDFModule } from './inputs/signature/sign-on-pdf/sign-on-pdf.module';
import { SearchMenuModule } from 'src/app/widgets/search-menu/search-menu.module';
import { PosterBadgeComponent } from './poster-badge/poster-badge.component';

@NgModule({
  declarations: [
    QuestionAnswersComponent,
    TextInputComponent,
    OptionsInputComponent,
    CalendarInputComponent,
    FileUploadInputComponent,
    MultitextInputComponent,
    FormRendererComponent,
    SignatureInputComponent,
    PosterBadgeComponent,
  ],
  exports: [
    QuestionAnswersComponent,
    FormRendererComponent,
    PosterBadgeComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTooltipModule,
    NgxDropzoneModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule,
    MatSelectModule,
    MatCheckboxModule,
    PipesModule,
    MatProgressSpinnerModule,
    SearchMenuModule,
  ],
  providers: [sanitizeHtmlPipe],
})
export class QuestionAnswersModule {}
