/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterCriterium } from './filterCriterium';


export interface BooleanOperator { 
    filters: Array<FilterCriterium | BooleanOperator>;
    operator: BooleanOperator.OperatorEnum;
}
export namespace BooleanOperator {
    export type OperatorEnum = 'and' | 'or';
    export const OperatorEnum = {
        And: 'and' as OperatorEnum,
        Or: 'or' as OperatorEnum
    };
}


