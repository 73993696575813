import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractNode,
  activeNodes,
  addressFields,
  nodeNames,
  parseWithPlaceholders,
  ProcessNode,
  QuestionNode,
  SketchParser,
  SPECIAL_FUNCTIONS,
} from 'advoprocess';
import { FormNodeConfig } from 'advoprocess/lib/nodes/default-nodes/form.node';
import DataStore from 'advoprocess/lib/parser/data-store';
import { QuestionConfig, questionModes } from 'advoprocess/lib/types/question';
import { NodeOperationsService } from 'src/app/views/lawyer/process/editor/node-operations';

export interface TagInputResponse {
  text?: string;
  directValue?: string;
}

interface SuggestionEntry {
  name: string;
  icon: string;
  explanation?: string;
  options?: SuggestionEntry[];
  requiresManualInput?: boolean;
  onSelect?: () => void;
  directValue?: any;
}

@Component({
  selector: 'app-refid-input',
  templateUrl: './refid-input.component.html',
  styleUrls: ['./refid-input.component.scss'],
})
export class RefidInputComponent implements AfterViewInit {
  @Input() dataSource?: ProcessNode[];
  @Input() dataStore?: DataStore;
  @Input() ownNode: ProcessNode;

  @Output() destroy = new EventEmitter<TagInputResponse | undefined>();

  tagSuggestions: SuggestionEntry[] = [];

  currentInput: string;

  private currentTagSnippet?: string;
  private currentArrayIndex?: number | undefined;

  selectedEntryIndexes: number[] = [0];
  selectedLevel: number = 0;

  manualPrefix = undefined;

  createNewOptions: SuggestionEntry[] = Object.keys(activeNodes())
    .filter((key) => activeNodes()[key].canHaveRefIds)
    .map((key): SuggestionEntry => {
      const nodeClass = activeNodes()[key];
      const instance: AbstractNode = new nodeClass();

      return {
        name: this.translator.instant(`node.names.${nodeNames[key]}`),
        explanation: this.translator.instant(`node.names.${nodeNames[key]}`),
        icon: instance.icon,
        onSelect: () => {
          if (key === 'FormNode') {
            const q: QuestionNode = SketchParser.buildNode(
              'QuestionNode',
              null
            ) as QuestionNode;
            this.addRefIdNode(key, undefined, undefined, (node) => {
              const config: FormNodeConfig = node.node.config as FormNodeConfig;
              config.questions.value = [
                {
                  question: q.config,
                  refId: this.currentInput,
                  position: [0, 0],
                },
              ];
            });
          } else {
            this.addRefIdNode(key, this.currentInput);
          }
        },
        options:
          instance.modes?.map((mode) => ({
            icon: mode.icon,
            name: this.translator.instant(mode.name),
            explanation: this.translator.instant(mode.name),
            onSelect: () => {
              this.addRefIdNode(key, this.currentInput, mode.value);
            },
          })) ?? [],
      };
    });

  @ViewChild('inp', { read: ElementRef })
  inputRef: ElementRef<HTMLInputElement>;

  constructor(
    private translator: TranslateService,
    private nodeOperationsService: NodeOperationsService,
    private snackBar: MatSnackBar
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputRef.nativeElement.focus();
    }, 0);
  }

  anyTagSnippedOverlays(): boolean {
    return this.tagSuggestions.find(
      (tag) => tag.name === this.currentInput.split(/\>|&gt;/)[0]
    )
      ? true
      : false;
  }

  private wrapInArrayIndex(str: string): string {
    if (!this.currentArrayIndex) return str;
    const splitted = str.split('>');
    if (!splitted.length) return str;
    splitted.splice(1, 0, this.currentArrayIndex.toString());
    return splitted.join('>');
  }

  close(tagName?: string, suggestion?: SuggestionEntry) {
    if (!suggestion?.requiresManualInput) {
      if (suggestion?.directValue && this.dataStore) {
        this.destroy.emit({
          directValue: parseWithPlaceholders(
            `<placeholder>${suggestion.name}</placeholder>`,
            this.dataStore,
            ''
          )?.body.innerHTML,
        });
        return;
      }
      const text = this.wrapInArrayIndex(tagName ?? this.currentInput ?? '');
      this.destroy.emit({
        text,
      });
    } else {
      this.manualPrefix = this.wrapInArrayIndex(tagName + '>');
      this.currentInput = '';
    }
  }

  private getSelectedTagPath(
    level?: number
  ): [string, SuggestionEntry] | undefined {
    let current = this.tagSuggestions[this.selectedEntryIndexes[0]];
    if (!current) return undefined;
    let buffer = [current.name];
    if (level >= 1) {
      for (let i = 1; i <= level; i++) {
        const indx = this.selectedEntryIndexes[i] ?? 0;
        if (!current?.options?.[indx]) return undefined;
        current = current.options[indx];
        buffer.push(current.name);
      }
    }
    return [buffer.join('>'), current];
  }

  keydown(event) {
    const [currentSelectedPath, currentTag] = this.getSelectedTagPath(
      this.selectedLevel
    ) ?? [undefined, undefined];
    if (event.key === 'Enter') {
      if (this.manualPrefix) {
        this.close(
          (this.manualPrefix + this.currentInput).replace(/>\s*$/gm, '').trim()
        );
      } else if (currentSelectedPath) {
        if (currentTag.onSelect) {
          currentTag.onSelect();
        } else {
          this.close(currentSelectedPath, currentTag);
        }
      } else {
        if (this.selectedEntryIndexes[0] === this.tagSuggestions.length) {
          if (this.selectedLevel === 0) {
            this.addRefIdNode('QuestionNode', this.currentInput);
          } else if (this.selectedLevel === 1) {
            const config = this.createNewOptions[this.selectedEntryIndexes[1]];
            config.onSelect();
          } else if (this.selectedLevel === 2) {
            const config =
              this.createNewOptions[this.selectedEntryIndexes[1]][
                this.selectedEntryIndexes[2]
              ];
            config.onSelect();
          }
        } else if (
          this.selectedEntryIndexes[0] ===
          this.tagSuggestions.length + 1
        ) {
          this.close(this.currentInput);
        } else {
          this.close();
        }
      }
    }
    if (event.key === 'Backspace' && !this.currentInput?.length) {
      this.close();
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.selectedEntryIndexes[this.selectedLevel]++;
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.selectedEntryIndexes[this.selectedLevel]--;
    } else if (
      event.key === 'ArrowRight' &&
      this.selectedLevel < this.selectedEntryIndexes.length &&
      this.inputRef.nativeElement.selectionStart >= this.currentInput.length &&
      (currentTag?.options?.length ||
        (this.selectedLevel === 0 &&
          this.selectedEntryIndexes[0] === this.tagSuggestions.length))
    ) {
      this.highlightEntry(this.selectedLevel + 1, 0);
      event.preventDefault();
    } else if (event.key === 'ArrowLeft' && this.selectedLevel > 0) {
      this.highlightEntry(this.selectedLevel - 1, 0);
      event.preventDefault();
    }
  }

  checkForTagRefs(): void {
    this.tagSuggestions = [];
    const currentWords = this.currentInput?.split(/[\s]+/gm) || [];
    if (currentWords.length) {
      this.currentTagSnippet = currentWords[currentWords.length - 1];
      const splitted = this.currentTagSnippet.split(/\>|&gt;/);
      const substr = splitted[0];
      this.currentArrayIndex =
        splitted.length > 1 &&
        !Number.isNaN(parseInt(splitted[splitted.length - 1]))
          ? parseInt(splitted[splitted.length - 1])
          : undefined;
      const validSuggestions: SuggestionEntry[] = [];
      if (this.dataStore) {
        this.entriesFromDataStore(validSuggestions, substr);
      } else if (this.dataSource) {
        this.entriesFromDataSource(validSuggestions, substr);
      }
      Object.keys(SPECIAL_FUNCTIONS)
        .filter((f) =>
          f
            .toLocaleLowerCase()
            .includes(this.currentTagSnippet.split(/\>|&gt;/)[0].toLowerCase())
        )
        .forEach((f) =>
          validSuggestions.push({
            name: f,
            explanation: this.translator.instant(
              `lawyer.editor.explanations.functions.${f}`
            ),
            icon: 'functions',
            options: [
              {
                name: 'ids',
                icon: 'lightbulb',
                explanation: this.translator.instant(
                  'lawyer.editor.explanations.functions.ids'
                ),
                requiresManualInput: true,
              },
              {
                name: 'values',
                icon: 'calculate',
                explanation: this.translator.instant(
                  'lawyer.editor.explanations.functions.values'
                ),
                requiresManualInput: true,
              },
            ],
          })
        );
      this.tagSuggestions = validSuggestions.slice(0, 10);
    } else {
      this.currentTagSnippet = '';
    }
    let maxEntries = this.tagSuggestions.length + 1;
    if (this.selectedLevel > 0) {
      if (
        this.selectedLevel === 1 &&
        this.selectedEntryIndexes[0] === this.tagSuggestions.length
      ) {
        maxEntries = this.createNewOptions.length - 1;
      } else {
        const [_, entry] = this.getSelectedTagPath(this.selectedLevel - 1) ?? [
          undefined,
          undefined,
        ];
        if (entry?.options?.length) {
          maxEntries = entry.options.length - 1;
        }
      }
    }
    this.selectedEntryIndexes[this.selectedLevel] = Math.max(
      Math.min(this.selectedEntryIndexes[this.selectedLevel], maxEntries),
      0
    );
  }

  private entriesFromDataSource(
    validSuggestions: SuggestionEntry[],
    substr: string
  ) {
    const cleanParse = (content: string) => {
      const txt = new DOMParser().parseFromString(content, 'text/html');
      txt.querySelectorAll('placeholder').forEach((p) => {
        p.querySelector('icon').remove();
        p.outerHTML = `#${p.innerHTML}`;
      });
      return txt.body.textContent;
    };

    this.dataSource?.forEach((node) => {
      (node?.node?.refIds ?? [])
        .concat(node?.subs?.list?.map((s) => s?.alias) ?? [])
        .forEach((refId) => {
          let canFit = refId?.toLowerCase().includes(substr.toLowerCase());
          let inf = '';
          let icon = node?.node.icon ?? 'tag';
          let questionConfig: QuestionConfig = undefined;
          let options: SuggestionEntry[] = undefined;
          switch (node.node.identifier) {
            case 'QuestionNode':
            case 'LawyerInputNode':
              questionConfig =
                (node.node?.config as QuestionConfig) ?? undefined;
              icon = node.node.selectedMode.icon ?? node?.node.icon ?? 'tag';
              break;
            case 'FormNode':
              let fit = node.node?.config?.questions?.value?.find(
                (q) => q.refId === refId
              );
              questionConfig = fit?.question ?? undefined;
              icon =
                questionModes.find(
                  (m) => m.value === fit?.question?.questionType?.value
                )?.icon ?? node?.node.icon;
              break;
            case 'DateNode':
              inf = node.node.config?.format?.value;
              options = this.DATE_FUNCTIONS;
              break;
            case 'RESTNode':
              inf = node.node.config?.url?.value ?? '';
              break;
            case 'MathNode':
              inf = cleanParse(node.node.config?.formula?.value ?? '');
              break;
            case 'VariableNode':
              inf = `${node.node.refId} = ${node.node.config?.value?.value}`;
              options = this.wrapTagInFunctions('any', []);
              break;
            case 'SignNode':
              inf = this.translator.instant('node.names.sign');
              break;
            default:
              break;
          }
          if (questionConfig) {
            inf = cleanParse(questionConfig?.questionText?.value ?? '');
            canFit = canFit || inf.includes(substr);
            if (questionConfig.questionType?.value === 'address') {
              options = addressFields.map((a) => ({
                name: a.value,
                icon: 'place',
                explanation: this.translator.instant(a.title),
                options: this.wrapTagInFunctions(
                  a.type === 'text' ? 'string' : a.type
                ),
              }));
            } else if (questionConfig.questionType?.value !== 'file') {
              options = this.wrapTagInFunctions(
                questionConfig.questionType?.value
              );
            }
          }
          if (canFit) {
            validSuggestions.push({
              name: refId,
              explanation: inf,
              icon,
              options,
              requiresManualInput: [
                'RESTNode',
                'ClientManagement',
                'VariableNode',
                'ActaportNode',
                'SubProcessNode',
              ].includes(node.node.identifier),
            });
          }
        });
    });
  }

  private entriesFromDataStore(
    validSuggestions: SuggestionEntry[],
    substr: string
  ) {
    this.dataStore?.keys
      ?.filter((key) => {
        return (
          !key.startsWith('__note') &&
          key?.toLowerCase().includes(substr.toLowerCase())
        );
      })
      .forEach((key) => {
        const value = this.dataStore.get(key);
        const type = this.dataStore.type(key);
        const meta = this.dataStore.meta(key);
        validSuggestions.push({
          icon: 'tag',
          name: key,
          explanation: parseWithPlaceholders(
            `<placeholder>${key}</placeholder>`,
            this.dataStore,
            ''
          )?.body.innerText,
          directValue: value,
        });
      });
  }

  DATE_FUNCTIONS = [
    {
      icon: 'event',
      name: 'plus',
      explanation: this.translator.instant('lawyer.editor.explanations.plus'),
      requiresManualInput: true,
    },
    {
      icon: 'event',
      name: 'minus',
      explanation: this.translator.instant('lawyer.editor.explanations.minus'),
      requiresManualInput: true,
    },
    {
      icon: 'event',
      name: 'date_format',
      explanation: this.translator.instant(
        'lawyer.editor.explanations.date_format'
      ),
      requiresManualInput: true,
    },
  ];

  wrapTagInFunctions(type: string, omit: string[] = []): SuggestionEntry[] {
    let options: SuggestionEntry[] = [
      {
        icon: 'keyboard_capslock',
        name: 'caps',
        explanation: this.translator.instant('lawyer.editor.explanations.caps'),
      },
      {
        icon: 'find_replace',
        name: 'replace',
        explanation: this.translator.instant(
          'lawyer.editor.explanations.replace'
        ),
        requiresManualInput: true,
      },
      {
        icon: 'drive_file_rename_outline',
        name: 'as',
        explanation: this.translator.instant('lawyer.editor.explanations.as'),
        requiresManualInput: true,
      },
      {
        icon: 'check_box_outline_blank',
        name: 'is_empty',
        explanation: this.translator.instant(
          'lawyer.editor.explanations.is_empty'
        ),
      },
      ...this.DATE_FUNCTIONS,
    ];
    if (type === 'number') {
      options.unshift({
        icon: 'spellcheck',
        name: 'as_word',
        explanation: this.translator.instant(
          'lawyer.editor.explanations.asWord'
        ),
      });
      options.unshift({
        icon: 'clear_all',
        name: 'format',
        requiresManualInput: true,
        explanation: this.translator.instant(
          'lawyer.editor.explanations.formatNumber'
        ),
      });
      type = 'string';
    }
    options = options.filter((o) => !omit.includes(o.name));
    options
      .filter((o) => !['as', 'is_empty'].includes(o.name))
      .forEach((o) => {
        o.options =
          this.wrapTagInFunctions(type, omit.concat([o.name])) ?? undefined;
      });
    return options;
  }

  highlightEntry(level: number, index: number) {
    this.selectedEntryIndexes = this.selectedEntryIndexes
      .slice(0, level)
      .concat(
        Array(Math.max(0, level + 1 - this.selectedEntryIndexes.length)).fill(0)
      );
    this.selectedEntryIndexes[level] = index;
    this.selectedLevel = level;
    this.selectedLevel = Math.max(this.selectedLevel, 0);
  }

  addRefIdNode(
    nodeType: string = 'QuestionNode',
    refId?: string,
    mode?: string,
    beforeClose?: (node: ProcessNode) => void
  ): void {
    if (!this.ownNode) {
      return;
    }
    refId = refId ? refId.split(/\>|&gt;/)[0] : undefined;
    const block = SketchParser.buildNode(nodeType, null);
    const lastNodesBeforeDoc =
      this.dataSource?.filter((n) => n.outputs.includes(this.ownNode._id)) ??
      [];
    const processNode = this.nodeOperationsService.addNode(
      this.dataSource,
      block,
      this.ownNode.x,
      this.ownNode.y - 5,
      1,
      'string'
    );
    processNode.outputs[0] = this.ownNode._id;
    lastNodesBeforeDoc.forEach((node) => {
      node.outputs = node.outputs.map((outId) => {
        if (outId !== this.ownNode._id) {
          return outId;
        }
        return processNode._id;
      });
    });
    this.nodeOperationsService.makeRoom(this.dataSource, processNode.y, 350);
    if (mode) processNode.node.onModeChange(mode);
    if (refId) processNode.node.refId = refId;
    this.snackBar.open(
      this.translator.instant('document.edit.added', {
        name: this.currentTagSnippet.split(/\>|&gt;/)[0],
        type: this.translator.instant('node.names.' + block.typeName),
      }),
      '',
      { duration: 3000 }
    );
    if (beforeClose) beforeClose(processNode);
    this.close(refId);
  }
}
