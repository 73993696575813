/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionMode } from './permissionMode';


export interface PermissionPolicy { 
    model: PermissionPolicy.ModelEnum;
    read: PermissionMode;
    write: PermissionMode;
}
export namespace PermissionPolicy {
    export type ModelEnum = 'addon_configurations' | 'clients' | 'execution_states' | 'lawyers' | 'legal_processes' | 'roles' | 'settings' | 'templates' | 'analytics';
    export const ModelEnum = {
        AddonConfigurations: 'addon_configurations' as ModelEnum,
        Clients: 'clients' as ModelEnum,
        ExecutionStates: 'execution_states' as ModelEnum,
        Lawyers: 'lawyers' as ModelEnum,
        LegalProcesses: 'legal_processes' as ModelEnum,
        Roles: 'roles' as ModelEnum,
        Settings: 'settings' as ModelEnum,
        Templates: 'templates' as ModelEnum,
        Analytics: 'analytics' as ModelEnum
    };
}


