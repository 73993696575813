import { Component, Input } from '@angular/core';
import { CustomCIService } from '../../services/customCI.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/auth/auth.service';
import { AdornmentsService } from 'src/app/adornments.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() isEmbed: boolean;

  @Input()
  justOverlay: boolean = false;

  hidden = false;

  constructor(
    public translator: TranslateService,
    private ci: CustomCIService,
    public auth: AuthService,
    private adornments: AdornmentsService
  ) {
    this.adornments.registerFooter(this);
  }

  languages = ['de', 'en'];

  selectLanguage(language: string) {
    this.translator.use(language);
    localStorage.setItem(`preferred-lang`, language);
  }

  get customLogo(): SafeResourceUrl {
    return this.ci.customLogo;
  }

  get dseLink(): string {
    if (!this.auth.loggedIn || this.auth.isClient) {
      return this.ci.customIdentity?.dse_link;
    }
    return '';
  }

  get imprintLink(): string {
    if (!this.auth.loggedIn || this.auth.isClient) {
      return this.ci.customIdentity?.imprint_link;
    }
    return '';
  }

  get lawFirmName(): string {
    return this.ci.customIdentity?.law_firm ?? 'Silberfluss';
  }
}
