import { AvailableFilter } from 'advoprocess/lib/types/filter';
import { executionStateFilters } from './execution-state-filters';

export const taskFilters: AvailableFilter[] = [
  {
    id: 'task_description',
    label: 'lawyer.dashboard.tasks.description',
    internal_name: 'event_content.chat.0.content',
    type: 'string',
    icon: 'label',
  },
  {
    id: 'task_states',
    label: 'lawyer.dashboard.tasks.state',
    internal_name: 'states!',
    type: 'entity',
    icon: 'source',
    children: executionStateFilters,
  },
];
