/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BooleanOperator } from './booleanOperator';
import { FilterCriterium } from './filterCriterium';
import { DataViewTableViewParameters } from './dataViewTableViewParameters';


export interface DataViewTableDefinition { 
    filter?: Array<FilterCriterium | BooleanOperator>;
    view?: DataViewTableViewParameters;
}

