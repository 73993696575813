/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TableViewParametersSortBy } from './tableViewParametersSortBy';
import { Pagination } from './pagination';
import { BooleanOperator } from './booleanOperator';
import { FilterCriterium } from './filterCriterium';


export interface QueryDataViewDataRequest { 
    additional_filter?: Array<FilterCriterium | BooleanOperator>;
    pagination?: Pagination;
    sort_by?: TableViewParametersSortBy;
}

