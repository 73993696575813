/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NotificationType } from './notificationType';


/**
 * Notification object
 */
export interface Notification { 
    due_at?: string;
    id?: string;
    meta?: object;
    seen?: boolean;
    send_at?: string;
    stateid?: string;
    threadid?: string;
    type?: NotificationType;
}

