import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';
import { CaptureConsole } from '@sentry/integrations';

if (environment.env === 'prod' || environment.env === 'new') {
  Sentry.init({
    dsn: 'https://1e438f38936ec75bdc2845a0fdcdb132@sentry.silberfluss.io/2',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/api.silberfluss\.io\//,
          /^https:\/\/staging-api.silberfluss\.io\//,
        ],
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: false,
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: environment.env,
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
