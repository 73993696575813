import { Injectable } from '@angular/core';
import { Setting } from 'src/api';
import { SettingsService as APISettingsServer } from 'src/api';
import { AuthService } from '../auth/auth.service';

export type ParsedSetting = Setting & {
  value?: any;
};

export type settingHierarchyLevel =
  | 'global'
  | 'lawyer'
  | 'process'
  | 'group'
  | 'client'
  | 'dossier'
  | 'thread';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private userSettings_: { [key in settingHierarchyLevel]?: ParsedSetting[] } =
    {};

  private loadedUserSettings: boolean = false;

  constructor(private service: APISettingsServer, private auth: AuthService) {}

  private initializeSettingLevel(
    level: settingHierarchyLevel,
    remoteId?: string | null
  ): Promise<void> {
    return new Promise((resolve) =>
      this.service
        .getAllSettings({
          level,
          remoteId: this.getRemoteId(level, remoteId),
        })
        .subscribe((settings) => {
          const parsed: ParsedSetting[] = settings.map((s) => {
            return this.parseSetting(s);
          });
          this.userSettings_[level] = parsed;
          resolve();
        })
    );
  }

  public initializeUserSettings(): Promise<void> {
    return Promise.all([
      this.initializeSettingLevel('global'),
      this.initializeSettingLevel(this.auth.isClient ? 'client' : 'lawyer'),
    ]).then(() => {
      this.loadedUserSettings = true;
    });
  }

  private parseSetting(s: Setting): ParsedSetting {
    if (s.value?.trim().startsWith('[') || s.value?.trim().startsWith('{')) {
      try {
        s.value = JSON.parse(s.value);
      } catch {}
    }
    return s;
  }

  public resetSettings() {
    this.loadedUserSettings = false;
    this.userSettings_ = {};
  }

  public async getSetting(
    key: string,
    level?: string,
    remoteId?: string
  ): Promise<ParsedSetting | undefined> {
    if (!this.loadedUserSettings) {
      await this.initializeUserSettings();
    }
    return this.getSettingCore(key, level, remoteId);
  }

  public getSettingSync(
    key: string,
    level?: string,
    remoteId?: string
  ): ParsedSetting {
    if (!this.loadedUserSettings) {
      throw new Error(
        "Settings not yet loaded. Call 'initializeUserSettings'!"
      );
    }
    return this.getSettingCore(key, level, remoteId);
  }

  private getSettingCore(
    key: string,
    level?: string,
    remoteId?: string
  ): ParsedSetting {
    if (!level) {
      const globalValue = this.userSettings_['global'].find(
        (s) => s.key === key
      );
      const userValue = this.userSettings_[
        this.auth.isClient ? 'client' : 'lawyer'
      ].find((s) => s.key === key);
      return userValue ?? globalValue;
    }
    return this.userSettings_[level].find((s) => s.key === key);
  }

  public async getSettings(
    level: settingHierarchyLevel = 'global'
  ): Promise<ParsedSetting[]> {
    if (!this.loadedUserSettings) {
      await this.initializeUserSettings();
    }
    return this.userSettings_[level] ?? [];
  }

  public setSetting(
    key: string,
    value: any,
    level: settingHierarchyLevel = 'global',
    remoteId: string = null
  ): Promise<ParsedSetting> {
    let parsed: string;
    switch (typeof value) {
      case 'object':
        parsed = JSON.stringify(value);
        break;
      default:
        parsed = value;
    }

    const parsedRemoteId = this.getRemoteId(level, remoteId);
    return new Promise((resolve) =>
      this.service
        .setSetting({
          level,
          remoteId: parsedRemoteId,
          settingRequest: {
            key,
            value: parsed,
          },
        })
        .subscribe(async (newSetting) => {
          const parsedSetting = this.parseSetting(newSetting);
          const settings = await this.getSettings(level);
          settings.splice(
            settings.findIndex((s) => s.id === parsedSetting.id),
            1,
            parsedSetting
          );

          resolve(parsedSetting);
        })
    );
  }

  private getRemoteId(
    level: settingHierarchyLevel,
    remoteId: string | null
  ): string | null {
    switch (level) {
      case 'lawyer':
        return this.auth.isClient ? null : this.auth.userId;
      case 'client':
        return this.auth.isClient ? this.auth.userId : remoteId;
      case 'global':
        return null;
      default:
        return this.auth.isClient ? null : remoteId;
    }
  }
}
