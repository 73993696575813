/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventProperty { 
    key?: EventProperty.KeyEnum;
    value?: string;
}
export namespace EventProperty {
    export type KeyEnum = 'mail' | 'firstName' | 'lastName' | 'realm';
    export const KeyEnum = {
        Mail: 'mail' as KeyEnum,
        FirstName: 'firstName' as KeyEnum,
        LastName: 'lastName' as KeyEnum,
        Realm: 'realm' as KeyEnum
    };
}


