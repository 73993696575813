import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ChatMessage } from 'advoprocess';
import _ from 'lodash';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html'
})
export class TextInputComponent implements OnInit {
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  @Input() inline?: boolean;
  @Input() message: ChatMessage;
  @Input() control: UntypedFormControl;

  @Input() value: any;
  @Input() canBeEmpty: boolean;
  @Output() valueChanged = new EventEmitter<any>();

  @Output() answered = new EventEmitter<any>();
  @Output() changed = new EventEmitter<void>();

  @Output() hasError = new EventEmitter<boolean>();

  requiredValidator = Validators.required;

  inputMode: string | null = null;

  get emptyValue(): boolean {
    return typeof this.value === 'number' ? _.isNil(this.value) : !this.value;
  }

  ngOnInit(): void {
    this.initInputMode();
    window.setTimeout(() => {
      if (this.inputRef?.nativeElement) {
        if (this.inline) {
          this.control.valueChanges.subscribe((value) => {
            if (
              this.message.responseRequest.type === 'number' &&
              typeof value === 'string'
            ) {
              value = parseFloat(value.replace(/[^\d,\.]/g, ''));
            }
            this.value = value;
            this.answered.emit(value);
            this.emitError();
          });
        } else {
          this.control.valueChanges.subscribe((value) => {
            if (
              this.message.responseRequest.type === 'number' &&
              typeof value === 'string'
            ) {
              value = parseFloat(value.replace(/[^\d,\.]/g, ''));
            }
            this.changed.emit(value);
          });
          this.inputRef.nativeElement.focus();
        }
      }
    }, 0);
    this.emitError();
  }

  private emitError() {
    this.hasError.emit(
      (!this.canBeEmpty && this.emptyValue) ||
      (this.value?.length && Boolean(this.control.errors))
    );
  }

  private initInputMode() {
    switch (this.message?.responseRequest?.type) {
      case 'mail':
        this.inputMode = 'email';
        break;
      case 'phone':
        this.inputMode = 'tel';
        break;
      case 'number':
        this.inputMode = 'numeric';
        break;
      default:
        break;
    }
  }

  mapQuestionType(type: string): string {
    switch (type) {
      case 'number':
        return 'number';
      default:
        break;
    }
    return 'text';
  }
}
