/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventProperty } from './eventProperty';


export interface EventNotice { 
    eventName?: EventNotice.EventNameEnum;
    eventProperties?: Array<EventProperty>;
}
export namespace EventNotice {
    export type EventNameEnum = 'REGISTRATION';
    export const EventNameEnum = {
        Registration: 'REGISTRATION' as EventNameEnum
    };
}


