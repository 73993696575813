import { Observable, map } from 'rxjs';
import { LegalProcessService } from 'src/api';
import { OptionType } from './options-input.component';

export function fetchProcessVariable(
  processid: string,
  processService: LegalProcessService,
  variableName: string,
  realm: string
): Observable<any> {
  return processService
    .getProcessVariables({
      filterViewPagination: {
        filter: [
          {
            operand: 'name',
            operator: 'eq',
            value: variableName,
          },
        ],
        pagination: {
          page: 1,
          rows_per_page: 1,
        },
        view: {
          displayed_columns: [],
          hidden_columns: [
            {
              display_name: 'id',
              internal_name: 'id',
            },
            {
              display_name: 'meta',
              internal_name: 'meta',
            },
            {
              display_name: 'name',
              internal_name: 'name',
            },
            {
              display_name: 'type',
              internal_name: 'type',
            },
            {
              display_name: 'value',
              internal_name: 'value',
            },
          ],
        },
      },
      processid,
      realm,
    })
    .pipe(
      map((data) => {
        const variable = data.list?.[0];
        if (!variable) return [];
        return variable.value;
      })
    );
}
