<div class="top-bar" [class.hide]="isEmbed" *ngIf="!activeComponents.length">
  <div class="logo-container" (click)="customLogo ? null : goHome()" (keydown.enter)="customLogo ? null : goHome()" role="button" tabindex="0" [attr.aria-label]="('aria.label.home' | translate)">
    <ng-container *ngIf="!customLogo; else styleLogo">
      <img src="assets/LogoIcon@1.svg" [alt]="'alt.companyLogo' | translate" />
    </ng-container>
    <ng-template #styleLogo>
      <img [src]="customLogo" [alt]="'alt.companyLogo' | translate" />
    </ng-template>
  </div>
  <div class="user-options" [attr.aria-label]="'aria.label.profileOptions' | translate ">
    <app-profile-chip></app-profile-chip>
  </div>
</div>
