/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FolderDefinition { 
    duplicate_behavior?: FolderDefinition.DuplicateBehaviorEnum;
    name: string;
    parent_folder?: string;
    stateid?: string;
}
export namespace FolderDefinition {
    export type DuplicateBehaviorEnum = 'replace' | 'error' | 'increment';
    export const DuplicateBehaviorEnum = {
        Replace: 'replace' as DuplicateBehaviorEnum,
        Error: 'error' as DuplicateBehaviorEnum,
        Increment: 'increment' as DuplicateBehaviorEnum
    };
}


