import { Component, Inject, Optional } from '@angular/core';
import DataStore from 'advoprocess/lib/parser/data-store';
import { saveAs } from 'file-saver';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { PossibleIncludes, exportOptions, isUploadedFile } from 'advoprocess';
import { AuthService } from 'src/app/auth/auth.service';
import { ProcessService } from 'src/app/views/process/process.service';

export interface DossierExportDialogData {
  service?: ProcessService;
  store?: DataStore;
}

interface DossierExportForm {
  format: FormControl<typeof exportOptions[number]>;
  includeUploadedDocuments: FormControl<boolean>;
  includeProtocol: FormControl<boolean>;
  exportName: FormControl<string>;
}

@Component({
  selector: 'app-dossier-export-dialog',
  templateUrl: './dossier-export-dialog.component.html',
  styleUrls: ['./dossier-export-dialog.component.scss'],
})
export class DossierExportDialogComponent {
  exportOptions = exportOptions;

  exportForm = new FormGroup<DossierExportForm>({
    format: new FormControl(this.exportOptions[0]),
    includeUploadedDocuments: new FormControl(true),
    includeProtocol: new FormControl(false),
    exportName: new FormControl(),
  });

  constructor(
    @Optional() public dialogRef: MatDialogRef<DossierExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DossierExportDialogData,
    private auth: AuthService
  ) {
    this.exportForm.get('format').valueChanges.subscribe(() => {
      this.exportForm
        .get('exportName')
        .setValue(
          this.exportForm
            .get('format')
            .value?.buildName(
              this.data?.service?.executionState?.sync_id,
              this.data?.service?.executionState?.name
            )
        );
    });
    this.exportForm
      .get('exportName')
      .setValue(
        this.exportForm
          .get('format')
          .value?.buildName(
            this.data?.service?.executionState?.sync_id,
            this.data?.service?.executionState?.name
          )
      );
  }

  export() {
    const target = this.exportForm.get('format')?.value;
    if (!target) return;
    const name = this.exportForm.get('exportName').value;
    target
      .execute(
        this.data?.store,
        this.data?.service.stateId,
        this.auth.jwtToken$.value,
        name,
        this.getIncludes()
      )
      .then((result) => {
        if (isUploadedFile(result)) return;
        saveAs(new Blob([result.content]), result.name);
        this.dialogRef.close();
      });
  }

  private getIncludes(): PossibleIncludes {
    const includes: PossibleIncludes = [];
    if (this.exportForm.get('includeUploadedDocuments')?.value) {
      includes.push('includeUploadedDocuments');
    }
    return includes;
  }

  saveConfig() {
    this.dialogRef.close({
      exportName: this.exportForm.get('exportName').value,
      includes: this.getIncludes(),
    });
  }
}
