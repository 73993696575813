/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientProcessParam } from './clientProcessParam';


export interface ClientProcessStep { 
    id?: string;
    next?: string;
    params?: ClientProcessParam;
    placeholder?: string;
    text?: string;
    title?: string;
    type?: string;
    value?: string;
}

