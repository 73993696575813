/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuthorizationTokenWithExpiration { 
    access_token?: string;
    expires_in?: number;
    refresh_expires_in?: number;
    scope?: string;
    session_state?: string;
    token_type?: string;
}

