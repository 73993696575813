/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { Lawyer } from './lawyer';
import { PermissionPolicy } from './permissionPolicy';


export interface Permission { 
    id: string;
    name: string;
    policies: Array<PermissionPolicy>;
    roles?: Role;
    users?: Lawyer;
    via?: Permission.ViaEnum;
}
export namespace Permission {
    export type ViaEnum = 'user' | 'role';
    export const ViaEnum = {
        User: 'user' as ViaEnum,
        Role: 'role' as ViaEnum
    };
}


