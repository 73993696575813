import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface IframeDialogData {
  title: string;
  url: string;
}

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss']
})
export class IframeDialogComponent {

  iframeSource: SafeResourceUrl = undefined;

  constructor(
    @Optional() public dialogRef: MatDialogRef<IframeDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IframeDialogData,
    private sanitizer: DomSanitizer
  ) {
    this.iframeSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

}
