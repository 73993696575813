import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  HostBinding,
  Inject,
  OnInit,
} from '@angular/core';
import {
  GeneratedDocument,
  ProcessParser,
  getPendingFile,
} from 'advoprocess';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FilesService } from 'src/api';
import * as pdfjsLib from 'pdfjs-dist';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { AuthService } from 'src/app/auth/auth.service';
import _ from 'cypress/types/lodash';
import { environment } from 'src/environments/environment';
import { LineCapStyle, PDFDocument, rgb } from 'pdf-lib';
import {
  SignaturePadComponent,
  SignaturePadResult,
} from './signature-pad/signature-pad.component';
import { SignatureFileDefintion } from 'advoprocess/lib/nodes/default-nodes/sign.node';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import { Subject, delay, filter, take } from 'rxjs';
import dayjs from 'dayjs';
import { DocumentComponent } from 'src/app/views/document/document.component';

pdfjsLib.GlobalWorkerOptions.workerSrc = `/assets/pdf.worker.min.js`;

export interface SignatureFields {
  x: number;
  y: number;
  width: number;
  height: number;
  pageNum?: number;
  result?: SignaturePadResult;
  done?: boolean;
}

export interface SignOnPDFComponentData {
  file: SignatureFileDefintion;
  parser: ProcessParser;
}

@Component({
  selector: 'app-sign-on-pdf',
  templateUrl: './sign-on-pdf.component.html',
  styleUrls: ['./sign-on-pdf.component.scss'],
})
export class SignOnPDFComponent implements OnInit, AfterViewInit {
  @ViewChild('documentPopOver', { read: ElementRef })
  documentPopOver: ElementRef<HTMLDivElement>;
  @ViewChild('appDocument')
  appDocument: DocumentComponent;
  @ViewChild('pdfViewer', { read: ElementRef })
  pdfViewer: ElementRef<HTMLElement>;

  ctx: CanvasRenderingContext2D;

  pdfBlobUrl: string;
  pdfLibDoc: PDFDocument;
  pageView: any;
  pendingFile: GeneratedDocument;

  pageRender$ = new Subject<any>();

  parser: ProcessParser;

  drawing = false;
  showMockedPDF = false;

  scaleFactor = 1.0;
  xCoord = 100;
  fixedCanvasWidth: number;
  fixedCanvasHeight: number;

  currentPage = 1;
  totalPages = 1;
  scale = 1;

  fileRef: string;
  fileName: string;
  fileFolder: string;
  options: any;
  assignees: string[];

  canvas: HTMLCanvasElement;

  lastPos = [0, 0];
  pdfRect = [0, 0, 540, 150];

  loading = true;

  signatureFields: SignatureFields[] = [];

  @HostBinding('class.data')
  get _() {
    return !!this.data;
  }

  parsedDocument: { value: string; options?: any };

  svgPath: string;

  constructor(
    public auth: AuthService,
    private files: FilesService,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<SignOnPDFComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SignOnPDFComponentData,
    private dialogService: DialogService
  ) {
    this.fileRef = this.data.file.fileRef;
    this.fileName = this.data.file.fileName + '_unterschrieben.pdf';
    this.fileFolder = this.data.file.fileFolder;
    this.options = this.data.file.options;
    this.assignees = this.data.file.assignees;
    this.parser = this.data.parser;
  }

  ngAfterViewInit(): void {
    if (this.showMockedPDF) {
      setTimeout(() => {
        const joditEl =
          this.documentPopOver.nativeElement.querySelector('.jodit-wysiwyg');
        let alreadyScrolled = false;
        this.signatureFields = Array.from(
          joditEl.querySelectorAll(
            '.placeholder-inserted[data-issignature="true"]'
          )
        ).map((el) => {
          // Obtain bounding client rect dimensions
          const rect = el.getBoundingClientRect();
          const sig: SignatureFields = {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          };

          const canvas = document.createElement('canvas');
          canvas.classList.add('signature-pad');
          canvas.classList.add('mocked');

          canvas.setAttribute('tabindex', '0');
          canvas.height = sig.height;
          canvas.width = sig.width;

          this.fixedCanvasWidth = canvas.width;
          this.fixedCanvasHeight = canvas.height;

          canvas.style.top = `${sig.y}px`;
          canvas.style.left = `${sig.x}px`;

          canvas.addEventListener('pointerup', () =>
            this.openSignaturePad(canvas, sig)
          );
          canvas.addEventListener('keydown', (event) => {
            if (event.key !== 'Enter') return;
            this.openSignaturePad(canvas, sig);
          });
          this.displayCanvasContent(canvas, sig);
          el.replaceWith(canvas);
          this.canvas = canvas;
          this.loading = false;
          setTimeout(() => {
            if (alreadyScrolled) return;
            alreadyScrolled = true;
            if (!canvas) return;
            canvas.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            setTimeout(() => {
              canvas.focus();
            }, 500);
          }, 1000);
          return sig;
        });
      }, 0);
    }
  }

  ngOnInit(): void {
    this.initDocument();
  }

  initDocument() {
    if (this.fileRef.match(/<<<PENDING::(.*)>>>/m)?.[1]) {
      // Render faked pdf using jodit with signature pads overlayed using html canvases
      this.pendingFile = getPendingFile(this.fileRef).file as GeneratedDocument;
      this.pendingFile.value = this.pendingFile.content;
      this.showMockedPDF = true;
    } else {
      // Render generated pdf with signature pads overlayed using html canvases
      const rawUrl =
        environment.API_URL +
        '/file/' +
        this.fileRef +
        '?adv_tk_gen=' +
        this.auth.jwtToken$.value;
      fetch(rawUrl)
        .then((response: Response) => response.arrayBuffer())
        .then((buffer: ArrayBuffer) =>
          Promise.all([
            pdfjsLib.getDocument({ data: buffer }).promise,
            PDFDocument.load(buffer),
          ])
        )
        .then(
          ([pdfJsDocument, pdfLibDocument]: [
            pdfjsLib.PDFDocumentProxy,
            PDFDocument
          ]) => {
            this.pdfLibDoc = pdfLibDocument;
            const totalPageNums = pdfJsDocument.numPages;
            for (let pageNum = 1; pageNum <= totalPageNums; pageNum++) {
              let breakLoop = false;
              pdfJsDocument.getPage(pageNum).then(async (page) => {
                const viewport = page.getViewport({ scale: 1 });
                // the coordinate origin is in the bottom left corner
                await page.getAnnotations().then((items) => {
                  items.forEach((item) => {
                    if (item.fieldValue == 'Signature') {
                      this.signatureFields.push({
                        x: item.rect[0],
                        y: viewport.height - item.rect[1],
                        width: item.rect[2] - item.rect[0],
                        height: item.rect[3] - item.rect[1],
                        pageNum,
                      });
                      breakLoop = true;
                    }
                  });
                });
                if (breakLoop) {
                  // Remove all form fields from the pdf
                  const form = this.pdfLibDoc.getForm();
                  const fields = form.getFields();
                  fields.forEach((field) => {
                    form.removeField(field);
                  });
                  // Convert the modified pdf to a blob
                  const modifiedPdfBytes = await this.pdfLibDoc.save();
                  const pdfBlob = new Blob([modifiedPdfBytes], {
                    type: 'application/pdf',
                  });
                  // Create a blob url
                  const url = URL.createObjectURL(pdfBlob);
                  this.pdfBlobUrl = url;
                  this.pageRender$
                    .pipe(
                      delay(50),
                      filter(
                        (event) =>
                          !!event?.source?.div?.querySelector('.signature-pad')
                      ),
                      take(1)
                    )
                    .subscribe(() => {
                      this.zoomToFirstSignatureField();
                    });
                }
              });
            }
          }
        );
    }
  }

  private zoomToFirstSignatureField() {
    const target = this.pdfViewer.nativeElement.querySelector('.signature-pad');
    if (!target) return;
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    setTimeout(() => {
      (target as any).focus();
    }, 500);
  }

  onPageRendered(event: any) {
    const pageEl = event.source.div;
    this.pageRender$.next(event);

    const canvas = document.createElement('canvas');
    const scale = event.source.viewport.scale;
    canvas.classList.add('signature-pad');
    canvas.setAttribute('tabindex', '0');
    this.signatureFields
      .filter((f) => f.pageNum === event.pageNumber)
      .forEach((sig) => {
        canvas.height = sig.height * scale;
        canvas.width = sig.width * scale;

        if (this.scale == 1.0) {
          this.fixedCanvasWidth = sig.width * scale;
          this.fixedCanvasHeight = sig.height * scale;
        }

        canvas.style.top = `${sig.y * scale}px`;
        canvas.style.left = `${sig.x * scale}px`;

        canvas.addEventListener('pointerup', () =>
          this.openSignaturePad(canvas, sig)
        );
        canvas.addEventListener('keydown', (event) => {
          if (event.key !== 'Enter') return;
          this.openSignaturePad(canvas, sig);
        });
        this.displayCanvasContent(canvas, sig);
        pageEl.appendChild(canvas);
      });
  }

  private displayCanvasContent(
    canvas: HTMLCanvasElement,
    sig: SignatureFields
  ) {
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (sig.result) {
      this.displaySignatureResult(canvas, ctx, sig.result);
    } else {
      this.displayCanvasPlaceholder(canvas, ctx);
    }
  }

  private displaySignatureResult(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    result: SignaturePadResult
  ) {
    let image = new Image();
    image.onload = () => {
      // For unknown reasons when drawing the svg-path the signature images seems always a bit to large.
      // Thus it is multiplied by an empirical found factor if 0.82
      this.scaleFactor =
        Math.min(
          this.fixedCanvasWidth / image.width,
          this.fixedCanvasHeight / image.height
        ) * 0.82;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
    image.src = result.pngDataURL;
    this.svgPath = result.svgPath;
  }

  private displayCanvasPlaceholder(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D
  ) {
    const text = 'Hier klicken,\n um zu unterschreiben.';
    const lines = text.split('\n');
    const lineHeight = Math.min(canvas.width / 10, canvas.height / 5); // Set line height
    context.font = `${lineHeight * 1.1}px Poppins`;
    context.textBaseline = 'middle';
    context.textAlign = 'center';
    lines.forEach((line, i) => {
      let x = canvas.width / 2;
      let y =
        canvas.height / 2 +
        i * lineHeight -
        ((lines.length - 1) / 2) * lineHeight;
      context.fillText(line, x, y);
    });
  }

  openSignaturePad(canvas: HTMLCanvasElement, sig: SignatureFields): void {
    const dialogRef = this.matDialog.open(SignaturePadComponent, {
      disableClose: true,
      data: {
        width: sig.width,
        height: sig.height,
      },
      panelClass: 'sf-signature-pad-overlay',
    });
    dialogRef.afterClosed().subscribe((result: SignaturePadResult) => {
      if (result === null || result === undefined) {
        return;
      }
      canvas.classList.add('done');
      sig.result = result;
      sig.done = true;
      this.displayCanvasContent(canvas, sig);
    });
  }

  get allDone(): boolean {
    return this.signatureFields.every((sig) => sig.done);
  }

  // Store the signature fields as image tags inside the html code
  async saveSignedDocument() {
    // Convert all HTML canvases to image-tags
    if (this.showMockedPDF) {
      const parser = new DOMParser();
      const content = parser.parseFromString(
        this.pendingFile.content,
        'text/html'
      );
      content
        .querySelectorAll('.placeholder-inserted[data-issignature="true"]')
        .forEach((el) => {
          el.replaceWith(this.canvas);
        });
      content.querySelectorAll('canvas').forEach((el) => {
        let image = new Image();
        image.src = el.toDataURL('image/svg');
        el.replaceWith(image);
      });
      this.dialogRef.close({
        content: content.body.innerHTML,
        name: this.fileName,
        options: this.options,
        scope: this.parser.thread.scope,
        assigned: this.assignees,
        path: this.fileFolder,
        isSigned: true,
        generatedAt: dayjs().toISOString(),
      });
      return;
    } else {
      // Set document metadata
      this.pdfLibDoc.setTitle(this.fileName);
      this.pdfLibDoc.setCreationDate(new Date(Date.now()));
      // Obtain the page with the first signature field
      const pages = this.pdfLibDoc.getPages();
      await Promise.all(
        this.signatureFields.map(async (sig) => {
          let page = pages[sig.pageNum - 1];
          // Embed the image in the PDF
          page.moveTo(sig.x, page.getHeight() - sig.y);
          // Draw svg on pdf
          const parsed = new DOMParser().parseFromString(
            this.svgPath,
            'image/svg+xml'
          );

          parsed.querySelectorAll('path').forEach((path) => {
            page.drawSvgPath(path.getAttribute('d'), {
              color: rgb(0, 0, 0),
              borderWidth: parseInt(path.getAttribute('stroke-width')),
              borderLineCap: LineCapStyle.Round,
              scale: this.scaleFactor,
            });
          });
        })
      );

      // Serialize the PDF to bytes (a Uint8Array)
      const pdfBytes = await this.pdfLibDoc.save();
      const file = new File([pdfBytes], this.fileName, {
        type: 'application/pdf',
      });
      file['isSigned'] = true;
      this.dialogRef.close(file);
    }
  }

  zoomOut() {
    this.scale -= 0.1;
  }

  zoomIn() {
    this.scale += 0.1;
  }

  closeWithoutSave() {
    this.dialogService
      .confirm({
        text: 'node.label.confirmSignatureClose',
        buttons: [
          {
            text: 'common.button.back',
            accept: false,
          },
          {
            text: 'common.button.close',
            accept: true,
            raised: true,
          },
        ],
      })
      .then((resp) => {
        if (!resp) return;
        this.dialogRef.close();
      });
  }
}
