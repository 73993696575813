/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccessPermission } from './accessPermission';


export interface FileInfo { 
    can_write?: boolean;
    folder?: string;
    is_folder?: boolean;
    mime?: string;
    name?: string;
    permissions?: Array<AccessPermission>;
    size?: string;
    updated_at?: string;
    uploaded_at?: string;
    uuid?: string;
}

