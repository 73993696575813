import { NgModule } from '@angular/core';
import { SearchMenuComponent } from './search-menu.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [SearchMenuComponent],
  exports: [SearchMenuComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatTooltipModule,
    TranslateModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    PipesModule,
  ],
})
export class SearchMenuModule {}
