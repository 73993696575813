/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TranslationDefinition } from './translationDefinition';


export interface CorporateIdentity { 
    address?: string;
    background_image?: string;
    contact_fax?: string;
    contact_mail?: string;
    contact_phone?: string;
    dse_link?: string;
    further_infos?: string;
    greeting_text?: string;
    imprint_link?: string;
    law_firm?: string;
    primary_color?: string;
    secondary_color?: string;
    translations?: Array<TranslationDefinition>;
    website?: string;
}

