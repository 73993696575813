/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InitialThreadStructure } from './initialThreadStructure';


/**
 * An request for creating an execution state object
 */
export interface ExecutionStateRequest { 
    chats?: InitialThreadStructure;
    clients?: any | null;
    closed?: boolean;
    dataStore?: any | null;
    fromProcess?: string;
    lawyers?: any | null;
    meta?: object;
    name?: string;
    nodeTemplate?: object;
    sync_id?: string;
    sync_meta?: any | null;
    sync_target?: string;
    widgets?: Array<object>;
}

