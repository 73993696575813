import { AvailableFilter } from 'advoprocess/lib/types/filter';

export const notificationFilters: AvailableFilter[] = [
  {
    id: 'state_file_name',
    label: 'lawyer.dashboard.notifications.filter.stateName',
    internal_name: 'state.file_name',
    icon: 'label',
    type: 'string',
  },
];
