import { ChatMessage } from 'advoprocess';
import { FormEntry } from 'advoprocess/lib/nodes/default-nodes/form.node';
import { getDropDownOptions } from 'advoprocess/lib/types/question';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as _ from 'lodash';

export type ExtendedFormEntry = FormEntry & {
  error?: boolean;
  message?: ChatMessage;
  isHeading?: boolean;
};

export function prefillFormField(field: ExtendedFormEntry) {
  const urlParams = new URLSearchParams(window.location.search);
  const targetKey = encodeURIComponent(`data.${field.refId}`);
  let targetValue: string | { [key: string | number]: any } | number =
    urlParams.get(targetKey);
  try {
    targetValue = JSON.parse(targetValue);
  } catch {}
  if (targetValue) {
    if (typeof targetValue === 'string' && !targetValue.length) return;
    let availableOptions:
      | string
      | {
          text: string;
          target: string;
        }[];
    switch (field.question.questionType?.value) {
      case 'number':
        const parsed = parseFloat(targetValue as any);
        if (_.isNaN(parsed)) return;
        field.value = parsed;
        break;
      case 'dropdown':
        availableOptions = getDropDownOptions(field.question.defaultValue);
        if (
          typeof availableOptions === 'string' ||
          availableOptions.some((o) => o.text === targetValue)
        ) {
          field.value = targetValue;
        }
        break;
      case 'check':
        availableOptions = getDropDownOptions(field.question.defaultValue);
        if (typeof targetValue === 'string') {
          targetValue = [targetValue];
        }
        if (!_.isArray(targetValue)) return;
        field.value = targetValue;
        break;
      case 'date':
        if (typeof targetValue !== 'string') return;
        if (!field.question?.requiredFormat?.value?.length) return;
        const isoDateRegex =
          /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
        let newDate: dayjs.Dayjs;
        dayjs.extend(customParseFormat);
        if (isoDateRegex.test(targetValue)) {
          newDate = dayjs(targetValue);
        } else {
          newDate = dayjs(targetValue, field.question.requiredFormat.value);
        }
        if (newDate?.isValid()) {
          field.value = newDate.format(
            field.question.requiredFormat.value as string
          );
        }
        break;
      default:
        if (!_.isObject(targetValue) && !_.isArray(targetValue)) {
          field.value = targetValue;
        }
        break;
    }
  }
}
