import { AvailableFilter } from 'advoprocess/lib/types/filter';

export const clientsFilters: AvailableFilter[] = [
  {
    id: 'client_full_name',
    label: 'client.filter.label.name',
    internal_name: 'full_name',
    icon: 'label',
    type: 'string',
  },
  {
    id: 'client_first_name',
    label: 'client.filter.label.firstName',
    internal_name: 'first_name',
    icon: 'label',
    type: 'string',
  },
  {
    id: 'client_last_name',
    label: 'client.filter.label.lastName',
    internal_name: 'last_name',
    icon: 'label',
    type: 'string',
  },
  {
    id: 'client_mail',
    label: 'client.filter.label.mail',
    internal_name: 'mail',
    icon: 'email',
    type: 'string',
  },
  {
    id: 'client_created_at',
    label: 'client.filter.label.created_at',
    internal_name: 'created_at',
    icon: 'today',
    type: 'date',
  },
  {
    label: 'client.filter.label.guest',
    id: 'client_is_guest',
    icon: 'password',
    type: 'boolean',
    options: [
      {
        label: 'client.filter.label.isNotGuest',
        value: false,
      },
      {
        label: 'client.filter.label.isGuest',
        value: true,
      },
      {
        label: 'client.filter.label.internal',
        value: false,
      },
    ],
    internal_name: 'guest',
  },
  {
    id: 'client_sync_id',
    label: 'client.filter.label.sync_id',
    internal_name: 'extern_id',
    icon: 'bookmark',
    type: 'string',
  },
  {
    id: 'client_id',
    label: 'client.filter.label.id',
    internal_name: 'id',
    icon: 'tag',
    type: 'string',
  },
  {
    id: 'client_variables',
    label: (params) => {
      if (params?.name) {
        return `${params.name}`;
      } else {
        return 'client.filter.label.variables';
      }
    },
    icon: 'tag',
    type: 'string',
    parameters: [
      {
        label: 'client.filter.label.variableName',
        name: 'name',
      },
    ],
    internal_name: (value: string, params: { [key: string]: string }) => {
      const split = params?.name?.split(/>/gm) ?? [''];
      return [
        {
          operand: `variables(name eq ${split[0]}).value`,
          label: `${params.name}`,
          operator: 'contains',
          value,
        },
      ];
    },
    grouping_clause: (value, params) => {
      const split = params?.name?.split(/>/gm) ?? [''];
      return {
        property_name_1: `variables(name eq ${split[0]}).value`,
        label: `${params.name}`,
      };
    },
  },
];
