<div class="refid-input">
  <span class="prefix">#</span>
  <span *ngIf="manualPrefix" class="prefix">{{ manualPrefix }}</span>
  <input [(ngModel)]="currentInput" [attr.size]="currentInput?.length + 1" (keydown)="keydown($event)"
    (blur)="destroy.emit({ text: undefined })" (keyup)="checkForTagRefs()" #inp />
</div>
<div class="options" *ngIf="!manualPrefix">
  <ng-container *ngTemplateOutlet="
      listTemplate;
      context: { options: tagSuggestions, level: 0, currentTag: '' }
    ">
  </ng-container>
  <button mat-button *ngIf="currentInput?.length > 0 && !anyTagSnippedOverlays()" (pointerdown)="
      $event.stopPropagation();
      $event.preventDefault();
      addRefIdNode('QuestionNode', currentInput)
    " (pointermove)="highlightEntry(0, tagSuggestions.length)"
    [class.highlight]="selectedEntryIndexes[0] === tagSuggestions?.length">
    <mat-icon>add</mat-icon>
    <div class="content">
      <span>{{
        "document.edit.addHint" | translate: { name: currentInput }
        }}</span>
    </div>
    <mat-icon>navigate_next</mat-icon>
  </button>
  <button mat-button *ngIf="currentInput?.length > 0 && !anyTagSnippedOverlays()" (pointerdown)="
      $event.stopPropagation();
      $event.preventDefault();
      close(currentInput)
    " (pointermove)="highlightEntry(0, tagSuggestions.length + 1)"
    [class.highlight]="selectedEntryIndexes[0] === tagSuggestions?.length + 1">
    <mat-icon>priority_high</mat-icon>
    <div class="content">
      <span>{{
        "document.edit.assumeHint" | translate: { name: currentInput }
        }}</span>
    </div>
  </button>
  <div class="options sublevel" *ngIf="
      currentInput?.length > 0 &&
      !anyTagSnippedOverlays() &&
      selectedEntryIndexes[0] === tagSuggestions?.length
    " [style.marginTop]="tagSuggestions?.length * 3 + 'rem'">
    <ng-container *ngTemplateOutlet="
        listTemplate;
        context: {
          options: createNewOptions,
          level: 1,
          currentTag: ''
        }
      ">
    </ng-container>
  </div>
</div>

<ng-template #listTemplate let-options="options" let-level="level" let-currentTag="currentTag">
  <button (pointerdown)="
      $event.stopPropagation();
      $event.preventDefault();
      tag.onSelect ? tag.onSelect() : close(currentTag + tag.name, tag)
    " (pointermove)="highlightEntry(level, i)" mat-button class="item" *ngFor="let tag of options; let i = index"
    [class.highlight]="i === selectedEntryIndexes[level]">
    <mat-icon>{{ tag.icon }}</mat-icon>
    <div class="content">
      <span [innerHTML]="
          tag.name +
            (currentArrayIndex && level === 0
              ? '>' + currentArrayIndex.toString()
              : '') | highlight: currentInput
        "></span>
      <span *ngIf="tag?.explanation?.length" [innerHTML]="tag.explanation | highlight: currentInput"
        class="moreinfo"></span>
    </div>
    <mat-icon *ngIf="tag?.options?.length">navigate_next</mat-icon>
  </button>
  <div class="options sublevel" *ngIf="options?.[selectedEntryIndexes?.[level]]?.options?.length"
    [style.marginTop]="(selectedEntryIndexes?.[level] * 3) + 'rem'">
    <ng-container *ngTemplateOutlet="
        listTemplate;
        context: {
          options: options[selectedEntryIndexes[level]].options,
          level: level + 1,
          currentTag:
            currentTag + options[selectedEntryIndexes[level]].name + '>'
        }
      ">
    </ng-container>
  </div>
</ng-template>