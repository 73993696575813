import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, Subscription, Subject, tap, map } from 'rxjs';
import { Notification, NotificationService, NotificationType } from 'src/api';
import { AuthService } from 'src/app/auth/auth.service';
import { WebSocketService } from 'src/app/common/websocket.service';

import { PushNotificationManager } from './PushNotificationManager';

@Injectable({
  providedIn: 'root',
})
export class DossierNotificationService {
  private reloadDossierMessages: string = ''; // (bug: in messages tap -> move to another tap -> a notification recieved -> retun to messages tap, the thread is neither reloaded or update)
  private notfication$: Observable<{ [key: string]: any }>;

  public blockNotifications: boolean = false;

  /**
   * Contains existing notifications of the dossier the service is setup for.
   * Incoming notifications are stored here, too.
   */
  private _notifications: Notification[] = [];

  public onNotificationsUpdate$ = new Subject<void>();

  /**
   * Stores the id of the dossier we are watching.
   */
  private _setupForDossierId?: string;

  private newDossierNotificationSubscription?: Subscription;

  get notifications(): Notification[] {
    return this._notifications;
  }

  get setupForDossierId(): string {
    return this._setupForDossierId;
  }

  /**
   * flag to insure that the removed assignment notifications are only fetched once 
   * when the user first enters the client dashboard or the lawyer dossier-list tap
   * 
   * a list to store the removed assignment notifications that were received while not in the client dashboard or the lawyer dossier-list tap
   * the list doesn't affect the rest of the logic, as the notification will be treated normally in the rest of the service
  */
  removedAssignmentNotificationsLoaded = false;
  removedAssignmentNotificationsIds: string[] = [];

  private readonly snackBarConfig: any = {
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'bottom',
    panelClass: ['notification-snackbar'],
  };

  private assignmentMessages = {
    'dossier.assignee.added': 'common.notification.dossier_assignee_added',
    'dossier.assignee.removed': 'common.notification.dossier_assignee_removed'
  };

  private newDossierAssignment$ = new Subject<{ id: string; type: string }>();
  get onNewDossierAssignment$() {
    return this.newDossierAssignment$.asObservable();
  }

  private fileNotification$ = new Subject<{ folderIdTree: string[]; fileId: string; stateId: string; action: string; }>();
  get onFileNotification$() {
    return this.fileNotification$.asObservable();
  }

  constructor(
    private socketService: WebSocketService,
    private snackBar: MatSnackBar,
    private router: Router,
    private translator: TranslateService,
    private notificationService: NotificationService,
    private auth: AuthService
  ) {
    this.notfication$ = this.socketService.messages$.pipe(
      filter((msg) =>
        msg?.payload?.stateId &&
        msg.payload.meta?.notificationType &&
        !this.blockNotifications
      )
    );

    this.notfication$.subscribe((msg) => {
      if (
        msg.payload.action &&
        this.isAssignmentNotification(msg.payload.action)
      ) {
        this.newDossierAssignment$.next({
          id: msg.payload.dbNotificationId,
          type: msg.payload.meta.notificationType,
        });

        // if the notification is a removed assignment, and you are not in the client dashboard or the lawyer dossier-list tap, 
        // store it in the removedAssignmentNotifications list.
        if (msg.payload.action == 'dossier.assignee.removed' && !this.isInClientDashboard() && !this.isInLawyerDossierListTab()) {
          this.removedAssignmentNotificationsIds.push(msg.payload.dbNotificationId);
        }
      } else if (
        msg.payload.action &&
        this.isFileNotificationActions(msg.payload.action)
      ) {
        this.fileNotification$.next({
          folderIdTree: msg.payload.meta.folderIdTree,
          fileId: msg.payload.meta.fileId,
          stateId: msg.payload.stateId,
          action: msg.payload.action
        });
      }
    });

    // handle UI notifications
    PushNotificationManager.buildCombinedNotificationStream().subscribe(
      (notification) => {
        this.showNotification(
          notification.dossierName,
          notification.threadName,
          notification.route,
          notification.isSameDossier,
          notification.notificationAction,
          notification.meta
        );
      }
    );
  }


  notifyInDossier() {
    this.notfication$.subscribe((msg) => {
      if (!this.isInDossier()) return;

      if (this.getDossierId() === msg.payload.stateId) {
        if (this.router.url.includes('messages') && [NotificationType.NewMessage, NotificationType.NewMention].includes(msg.payload.meta.notificationType)) {
          // if in the thread, mark the new messages/mention as seen
          if (this.router.url.includes(msg.payload.threadId)) {
            this.markNotificationAsSeen(msg.payload.dbNotificationId);
          }
          return;
        }
        this.reloadDossierMessages = msg.payload.stateId; // in the dossier but not in the messages tap, so reload the messages
      } else if (
        this.reloadDossierMessages &&
        this.getDossierId() !== this.reloadDossierMessages
      ) {
        this.reloadDossierMessages = ''; // left the dossier, so reset the reload flag
      }

      const threadRoute = this.buildThreadRoute(
        msg.payload.stateId,
        msg.payload.threadId,
        msg.payload.action,
        msg.payload?.meta?.folderIdTree
      );

      PushNotificationManager.dispatchNotification(
        {
          dossierName: msg.payload.stateName,
          threadName: msg.payload.threadName,
          route: threadRoute,
          isSameDossier: this.getDossierId() === msg.payload.stateId,
          notificationAction: msg.payload.action,
          meta: msg.payload.meta
        }
      );
    });
  }

  notifyInDashboard() {
    this.notfication$.subscribe((msg) => {
      const isLawyerDashboard = this.isInLawyerDashboard();
      const isClientDashboard = this.isInClientDashboard();
      if (!isClientDashboard && !isLawyerDashboard) return;

      const threadRoute = this.buildThreadRoute(
        msg.payload.stateId,
        msg.payload.threadId,
        msg.payload.action,
        msg.payload?.meta?.folderIdTree
      );

      PushNotificationManager.dispatchNotification(
        {
          dossierName: msg.payload.stateName,
          threadName: msg.payload.threadName,
          route: threadRoute,
          isSameDossier: this.getDossierId() === msg.payload.stateId,
          notificationAction: msg.payload.action,
          meta: msg.payload.meta
        }
      );
    });
  }

  /**
   * Setup the service to handle and display notifications of a given dossier.
   *
   * Fetches all stored notifications of dossier and stores
   * incoming notifications of given dossier.
   *
   * @param dossierId Id of dossier to be opened
   */
  setupForDossier(dossierId: string): Observable<Notification[]> {
    this._setupForDossierId = dossierId;

    //Stop storing notifications of 'old' dossier
    if (this.newDossierNotificationSubscription)
      this.newDossierNotificationSubscription.unsubscribe();

    //Store new notifications of set up dossier
    this.newDossierNotificationSubscription = this.notfication$
      .pipe(filter((msg) => msg.payload.stateId == this.getDossierId()))
      .subscribe((msg) => {
        if (this._notifications.some((n) => n.id === msg.payload.dbNotificationId)) {
          return;
        }

        // if a deleted file or a file permission removed notification is received, we don't want to show it in the bubbles
        if (
          this.isFileNotificationActions(msg.payload.action) && 
          (msg.payload.action.includes('no_access') || msg.payload.action.includes('deleted'))
        ) return;
        
        this._notifications.push({
          stateid: msg.payload.stateId,
          threadid: msg.payload.threadId,
          id: msg.payload.dbNotificationId,
          type: msg.payload.meta.notificationType,
          meta: msg.payload.meta,
        });
        this.onNotificationsUpdate$.next();
      });

    if (!this.auth.loggedIn) return;

    return this.notificationService
      .queryNotifications({
        filterViewPagination: {
          filter: [
            {
              operand: 'state_id',
              operator: 'eq',
              value: dossierId,
            },
            {
              operand: 'seen',
              operator: 'eq',
              value: 'false',
            }
          ],
          view: {
            displayed_columns: [
              {
                display_name: 'id',
                internal_name: 'id',
              },
              {
                display_name: 'stateid',
                internal_name: 'state_id',
              },
              {
                display_name: 'threadid',
                internal_name: 'threadid',
              },
              {
                display_name: 'type',
                internal_name: 'type',
              },
              {
                display_name: 'meta',
                internal_name: 'meta',
              }
            ],
            sort_by: {
              by: 'threadid',
              direction: 'asc',
            },
          },
          pagination: {
            page: 1,
            rows_per_page: 500,
          },
        },
      })
      .pipe(
        map(({ notifications }) => {
          return notifications.map(notification => {
            if (notification.type === NotificationType.FileUpdated) {
              (notification.meta as any).folderIdTree = JSON.parse((notification.meta as any).folderIdTree);
            }

            return notification;
          });
        }),
        tap((notifications) => {
          this._notifications = notifications;
          this.markSeenByType(NotificationType.ParticipantAdded);
          this.onNotificationsUpdate$.next();
        })
      );
  }

  showNotification(
    dossierName: string,
    threadName: string,
    route: string,
    isSameDossier: boolean,
    notificationAction: string,
    meta: any
  ) {
    let message = 'common.notification.dossier';
    let name = dossierName;
    let params = {};

    if (isSameDossier) {
      if (threadName) {
        message = 'common.notification.chat';
        name = threadName;
      } else {
        // chat without name
        message = 'common.notification.current_dossier';
        name = '';
      }
    }

    if (this.isAssignmentNotification(notificationAction)) {
      message = this.assignmentMessages[notificationAction];
      name = dossierName;
    } else if (this.isFileNotificationActions(notificationAction)) {
      const folder = meta?.folderNameTree?.join('/');

      message = `common.notification.${notificationAction.replace(/\./g, '_')}`;
      name = '';
      params = {
        name: meta.actor,
        file: folder ? `${folder}/${meta.fileName}` : meta.fileName,
        dossierName: dossierName
      };
    }

    const showAction = this.shouldNotificationHaveActionBtn(notificationAction);

    const snackBarRef = this.snackBar.open(
      this.translator.instant(message, params) + ` ${name}`,
      showAction ? '➔' : '',
      this.snackBarConfig
    );

    if (showAction) {
      snackBarRef.onAction().subscribe(() => {
        this.goToDossier(route);
      });
    }
  }

  shouldNotificationHaveActionBtn(action: string): boolean {
    // don't show action button for removed assignments
    if (this.isAssignmentNotification(action) && action.endsWith('removed')) {
      return false;
    }

    return true;
  }

  isInDossier(): boolean {
    const urlSegments = this.router.parseUrl(this.router.url).root.children
      .primary.segments;
    return urlSegments.some((segment) => segment.path === 's');
  }

  isInLawyerDossierListTab(): boolean {
    const urlSegments = this.router.parseUrl(this.router.url).root.children
      .primary.segments;
    return urlSegments[1]?.path === 'intern' && urlSegments[2]?.path === 'dossier';
  }

  isInLawyerDashboard(): boolean {
    const urlSegments = this.router.parseUrl(this.router.url).root.children
      .primary.segments;
    return urlSegments[1]?.path === 'intern' && !this.isInDossier();
  }

  isInClientDashboard(): boolean {
    const urlSegments = this.router.parseUrl(this.router.url).root.children
      .primary.segments;
    return (
      !urlSegments.some((segment) => segment.path === 'intern') &&
      !this.isInDossier()
    );
  }

  buildThreadRoute(dossierId: string, threadId: string, action: string, folderIdTree?: string[]): string {
    let baseRoute = this.router.url.split('/')[1]; // realm
    if (this.router.url.includes('intern')) {
      baseRoute += '/intern'; // realm/intern
    }
    baseRoute += `/s/${dossierId}/messages/${threadId}`;

    // if the action is dossier assignment, go to the dossier itself, not the thread
    if (action in this.assignmentMessages && action.startsWith('dossier')) {
      baseRoute = baseRoute.split('/messages')[0];
    }

    // if the action is file related, go to the file tap
    if (this.isFileNotificationActions(action)) {
      baseRoute = baseRoute.split('/messages')[0] + '/files';
      if (folderIdTree) {
        baseRoute += '/' + folderIdTree.join('/');
      }
    }

    return baseRoute;
  }

  goToDossier(route: string) {
    if (this.isInDossier()) {
      // easiest solution to reload the dossier component (go out and then go in with clean state)
      this.router.navigate([this.router.url.split('/s/')[0]]).then(() => {
        this.router.navigate([route]);
      });
    } else {
      this.router.navigate([route]);
    }
  }

  getDossierId(): string {
    const urlSegments = this.router.parseUrl(this.router.url).root.children
      .primary.segments;
    const dossierIdx = urlSegments.findIndex((segment) => segment.path === 's');
    return urlSegments[dossierIdx + 1]?.path;
  }

  shouldReloadDossierMessages(dossierId: string): boolean {
    return this.reloadDossierMessages === dossierId;
  }

  isAssignmentNotification(action: string): boolean {
    return action in this.assignmentMessages;
  }

  isFileNotificationActions(action: string): boolean {
    return action.startsWith('file.');
  }

  /**
   * Simple helper function to determine
   * whether a thread has a notification
   *
   * @param threadid Id of thread
   */
  threadHasNotification(threadid: string) {
    return this.notifications.some((v) => {
      return v.threadid === threadid;
    });
  }


  markThreadNewMessagesAsSeen(threadId: string) {
    const newMessageNotificationIds = this.notifications
      .filter(
        (notification) =>
          notification.threadid === threadId &&
          [NotificationType.NewMessage, NotificationType.NewMention].includes(notification.type)
      )
      .map((notification) => notification.id);

    newMessageNotificationIds.forEach((notificationId) => {
      this.markNotificationAsSeen(notificationId);
    });
  }

  /**
   * Marks the removed assignment notifications as seen.
   * If it is the first time the function is called, it fetches the notifications from the backend and marks them as seen.
   * Otherwise, it marks all the remove assignment notifications that were received while not in the client dashboard or the lawyer dossier-list tap as seen.
   */
  markRemovedAssignmentAsSeen() {
    if (this.removedAssignmentNotificationsLoaded) {
      this.removedAssignmentNotificationsIds.forEach((id) => {
        this.markNotificationAsSeen(id);
      });

      this.removedAssignmentNotificationsIds = [];
    } else {
      this.notificationService
        .queryNotifications({
          filterViewPagination: {
            filter: [
              {
                operand: 'type',
                operator: 'eq',
                value: NotificationType.ParticipantRemoved,
              },
              {
                operand: 'seen',
                operator: 'eq',
                value: 'false',
              },
            ],
            view: {
              displayed_columns: [
                {
                  display_name: 'id',
                  internal_name: 'id',
                },
              ],
            },
            pagination: {
              page: 1,
              rows_per_page: 500,
            },
          },
        })
        .subscribe(({ notifications }) => {
          notifications.forEach((notification) => {
            this.markNotificationAsSeen(notification.id);
          });
          this.removedAssignmentNotificationsLoaded = true;
        });
    }
  }

  private markSeenByType(type: string) {
    this.notifications
      .filter((notification) => type == notification.type)
      .forEach(({ id }) => this.markNotificationAsSeen(id));
  }

  markNotificationAsSeen(id: string, cached: boolean = false): void {
    if (!id) return;
    
    // handle cached notifications (marked as seen but still in the list)
    const notification = this.notifications.find((notification) => notification.id === id);
    if (notification?.seen) return; // if the notification is already marked as seen, it's already removed from the backend
    if (notification) {
      notification.seen = true; // optimistic update, will be reverted if the backend call fails
    }
    
    this.notificationService.markAsSeen({ id }).subscribe({
      next: () => {
        if (cached) return; // if notification is cached, don't remove it from the list or update the UI (it will be removed later e.g in onDestroy)

        const idx = this.notifications.findIndex((notification) => notification.id === id);
        if (idx !== -1) {
            this.notifications.splice(idx, 1);
            this.onNotificationsUpdate$.next();
        }
      },
      error: (_) => {
        if (notification) {
          notification.seen = false; // revert optimistic update
        }
      }
    });
  }

  markFileNotificationsInFolderAsSeen(folderId: string): string[] {
    const filteredNotifications = this.notifications
      .filter((notification) => notification.type === NotificationType.FileUpdated)
      .map((notification: any) => {
        let parentFolderId = undefined;
        if (notification.meta.folderIdTree.length > 0) {
          parentFolderId = notification.meta.folderIdTree[notification.meta.folderIdTree.length - 1]
        }

        return {
          id: notification.id,
          parentFolderId
        };
      })
      .filter(({ parentFolderId }) => parentFolderId === folderId);

      filteredNotifications.forEach(({ id }) => this.markNotificationAsSeen(id, true));

      return filteredNotifications.map(({ id }) => id);
  }

  deleteCachedNotifications(cachedNotificationIds: string[]) {
    this._notifications = this.notifications.filter(({ id }) => !cachedNotificationIds.some((cachedId) => cachedId === id));
    this.onNotificationsUpdate$.next();
  }
}
