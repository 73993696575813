import { Component, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { GeneratedDocument } from 'advoprocess';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import dayjs from 'dayjs';
import {
  Observable,
  Subject,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { Template, TemplatesService } from 'src/api';
import { PermissionsService } from 'src/app/auth/permissions.service';
import { filterTerm } from 'src/app/common/helpers';

export interface CreateDocumentDialogData {
  hidePath?: boolean;
  defaultDocumentName?: string;
  defaultPath?: string;
}

@Component({
  selector: 'app-create-document-dialog',
  templateUrl: './create-document-dialog.component.html',
  styleUrls: ['./create-document-dialog.component.scss'],
})
export class CreateDocumentDialog {
  dataForm = new FormGroup({
    name: new FormControl<string>(this.data.defaultDocumentName ?? ''),
    path: new FormControl<string>(this.data.defaultPath ?? ''),
    template: new FormControl<(Template & { definition: any }) | undefined>(
      undefined
    ),
  });

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<CreateDocumentDialog, GeneratedDocument>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public permissions: PermissionsService,
    private templates: TemplatesService,
    private translator: TranslateService
  ) {}

  abortQuery$ = new Subject<void>();
  lastTemplatesResult: MenuEntry<Template>[] = [];

  queryTemplates(searchTerm: string): Observable<MenuEntry<Template>[]> {
    this.abortQuery$.next();
    return timer(1000).pipe(
      switchMap(() =>
        this.templates.listTemplates({
          filterViewPagination: {
            filter: [
              {
                operator: 'or',
                filters: [
                  {
                    operand: 'type',
                    operator: 'eq',
                    value: 'text',
                  },
                  {
                    operand: 'definition.type',
                    operator: 'eq',
                    value: 'DocumentNode',
                  },
                ],
              },
              {
                operand: 'name',
                operator: 'contains',
                value: searchTerm,
              },
            ],
            pagination: {
              page: 1,
              rows_per_page: 10,
            },
            view: {
              displayed_columns: [
                {
                  display_name: 'name',
                  internal_name: 'name',
                },
                {
                  display_name: 'id',
                  internal_name: 'id',
                },
                {
                  display_name: 'type',
                  internal_name: 'type',
                },
                {
                  display_name: 'nodeType',
                  internal_name: 'node_type',
                },
                {
                  display_name: 'definition',
                  internal_name: 'definition',
                },
              ],
            },
          },
        })
      ),
      map((resp) =>
        [
          {
            name: this.translator.instant('common.button.removeSelection'),
            icon: 'close',
            value: undefined,
            group: {
              name: '',
              priority: 10,
            },
          } as MenuEntry<Template | undefined>,
        ]
          .concat(
            resp.templates.map(
              (p: any) =>
                ({
                  name: p.name,
                  value: p,
                } as MenuEntry<Template>)
            )
          )
          .filter((e) => filterTerm(searchTerm, e, this.translator))
      ),
      tap((x) => (this.lastTemplatesResult = x)),
      startWith(this.lastTemplatesResult),
      takeUntil(this.abortQuery$)
    );
  }

  selectTemplate(event: MenuEntry<Template & { definition: any }>) {
    this.dataForm.get('template').setValue(event.value);
    if (event?.value?.definition?.config?.path?.length) {
      this.dataForm.get('path').setValue(event.value.definition.config.path);
    }
  }

  async save(): Promise<void> {
    const template: (Template & { definition: any }) | undefined =
      this.dataForm.get('template').value;
    let content = '<p></p>';
    let options: {
      letterhead: string;
      margins: [number, number, number, number];
      pageNumbering: boolean;
    } = {
      letterhead: undefined,
      margins: [20, 13, 20, 13],
      pageNumbering: false,
    };
    if (!!template) {
      if (template.type === 'text') {
        content = template.definition?.value ?? '<p></p>';
      } else {
        content = template.definition?.config?.content ?? '<p></p>';
        options = {
          letterhead: template.definition?.config?.letterhead ?? undefined,
          margins: template.definition?.config?.margin ?? [20, 13, 20, 13],
          pageNumbering: template.definition?.config?.pageNumbering ?? false,
        };
      }
    }
    const newDocument: GeneratedDocument = {
      name: this.dataForm.get('name').value,
      path: this.dataForm.get('path')?.value?.length
        ? this.dataForm.get('path').value
        : undefined,
      assigned: [],
      generatedAt: dayjs().toISOString(),
      hideOutput: true,
      content,
      options,
    };
    this.dialogRef.close(newDocument as GeneratedDocument);
  }
}
