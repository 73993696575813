/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Tenant } from './tenant';
import { KeycloakClient } from './keycloakClient';


/**
 * The response after having created a tenant.
 */
export interface TenantCreationResponse { 
    default_user_id?: string;
    keycloakClients?: Array<KeycloakClient>;
    tenant?: Tenant;
}

