/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LawyerCorporateIdentity { 
    address?: string;
    contact_fax?: string;
    contact_mail?: string;
    contact_phone?: string;
    further_infos?: string;
    lawyer_headshot?: string;
}

