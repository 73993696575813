import { Component, Inject, Optional } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
  FormattedDataAnswer,
  formatTag,
  reverseJSONPreview,
} from 'advoprocess';
import { FieldChangeEvent } from '../data-render-table/data-render-table.component';
import * as _ from 'lodash';
import { wrapInJWT } from 'advoprocess/lib/helpers/parser';
import { AuthService } from 'src/app/auth/auth.service';

export interface DataRenderDialogConfig {
  data: (FormattedDataAnswer & {
    id: string;
    value: any;
  })[];
  validator?: (values: FormattedDataAnswer[]) => boolean | string;
  getOptions?: (
    variable: FormattedDataAnswer,
    all: FormattedDataAnswer[]
  ) => any[] | '*';
  label?: string;
}

export interface ChangeDataRecord {
  delete: string[];
  change: {
    name: string;
    type: string;
    value: any;
    id?: string;
  }[];
}

@Component({
  selector: 'app-data-render-dialog',
  templateUrl: './data-render-dialog.component.html',
  styleUrls: ['./data-render-dialog.component.scss'],
})
export class DataRenderDialogComponent {
  formattedData: FormattedDataAnswer[];

  operations: ChangeDataRecord = {
    change: [],
    delete: [],
  };

  somethingChanged = false;

  validation: boolean | string = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<DataRenderDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DataRenderDialogConfig,
    private auth: AuthService
  ) {
    this.initializeFormattedData();
  }

  preFormatTag(value: any, type: any): string {
    let formatted = formatTag(value, type) as string;
    if (Array.isArray(formatted)) {
      formatted = JSON.stringify(formatted);
    }
    return formatted;
  }

  parseDataValue(val: any, type: string): string {
    let parsed: string;
    if (Array.isArray(val)) {
      parsed = `[${val
        .map((value) => this.preFormatTag(value, type))
        .join(',')}]`;
    } else {
      parsed = this.preFormatTag(val, type) as string;
    }
    try {
      parsed = JSON.parse(parsed);
      if (_.isObject(parsed)) {
        /* Is a JSON-searializable data, should be rendered as JSON */
        return {
          type: 'json',
          data: [
            {
              key: 'Object',
              skipPath: true,
              value: this.buildJSONPreview(parsed),
              type: 'json',
              folded: true,
            },
          ],
        } as any as string;
      }
    } catch {}
    parsed = wrapInJWT(
      new DOMParser().parseFromString(parsed, 'text/html'),
      this.auth.jwtToken$.value
    ).body.innerHTML;
    return parsed;
  }

  buildJSONPreview(data: any): any {
    return Object.entries(data).map(([key, value]) => {
      const hasJSONChildren = value && _.isObject(value);
      return {
        key,
        value: hasJSONChildren ? this.buildJSONPreview(value) : value,
        type: hasJSONChildren ? 'json' : 'plain',
        folded: true,
      };
    });
  }

  initializeFormattedData(): {
    name: string;
    type: string;
    value: any;
  }[] {
    if (!this.data || !this.data.data) {
      return;
    }
    const message = this.data.data;
    const buffer: FormattedDataAnswer[] = [];
    for (const element of message) {
      buffer.push({
        name: element.name,
        value: this.parseDataValue(element.value, element.type ?? ''),
        type: element.type ?? '',
      });
    }
    this.formattedData = buffer;
  }

  save() {
    this.dialogRef.close(this.operations);
  }

  fieldChange(event: FieldChangeEvent) {
    this.somethingChanged = true;
    let point = this.data.data.find((d) => d.name === event.oldFieldName);
    if (!point) {
      point = {
        name: event.oldFieldName,
        type: 'string',
        value: event.oldValue,
        id: undefined,
      };
      this.data.data.push(point);
    }
    point.name = event.newFieldName;
    if (!point.name) {
      this.data.data.splice(this.data.data.indexOf(point), 1);
      if (point.id) this.operations.delete.push(point.id);
      this.initializeFormattedData();
      return;
    }
    let parsed = event.newValue;
    (() => {
      const questionType = (point.meta as any)?.questionType as
        | string
        | undefined;
      if (
        (!questionType || questionType === 'number') &&
        parseFloat(parsed) &&
        !Number.isNaN(parseFloat(parsed))
      ) {
        point.value = parseFloat(parsed);
        point.type = 'number';
        return;
      }
      if (_.isObject(parsed)) {
        if (parsed['type'] === 'json' && parsed['data']) {
          point.value = reverseJSONPreview((parsed as any).data);
          point.value = point.value['Object'];
        } else {
          point.value = parsed;
        }
        point.type = 'object';
        return;
      }
      try {
        parsed = JSON.parse(parsed);
        if (_.isObject(parsed)) {
          if (parsed['type'] === 'json' && parsed['data']) {
            point.value = reverseJSONPreview((parsed as any).data);
          } else {
            point.value = parsed;
          }
          point.type = 'object';
          return;
        }
      } catch {}
      point.value = event.newValue;
      point.type = 'string';
      return;
    })();
    this.initializeFormattedData();

    this.operations.delete = this.operations.delete.filter(
      (d) => d !== point.id
    );
    this.operations.change = this.operations.change.filter(
      (d) => d.id !== point.id
    );

    this.operations.change.push({
      name: point.name,
      type: point.type,
      value: point.value,
      id: point.id,
    });
  }
}
