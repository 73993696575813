<h1 mat-dialog-title>{{ "common.label.clientVariables" | translate }}</h1>

<mat-dialog-content>
  <app-data-render-table [data]="formattedData" (fieldChange)="fieldChange($event)" [validator]="data.validator"
    (afterValidate)="validation = $event" *ngIf="formattedData" [getOptionsFor]="data?.getOptions"
    [label]="data?.label"></app-data-render-table>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ "common.button.close" | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!somethingChanged || validation !== true">
    <mat-icon>save</mat-icon>
    {{ "common.button.save" | translate }}
  </button>
</mat-dialog-actions>