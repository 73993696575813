/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NotificationType = 'NEW_MESSAGE' | 'NEW_MENTION' | 'PARTICIPANT_ADDED' | 'PARTICIPANT_REMOVED' | 'FILE_UPDATED';

export const NotificationType = {
    NewMessage: 'NEW_MESSAGE' as NotificationType,
    NewMention: 'NEW_MENTION' as NotificationType,
    ParticipantAdded: 'PARTICIPANT_ADDED' as NotificationType,
    ParticipantRemoved: 'PARTICIPANT_REMOVED' as NotificationType,
    FileUpdated: 'FILE_UPDATED' as NotificationType
};

