<div class="label">
  {{ label }}
</div>
<div class="search-bar">
  <input [(ngModel)]="searchTerm" type="text" [placeholder]="'common.label.search' | translate" />
</div>
<ng-scrollbar>
  <div class="icon-grid">
    <div class="icon-button" *ngFor="let icon of allIcons" (click)="selectIcon(icon)" [class.selected]="value === icon"
      [matTooltip]="icon">
      <mat-icon>{{ icon }}</mat-icon>
    </div>
  </div>
</ng-scrollbar>