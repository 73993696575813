<!-- <mat-icon *ngIf="sender === 'bot'">auto_awesome</mat-icon> -->
<svg *ngIf="sender === 'bot'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1311.19 1174.07">
  <g id="Ebene_1-2">
    <g>
      <path
        d="m34.06,917.24l16.47-125.65c62.76,8.23,159.29,11.38,262.76-20.34,126.16-38.68,206.8-83.84,300.16-136.14,41.93-23.49,85.3-47.78,136.59-73.83l14.1-7.18c109.03-55.64,273.78-139.71,487.59-112.87l-15.8,125.74c-175.12-22-307.68,45.65-414.19,100l-14.31,7.29c-49,24.88-91.23,48.54-132.06,71.41-95.57,53.53-185.83,104.09-324.95,146.73-125.23,38.39-241.17,34.7-316.38,24.84Z" />
      <path
        d="m0,1171.4l16.46-125.65c55.33,7.25,194.53-9.51,286.7-52.26,122.24-56.71,220.78-163.54,316.07-266.87,45.84-49.7,89.14-96.66,134.32-137.31,130.9-117.78,312.38-171.97,497.93-148.67l-15.8,125.74c-204.01-25.62-336.54,62.39-397.37,117.14-40.79,36.7-82.14,81.55-125.93,129.02-98.84,107.17-210.86,228.63-355.9,295.91-110.26,51.15-273.35,73.86-356.49,62.96Z" />
      <path
        d="m1277.14,256.83l-16.47,125.65c-62.76-8.23-159.29-11.38-262.76,20.34-126.16,38.68-206.8,83.84-300.16,136.14-41.93,23.49-85.3,47.78-136.59,73.83l-14.1,7.18c-109.03,55.64-273.78,139.71-487.59,112.87l15.8-125.74c175.12,22,307.68-45.65,414.19-100l14.31-7.29c49-24.88,91.23-48.54,132.06-71.41,95.57-53.53,185.83-104.09,324.95-146.73,125.23-38.39,241.17-34.7,316.38-24.84Z" />
      <path
        d="m1311.19,2.67l-16.46,125.65c-55.33-7.25-194.53,9.51-286.7,52.26-122.24,56.71-220.78,163.54-316.07,266.87-45.84,49.7-89.14,96.66-134.32,137.31-130.9,117.78-312.38,171.97-497.93,148.67l15.8-125.74c204.01,25.62,336.54-62.39,397.37-117.14,40.79-36.7,82.14-81.55,125.93-129.02,98.84-107.17,210.86-228.63,355.9-295.91C1064.97,14.48,1228.06-8.22,1311.19,2.67Z" />
    </g>
  </g>
</svg>
<mat-icon *ngIf="sender !== 'bot' && !senderInfo">face</mat-icon>
<div class="sender-info" *ngIf="senderInfo" [matTooltip]="senderInfo.name ?? senderInfo.mail ?? ''" tabindex="0"
  [attr.aria-label]="senderInfo.name">
  <mat-icon *ngIf="!senderInfo?.initials">face</mat-icon>
  <mat-icon *ngIf="senderInfo?.initials?.type === 'icon'">{{senderInfo?.initials?.label}}</mat-icon>
  <img *ngIf="senderInfo?.initials?.type === 'profile-pic'" [src]="API_URL +
  '/file/' +
  senderInfo.initials.label +
  '?realm=' +
  realm" />
  <span *ngIf="senderInfo?.initials?.type === 'initials'" aria-hidden="true"> {{ senderInfo.initials?.label }}</span>
</div>