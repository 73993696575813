/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TableViewParametersSortBy { 
    by?: string;
    direction?: TableViewParametersSortBy.DirectionEnum;
}
export namespace TableViewParametersSortBy {
    export type DirectionEnum = 'asc' | 'desc';
    export const DirectionEnum = {
        Asc: 'asc' as DirectionEnum,
        Desc: 'desc' as DirectionEnum
    };
}


