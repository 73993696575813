<div class="realm-selection-container">
  <div class="bg-fade"></div>
  <div class="realm-selection-mask">
    <h1>{{ "common.realmSelection.title" | translate }}</h1>
    <p>
      {{ "common.realmSelection.text" | translate }}
    </p>
    <form [formGroup]="realmData" (onsubmit)="selectRealm()">
      <mat-form-field appearance="fill">
        <mat-label>{{ "common.realmSelection.realm" | translate }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="realm"
          name="realm"
          [matAutocomplete]="auto"
          (blur)="checkValid()"
        />
        <mat-autocomplete
          autoActivateFirstOption
          #auto="matAutocomplete"
          [displayWith]="getRealmName"
        >
          <mat-option
            *ngFor="let realm of realms | async"
            [value]="realm"
            class="sf-realm-selection-option"
          >
            <span class="logo">
              <img
                *ngIf="realm.logo_id?.length"
                [src]="API_URL + '/public/meta/ci/logo/' + realm.slug"
              />
            </span>
            <span class="name">{{ realm.name }}</span>
            <span class="slug">/{{ realm.slug }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div class="error-info" *ngIf="errorMessage">
        {{ errorMessage | translate }}
      </div>
      <button
        type="submit"
        [disabled]="
          !realmData.get('realm')?.value?.slug?.length &&
          !realmData.get('realm')?.value?.length
        "
        mat-raised-button
        color="primary"
        (click)="selectRealm()"
      >
        {{ "common.realmSelection.selectRealm" | translate }}
      </button>
    </form>
  </div>
</div>
