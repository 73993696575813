import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import {
  BooleanOperator,
  FilterCriterium,
  LawyerService,
  Permission,
  PermissionMode,
  PermissionPolicy,
  PermissionsService as APIPermissionsService,
  RolesService,
} from 'src/api';
import * as _ from 'lodash';
import { clientsFilters } from 'src/app/views/process/filters/clients-filters';
import { executionStateFilters } from 'src/app/views/process/filters/execution-state-filters';
import { processFilters } from 'src/app/views/process/filters/process-filters';
import { lawyersFilters } from 'src/app/views/process/filters/lawyers-filters';
import { groupsFilter } from 'src/app/views/process/filters/groups-filters';
import { templatesFilters } from 'src/app/views/process/filters/templates-filters';
import { filterTerm, isFilterCriterium } from '../helpers';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import { TranslateService } from '@ngx-translate/core';
import { PermissionsService } from 'src/app/auth/permissions.service';
import {
  AvailableFilter,
  ExtendedFilterCriterium,
} from 'advoprocess/lib/types/filter';
import { analyticsDataViewFilters } from 'src/app/views/process/filters/analytics-filters';

type ExtendedPermissionMode = PermissionMode & {
  extendedFilters: ExtendedFilterCriterium[];
  availableFilters: AvailableFilter[];
};

type ExtendedPermissionPolicy = PermissionPolicy & {
  read: ExtendedPermissionMode;
  write: ExtendedPermissionMode;
};

@Component({
  selector: 'app-permission-edit',
  templateUrl: './permission-edit.component.html',
  styleUrls: ['./permission-edit.component.scss'],
})
export class PermissionEditComponent implements OnChanges {
  @Input() entity: 'lawyer' | 'role' = 'lawyer';
  @Input() userId: string;
  @Input() testModel: any;

  permissions: Permission[];

  policies: ExtendedPermissionPolicy[] = [];

  focusPermissionIndex = -1;

  possibleModes = Object.values(PermissionMode.ModeEnum);

  constructor(
    private api: APIPermissionsService,
    private service: PermissionsService,
    private lawyers: LawyerService,
    private roles: RolesService,
    private translator: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userId || changes.entity) {
      this.initPermissions();
    }
  }

  private initPermissions() {
    if (!this.userId) return;
    let query: Observable<Array<Permission>>;
    if (this.entity === 'lawyer') {
      query = this.lawyers.getLawyerPermissions({
        userid: this.userId,
      });
    } else {
      query = this.roles.getRolePermissions({
        roleid: this.userId,
      });
    }
    query.subscribe((perm) => {
      this.permissions = perm;
      this.focusPermissionIndex = -1;
      this.buildPolicyTable();
    });
  }

  buildPolicyTable() {
    let policies: Array<PermissionPolicy>;
    if (
      this.focusPermissionIndex > 0 &&
      this.focusPermissionIndex < this.permissions.length
    ) {
      policies = this.permissions[this.focusPermissionIndex].policies;
    } else {
      policies = this.combinePolicies();
    }
    this.policies = Object.values(PermissionPolicy.ModelEnum).map((model) => {
      return {
        model,
        read: this.extendPermissionMode(
          policies.find((p) => p.model === model)?.read,
          model
        ),
        write: this.extendPermissionMode(
          policies.find((p) => p.model === model)?.write,
          model
        ),
      };
    });
  }

  private extendPermissionMode(
    mode: PermissionMode | undefined,
    model: PermissionPolicy.ModelEnum
  ): ExtendedPermissionMode {
    if (!mode) return undefined;
    const mapping: {
      [key in PermissionPolicy.ModelEnum]: AvailableFilter[] | undefined;
    } = {
      clients: clientsFilters,
      execution_states: executionStateFilters,
      legal_processes: processFilters,
      lawyers: lawyersFilters,
      roles: groupsFilter,
      templates: templatesFilters,
      analytics: analyticsDataViewFilters,
      addon_configurations: undefined,
      settings: undefined,
    };
    const match = mapping[model];
    if (!match) return { ...mode, extendedFilters: [], availableFilters: [] };
    return {
      ...mode,
      availableFilters: match,
      extendedFilters:
        mode.filters?.map((f) => {
          return this.extendFilter(f, match);
        }) ?? [],
    };
  }

  private extendFilter(
    f: FilterCriterium | BooleanOperator,
    match: AvailableFilter[]
  ): ExtendedFilterCriterium {
    if (isFilterCriterium(f)) {
      const foundAvailable = match.find(
        (available) =>
          available.id === f.filterId || available.internal_name === f.operand
      );
      const label = f.label
        ? f.label
        : foundAvailable?.label
        ? _.isFunction(foundAvailable.label)
          ? foundAvailable.label({})
          : foundAvailable.label
        : f.operand;
      return {
        ...f,
        icon: foundAvailable?.icon ?? 'question_mark',
        label,
      };
    } else {
      return {
        ...f,
        filters: f.filters.map((f) => {
          return this.extendFilter(f, match);
        }),
      };
    }
  }

  private combinePolicies(): Array<PermissionPolicy> {
    const allPolicies = _.flatten(this.permissions.map((p) => p.policies));

    const strictest = (modes?: PermissionMode[]): PermissionMode => {
      if (!modes?.length) return undefined;
      let filters: Array<FilterCriterium | BooleanOperator> = [];
      for (const m of modes) {
        if (m.mode === PermissionMode.ModeEnum.Forbid) return m;
        if (m.mode === PermissionMode.ModeEnum.Filter) {
          filters = filters.concat(m.filters);
        }
      }
      if (filters.length) {
        return {
          mode: PermissionMode.ModeEnum.Filter,
          filters,
        };
      }
      return {
        mode: PermissionMode.ModeEnum.Allow,
      };
    };

    return Object.values(PermissionPolicy.ModelEnum).map((model) => {
      return {
        model,
        read: strictest(
          allPolicies.filter((m) => m.model === model).map((p) => p.read)
        ),
        write: strictest(
          allPolicies.filter((m) => m.model === model).map((p) => p.write)
        ),
      };
    });
  }

  setMode(rule: PermissionMode, mode: PermissionMode.ModeEnum) {
    rule.mode = mode;
  }

  cachedPermissions: Permission[] = undefined;

  queryPermissions(searchTerm: string): Observable<MenuEntry<Permission>[]> {
    const possibleValues = this.service.getPossibleValuesFor(
      this.entity === 'lawyer'
        ? PermissionPolicy.ModelEnum.Lawyers
        : PermissionPolicy.ModelEnum.Roles,
      this.testModel,
      `permissions.name`,
      '*'
    );

    let obs: Observable<Permission[]>;
    if (this.cachedPermissions) {
      obs = of(this.cachedPermissions);
    } else {
      obs = this.api
        .listPermissions({})
        .pipe(tap((perm) => (this.cachedPermissions = perm)));
    }

    return obs.pipe(
      map((permissions) => {
        return permissions
          .map(
            (perm): MenuEntry<Permission> => ({
              name: perm.name,
              value: perm,
            })
          )
          .filter(
            (m) =>
              filterTerm(searchTerm, m, this.translator) &&
              (possibleValues === '*' || possibleValues.includes(m.value.name))
          )
          .concat([
            {
              name: 'common.button.removeSelection',
              icon: 'close',
              value: undefined,
            },
          ]);
      })
    );
  }

  get userPermissions() {
    return this.permissions?.filter(
      (p) => p.via === (this.entity === 'lawyer' ? 'user' : 'role')
    );
  }

  setUserPermission(entry: MenuEntry<Permission>, policy: Permission) {
    if (!entry.value?.id && policy?.id) {
      return this.api
        .unassignPermission({
          entity: this.entity,
          policyId: policy.id,
          userid: this.userId,
        })
        .subscribe(() => {
          this.initPermissions();
        });
    }
    this.api
      .assignPermission({
        entity: this.entity,
        policyId: entry.value.id,
        userid: this.userId,
        replaceOld: true,
      })
      .subscribe(() => {
        this.initPermissions();
      });
  }
}
