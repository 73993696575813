import { NgModule } from '@angular/core';
import { sanitizeHtmlPipe, sanitizeUrlPipe } from './sanitize.pipe';
import { highlightSubstringPipe } from './highlight-substring.pipe';
import { nodeDocumentPipe } from './node-document.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { appointmentDurationPipe } from './appointment-duration.pipe';

@NgModule({
  declarations: [
    sanitizeHtmlPipe,
    sanitizeUrlPipe,
    highlightSubstringPipe,
    nodeDocumentPipe,
    StripHtmlPipe,
    appointmentDurationPipe
  ],
  exports: [
    sanitizeHtmlPipe,
    sanitizeUrlPipe,
    highlightSubstringPipe,
    nodeDocumentPipe,
    StripHtmlPipe,
    appointmentDurationPipe
  ],
})
export class PipesModule { }
