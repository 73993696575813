/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BooleanOperator } from './booleanOperator';
import { FilterCriterium } from './filterCriterium';


export interface PermissionMode { 
    filters?: Array<FilterCriterium | BooleanOperator>;
    mode: PermissionMode.ModeEnum;
}
export namespace PermissionMode {
    export type ModeEnum = 'ALLOW' | 'FORBID' | 'FILTER';
    export const ModeEnum = {
        Allow: 'ALLOW' as ModeEnum,
        Forbid: 'FORBID' as ModeEnum,
        Filter: 'FILTER' as ModeEnum
    };
}


