<div class="header">
  <button mat-icon-button (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="wrapper">
  <canvas id="signature-pad" #canvasSignArea class="signature-pad" [width]="width" [height]="height"></canvas>
</div>
<div *ngIf="showHint">
  <p>Bitte unterschreiben Sie zuerst.</p>
</div>
<div class="actions">
  <div style="flex: 1 1 0; display: flex; gap: 10px; align-items: center;">
    <button mat-icon-button (click)="clear()">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button (click)="undo()">
      <mat-icon>settings_backup_restore_icon</mat-icon>
    </button>
  </div>
  <button mat-button (click)="savePNG()">
    <mat-icon style="margin-right: 10px;">check_icon</mat-icon>
    <span>{{
      'common.button.accept' | translate
      }}</span></button>
</div>