import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs';
import { DestroyNotifier } from 'src/app/common/destroy-notifier';
import {PublicService} from "../../../api";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends DestroyNotifier {
  public errorMessage: string | undefined = undefined;

  constructor(
    private auth: AuthService,
    private activeRoute: ActivatedRoute,
    private publicAPI: PublicService,
    private translator: TranslateService,
    private activatedRoute: ActivatedRoute,
    router: Router
  ) {
    super();
    const realm = this.activatedRoute.snapshot.paramMap.get('realm');
    this.checkRealmExists(realm);
    this.auth.ready$
      .pipe(
        filter((t) => t),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.auth.loggedIn) {
          setTimeout(() => {
            navigateAfterLogin(router, auth);
          }, 500);
        } else {
          this.auth.logIn();
        }
      });

    this.translator.onLangChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => this.checkRealmExists(realm));
  }


  private checkRealmExists(realm: string) {
    this.publicAPI
      .checkRealm({
        realm,
      })
      .subscribe({
        next: (value) => {
          if (!value) {
            this.errorMessage = this.translator.instant(
              'common.error.noSuchRealm'
            );
          }
        },
        error: () => {
          this.errorMessage = this.translator.instant(
            'common.error.noSuchRealm'
          );
        },
      });
  }
}

export function navigateAfterLogin(router: Router, auth: AuthService) {
  const realm = auth.rawDecoded.realm ?? '';
  const urlParams = new URLSearchParams(window.location.search);
  let redirectionTarget = urlParams.get('redirect');
  if (redirectionTarget && redirectionTarget !== `%2F${realm}`) {
    redirectionTarget = decodeURIComponent(redirectionTarget);
    console.log('redirecting to', redirectionTarget, '...');
    router.navigateByUrl(redirectionTarget);
  } else {
    router.navigateByUrl(
      !realm ? '/' : `/${realm}/${auth.isClient ? '' : 'intern/overview'}`
    );
  }
}
