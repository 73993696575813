/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExecutionStateParticipantRequestParent } from './executionStateParticipantRequestParent';


export interface ExecutionStateParticipantRequest { 
    id?: string;
    parent?: ExecutionStateParticipantRequestParent | null;
    role?: string;
    source?: ExecutionStateParticipantRequest.SourceEnum;
}
export namespace ExecutionStateParticipantRequest {
    export type SourceEnum = 'lawyer' | 'client';
    export const SourceEnum = {
        Lawyer: 'lawyer' as SourceEnum,
        Client: 'client' as SourceEnum
    };
}


