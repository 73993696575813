import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { ChatMessage } from 'advoprocess';
import dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { distinctUntilChanged } from 'rxjs/operators';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

class CustomDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string, platform: Platform, private params: any) {
    super(matDateLocale, platform);

    dayjs.locale('de');
    dayjs.extend(customParseFormat);
    dayjs.extend(localizedFormat);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  get customFormat(): string {
    return this.params?.format ?? 'DD.MM.YYYY';
  }

  parse(value: any): Date | null {
    return dayjs(value, this.customFormat).toDate();
  }

  format(date: Date, displayFormat: any): string {
    return dayjs(date).format(this.customFormat);
  }
}

@Component({
  selector: 'app-calendar-input',
  template: `
    <div class="input-area" *ngIf="inline">
      <mat-form-field appearance="fill" [class.required]="!canBeEmpty">
        <mat-label [innerHtml]="message.title | sanitizeHtml"></mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [formControl]="internalControl"
          [matDatepickerFilter]="dateFilter"
          (change)="handleInputChange($event)"
        />
        <mat-hint>
          Format:
          {{
            this.message.responseRequest?.params.format ?? 'DD.MM.YYYY'
          }}</mat-hint
        >
        <mat-datepicker-toggle matSuffix [for]="picker" [attr.aria-label]="('aria.label.toggleDatepickerFor' | translate) + ' ' + (message.title | sanitizeHtml)"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <span id="date-required" class="sr-only" *ngIf="!canBeEmpty">This field is required</span>
      </mat-form-field>
    </div>
    <mat-calendar
      (selectedChange)="calendarChanged($event)"
      [selected]="toDate(control.value)"
      [dateFilter]="dateFilter"
      *ngIf="!inline"
      [attr.aria-label]="('aria.label.calendarFor' | translate) + ' ' + (message.title | sanitizeHtml)"
    ></mat-calendar>
  `,
  styles: [
    `
      // remove the button style from the calendar cells
      ::ng-deep button.mat-calendar-body-cell {
        background: none !important;
        outline: none !important;
      }
    `
  ],
  providers: [
    {
      provide: DateAdapter,
      useFactory: (
        calendarInput: CalendarInputComponent,
        matDateLocale: string,
        platform: Platform
      ) => {
        return new CustomDateAdapter(
          matDateLocale,
          platform,
          calendarInput.message.responseRequest?.params
        );
      },
      deps: [CalendarInputComponent, MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class CalendarInputComponent implements OnInit {
  @Input() message: ChatMessage;
  @Input() control: UntypedFormControl;

  @Input() inline?: boolean;

  @Output() answered = new EventEmitter<any>();
  @Output() hasError = new EventEmitter<boolean>();

  @Input() canBeEmpty: boolean;

  internalControl = new UntypedFormControl();

  constructor() {
    dayjs.extend(customParseFormat);
  }

  ngOnInit(): void {
    if (this.inline) {
      if (!this.canBeEmpty) {
        this.internalControl.addValidators([Validators.required]);
      }
      if (this.control.value) {
        const d = dayjs(
          this.control.value,
          this.message.responseRequest?.params.format
        );
        if (d.isValid()) {
          this.internalControl.setValue(d.toDate());
        }
      }
      // deleted the distinctUntilChanged
      this.internalControl.valueChanges
        .subscribe((value: Date) => {
          this.calendarChanged(value);
          this.hasError.emit(
            (!this.canBeEmpty && !value) || Boolean(this.internalControl.errors)
          );
        });
      this.hasError.emit(
        (!this.canBeEmpty && !this.internalControl.value) ||
          Boolean(this.internalControl.errors)
      );
    }
  }

  calendarChanged(timestamp): void {
    const answer = dayjs(timestamp).format(
      this.message.responseRequest?.params.format
    );

    if (this.validateDate(answer)) {
      this.control.setValue(answer);
      this.answered.emit(this.control.value);
      this.internalControl.setErrors(null);
      this.hasError.emit(false);
    } else {
      this.internalControl.setErrors({ invalidDate: true });
      this.hasError.emit(true);
    }
  }

  toDate(val: string) {
    return dayjs(val, this.message.responseRequest?.params.format).toDate();
  }

  handleInputChange(event) {
    const d = dayjs(
      event.target.value,
      this.message.responseRequest?.params.format
    );

    if (d.isValid() && this.validateDate(d.format(this.message.responseRequest?.params.format))) {
      this.internalControl.setValue(d.toDate());
    } else {
      this.internalControl.setErrors({ invalidDate: true });
    }

    this.hasError.emit(Boolean(this.internalControl.errors));
  }

  /**
   * Validate a date string according to the responseRequest constraint params
   * @param value the date string to validate
   * @returns true if the date is valid, false otherwise
   *
  */
  validateDate(value: string): boolean {
    // get the format and constraint from the responseRequest params
    const format = this.message.responseRequest?.params.format || 'DD.MM.YYYY';
    const constraint = this.message.responseRequest?.params.constraint || 'all';

    // check if the date is a valid date
    const date = dayjs(value, format);
    if (!date.isValid()) {
      return false;
    }

    // check the constraint
    switch (constraint) {
      case 'past':
        return date.isBefore(dayjs().add(1, 'day'), 'day'); // past + today
      case 'future':
        return date.isAfter(dayjs().subtract(1, 'day'), 'day'); // future + today
      default:
        return true; // all
    }
  }

  /**
   * Filter for the matrial datepicker to only allow selection of dates that are valid according to the responseRequest constraint params
   * @param d the date to check
   * @returns true if the date is valid, false otherwise
  */
  dateFilter = (d: Date | null): boolean => {
    if (!d) return false;

    const date = dayjs(d);
    const constraint = this.message.responseRequest?.params.constraint || 'all';

    switch (constraint) {
      case 'past':
        return date.isBefore(dayjs().add(1, 'day'), 'day');
      case 'future':
        return date.isAfter(dayjs().subtract(1, 'day'), 'day');
      default:
        return true;
    }
  };
}
