import { Injectable } from '@angular/core';
import { AbstractNode, ProcessNode, StartNode } from 'advoprocess';
import { sha256 } from 'js-sha256';
import { Subject } from 'rxjs';
import { binarySearch } from '../common/path.algorithms';
import { SketchManager } from '../sketch-manager';

@Injectable({
  providedIn: 'root',
})
export class NodeOperationsService {
  sketchManager: SketchManager;

  positionsChanged = new Subject<void>();

  addNode(
    nodes: ProcessNode[],
    newNode: AbstractNode,
    x: number,
    y: number,
    outputCount: number,
    mode?: string
  ): ProcessNode {
    if (!this.sketchManager) {
      return;
    }
    newNode.ctx = this.sketchManager;
    // Find unused id
    let seed = Date.now();
    let newId: string = sha256(seed.toString());
    while (nodes.some((n) => n._id === newId)) {
      newId = sha256((seed++).toString());
    }
    const bpn: ProcessNode = {
      _id: newId,
      node: newNode,
      x,
      y,
      assigned:
        (newNode?.constructor as typeof AbstractNode)?.initialAssigned ??
        undefined,
      outputs: new Array(outputCount),
    };
    if (!nodes.find((n) => n instanceof StartNode || n.start)) {
      bpn.start = true;
    }

    nodes.splice(
      binarySearch(nodes, bpn, (a, b) => a.y > b.y),
      0,
      bpn
    );
    if (mode) {
      newNode.onModeChange(mode);
    }
    bpn.outputs = newNode.outputs.map((_) => null);
    nodes.forEach((node) => node.node.onProcessChanged(nodes));
    return bpn;
  }

  pushBetweenNode(
    nodes: ProcessNode[],
    newNode: AbstractNode,
    outPortIndex: number,
    outNode: ProcessNode,
    inNode: ProcessNode,
    outputCount: number,
    mode?: string
  ): Promise<ProcessNode> {
    const x = (outNode.x + inNode.x) / 2;
    const y = (outNode.y + inNode.y) / 2;
    const processNode = this.addNode(nodes, newNode, x, y, outputCount, mode);
    outNode.outputs[outPortIndex] = processNode._id;
    processNode.outputs[0] = inNode._id;
    return new Promise<ProcessNode>((resolve) => {
      window.setTimeout(async () => {
        this.makeRoom(
          nodes,
          y,
          processNode.ui.elementRef.nativeElement.getBoundingClientRect().height
        );
        resolve(processNode);
      }, 0);
    });
  }

  makeRoom(nodes: ProcessNode[], y: number, height: number): void {
    nodes.forEach((node) => {
      node.y = node.y > y ? node.y + height : node.y;
    });
    this.positionsChanged.next();
  }
}
