<!-- <div class="settings-categories">
  <div
    class="setting-selector"
    *ngFor="let group of config"
    [class.active]="foucsGroup === group"
  >
    <mat-icon>{{ group.icon }}</mat-icon>
    {{ group.title | translate }}
  </div>
</div> -->
<div class="settings-content">
  <ng-scrollbar #scrollBar>
    <h1>{{ "lawyer.dashboard.settings.title" | translate }}</h1>
    <ng-container *ngFor="let group of config">
      <div class="settings-group" *ngIf="!config.hideIf || !config.hideIf()">
        <h2>
          <mat-icon aria-hidden="true">{{ group.icon }}</mat-icon>{{ group.title | translate }}
        </h2>
        <div class="settings">
          <ng-container *ngFor="let setting of group.settings">
            <div class="setting" *ngIf="!setting.hideIf || !setting.hideIf()">
              <div class="left">
                <h3>
                  <mat-icon *ngIf="setting.icon" aria-hidden="true">{{ setting.icon }}</mat-icon>{{ setting.name | translate }}
                  <beta-hint *ngIf="setting?.beta"></beta-hint>
                </h3>
                <p *ngIf="setting.description" [innerHTML]="setting.description | translate"></p>
              </div>
              <div class="right" [class.broad]="setting.type === 'text' && setting.multiline">
                <ng-container *ngIf="setting.type === 'text'">
                  <mat-form-field appearance="fill" *ngIf="!setting.multiline">
                    <mat-label>{{ setting.name | translate }}</mat-label>
                    <input matInput type="text" [value]="setting.value"
                      (blur)="saveField(setting, $event.target.value)" [attr.aria-label]="setting.name | translate" />
                  </mat-form-field>
                  <app-rich-setting-editor *ngIf="setting.multiline" (valueChanged)="saveField(setting, $event)"
                    [value]="setting.value" [attr.aria-label]="setting.name | translate"></app-rich-setting-editor>
                </ng-container>
                <ng-container *ngIf="setting.type === 'button'">
                  <button mat-raised-button color="primary" (click)="setting.onChange(null)" [attr.aria-label]="setting.name | translate">
                    {{ setting.name | translate }}
                  </button>
                </ng-container>
                <ng-container *ngIf="setting.type === 'image'">
                  <div class="logo" [class.empty]="!setting.value" (click)="imageFileInput.click()" role="button" tabindex="0" [attr.aria-label]="('aria.label.uploadImageFor' | translate) + ' ' + (setting.name | translate)">
                    <ng-container *ngIf="!setting.value">
                      <mat-icon aria-hidden="true">image</mat-icon>
                      <span>{{ 'process.label.uploadFile' | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="setting.value">
                      <img [src]="setting.value" [alt]="('alt.imgPreviewFor' | translate) + ' ' + (setting.name | translate)" />
                    </ng-container>
                    <input type="file" accept="image/png,image/jpeg" #imageFileInput
                      (change)="setImageFile(setting, $event)" [attr.aria-label]="('aria.label.selectImageFor' | translate) + ' ' + (setting.name | translate)" />
                  </div>
                </ng-container>
                <ng-container *ngIf="setting.type === 'color'">
                  <input [(colorPicker)]="setting.value" [style.background]="setting.value" [value]="setting.value"
                    [class.lightText]="useLightText(setting.value)" readonly cpPosition="top" cpAlphaChannel="disabled"
                    (colorPickerSelect)="updateColor(setting, $event)" class="colorpicker" [attr.aria-label]="('aria.label.selectColorFor' | translate) + ' ' + (setting.name | translate)" />
                </ng-container>
                <ng-container *ngIf="setting.type === 'dropdown'">
                  <mat-form-field appearance="fill">
                    <mat-label>{{ setting.name | translate }}</mat-label>
                    <mat-select [value]="setting.value" (selectionChange)="saveField(setting, $event.value)" [attr.aria-label]="('aria.label.selectOptionFor' | translate) + ' ' + (setting.name | translate)">
                      <mat-select-trigger>
                        <mat-icon *ngIf="findOption(setting)?.icon" aria-hidden="true">{{
                          findOption(setting)?.icon
                          }}</mat-icon>&nbsp;{{ findOption(setting)?.label | translate }}
                      </mat-select-trigger>
                      <mat-option *ngFor="let option of setting.options" [value]="option.value">
                        <mat-icon *ngIf="option.icon" aria-hidden="true">{{ option.icon }}</mat-icon>
                        {{ option.label | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-scrollbar>
</div>
