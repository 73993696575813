import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _sanitizeHTML from 'sanitize-html';
import { environment } from 'src/environments/environment';

const sanitizeHTML = (_sanitizeHTML as any).default || _sanitizeHTML;

@Pipe({ name: 'sanitizeHtml' })
export class sanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    const config = {
      allowedTags: [
        'h1',
        'h2',
        'h3',
        'h4',
        'p',
        'ol',
        'ul',
        'li',
        'a',
        'span',
        'strong',
        'code',
        'br',
        'b',
        'i',
        'em',
        'u',
        'placeholder',
        'icon',
        'img',
        'table',
        'tr',
        'td',
        'div',
        'iframe',
      ],
      allowedAttributes: {
        '*': ['href', 'title', 'class', 'style'],
        iframe: ['src', 'style', 'width', 'height'],
        a: ['target', 'download', 'onclick', 'style'],
        div: ['class', 'eb-*', 'style'],
        placeholder: ['contenteditable', 'style', 'class'],
        span: ['style', 'data-tagid'],
        img: ['src', 'style', 'width', 'height'],
        icon: ['contenteditable'],
        td: ['style'],
        tr: ['style'],
        table: ['style'],
      },
      exclusiveFilter: (frame) => {
        if (frame.tag === 'a') {
          return (
            frame.attribs['onclick'] &&
            !frame.attribs['onclick'].match(
              /javascript:PRINT_CALLBACK_WITH_ID_(.{64})\(\)/
            )
          );
        }
        return false;
      },
      allowedSchemes: ['data', 'https', 'blob'],
    };
    if (environment.env !== 'prod') {
      config.allowedSchemes.push('http');
    }
    const sanitized = sanitizeHTML(value, config);
    return this._sanitizer.bypassSecurityTrustHtml(sanitized);
  }
}

@Pipe({ name: 'sanitizeURL' })
export class sanitizeUrlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
