/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FileHTMLOptions } from './fileHTMLOptions';


export interface FileHTML { 
    format?: FileHTML.FormatEnum;
    html_code?: string;
    options?: FileHTMLOptions;
}
export namespace FileHTML {
    export type FormatEnum = 'default' | 'DATEV' | 'Advolux';
    export const FormatEnum = {
        Default: 'default' as FormatEnum,
        Datev: 'DATEV' as FormatEnum,
        Advolux: 'Advolux' as FormatEnum
    };
}


