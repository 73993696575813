import { AvailableFilter } from 'advoprocess/lib/types/filter';
import { groupsFilter } from './groups-filters';

export const lawyersFilters: AvailableFilter[] = [
  {
    id: 'lawyer_full_name',
    label: 'lawyer.filter.label.fullName',
    internal_name: 'full_name',
    icon: 'label',
    type: 'string',
  },
  {
    id: 'lawyer_first_name',
    label: 'lawyer.filter.label.firstName',
    internal_name: 'first_name',
    icon: 'label',
    type: 'string',
  },
  {
    id: 'lawyer_last_name',
    label: 'lawyer.filter.label.lastName',
    internal_name: 'last_name',
    icon: 'label',
    type: 'string',
  },
  {
    id: 'lawyer_mail',
    label: 'lawyer.filter.label.mail',
    internal_name: 'mail',
    icon: 'email',
    type: 'string',
  },
  {
    id: 'lawyer_created_at',
    label: 'lawyer.filter.label.created_at',
    internal_name: 'created_at',
    icon: 'today',
    type: 'date',
  },
  {
    id: 'lawyer_id',
    label: 'lawyer.filter.label.id',
    internal_name: 'id',
    icon: 'tag',
    type: 'string',
  },
  {
    id: 'state_lawyer_groups',
    label: 'lawyer.dashboard.clients.groups',
    internal_name: 'roles',
    type: 'entity',
    icon: 'groups_2',
    children: groupsFilter,
  },
];
