import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  ChatMessage,
  getPendingFile,
  KNOWN_OPERATORS,
  parseFileReference,
  parseWithPlaceholders,
  toFileReference,
  removePendingFile,
  uploadFile,
  ProcessParser,
  applyComparisonToDataStore,
} from 'advoprocess';
import { wrapInJWT } from 'advoprocess/lib/helpers/parser';
import {
  buildLayout,
  FormEntry,
} from 'advoprocess/lib/nodes/default-nodes/form.node';
import DataStore from 'advoprocess/lib/parser/data-store';
import {
  getValidatorForQuestion,
  parseQuestionConfig,
  QuestionConfig,
} from 'advoprocess/lib/types/question';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { FilesService } from 'src/api';
import { AuthService } from 'src/app/auth/auth.service';
import { ProcessService } from '../../process.service';
import { debounceTime } from 'rxjs';
import { ExtendedFormEntry, prefillFormField } from './prefill-helper';

@Component({
  selector: 'app-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss'],
})
export class FormRendererComponent implements OnChanges {
  @Input() fields: FormEntry[];
  @Input() title: string;
  @Input() readOnly: boolean = false;
  @Input() hideContinue: boolean = false;
  @Input() showPosterBadge: boolean = false;

  @Input() service?: ProcessService;
  @Input() parser?: ProcessParser;
  @Input() previousDataStore?: DataStore;
  @Input() prefillValues?: boolean;

  @Output() answered = new EventEmitter<any>();
  @Output() formStateChange = new EventEmitter<ExtendedFormEntry[]>();

  layout: ExtendedFormEntry[][] = [];

  get isValid(): boolean {
    return !this.layout.some((row, rowindex) =>
      row.some(
        (entry, colindex) =>
          this.shouldRenderQuestion(rowindex, colindex) && entry.error
      )
    );
  }

  uploadingFiles = false;

  initialTimeStamp = null;

  constructor(
    private auth: AuthService,
    private files: FilesService,
    private snackBar: MatSnackBar,
    private el: ElementRef
  ) {
    this.initialTimeStamp = dayjs().toISOString();

    this.formStateChange.pipe(debounceTime(500)).subscribe(() => {
      this.calculateQuestionTexts();
      this.updateRenderQuestionsCache();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields) {
      this.layout = buildLayout(this.fields);
      _.flatten(this.layout).forEach((field) => {
        field.message = this.buildMessage(field);
        if (this.prefillValues) {
          prefillFormField(field);
        }
      });
      this.calculateQuestionTexts();
    }
    // If the form is readonly, all input elements should be disabled
    if (changes.readOnly) {
      setTimeout(() => {
        if (this.readOnly) {
          this.el.nativeElement.querySelectorAll('input, textarea').forEach((i) => {
            i.disabled = true;
            i.classList.add('__done_form_block_input');
          });
        } else {
          this.el.nativeElement
            .querySelectorAll('.__done_form_block_input')
            .forEach((i) => {
              i.disabled = false;
              i.classList.remove('__done_form_block_input');
            });
        }
      }, 50);
    }
  }

  buildMessage(field: ExtendedFormEntry): ChatMessage {
    if (!field.instanceTime) {
      field.instanceTime = dayjs().toISOString();
    }
    const config = field.question;
    const { params, parsedQuestion } = parseQuestionConfig(
      config as unknown as QuestionConfig,
      new DataStore({})
    );

    const typeTranslation: { [key: string]: string } = {
      string: 'word',
      number: 'number',
      dropdown: 'buttons',
    };

    const type =
      typeTranslation[config.questionType?.value as string] ||
      config.questionType?.value ||
      'word';

    let data: ChatMessage;

    switch (config?.questionType?.value) {
      case 'form_output':
        data = {
          content: parsedQuestion.body.innerHTML,
          sender: 'bot',
          format: 'plain',
          timestamp: field.instanceTime,
          id: '',
        };
        break;
      default:
        data = {
          id: '',
          format: 'default',
          sender: 'bot',
          title: parsedQuestion.body.innerHTML,
          content: parsedQuestion.body.innerHTML,
          timestamp: field.instanceTime,
          responseRequest: {
            params,
            type,
            validator:
              getValidatorForQuestion(config as QuestionConfig) ?? undefined,
            placeholder:
              type !== 'buttons'
                ? config.defaultValue?.value?.toString()
                : undefined,
          },
        };
        break;
    }

    return data;
  }

  private calculateQuestionTexts() {
    const dataStore = buildFormTempDataStore(
      this.layout,
      this.parser?.thread?.dataStore
    );
    _.flatten(this.layout).forEach((field) => {
      const wrapped = wrapInJWT(
        parseWithPlaceholders(
          field.question.questionText.value,
          dataStore,
          ' '
        ),
        this.auth.jwtToken$.value
      );
      if (
        wrapped.querySelectorAll('h2').length &&
        !wrapped.querySelectorAll('p').length
      ) {
        field.isHeading = true;
      }
      field.message.title = field.message.content = wrapped.body.innerHTML;
    });
  }

  fieldEntered(field: ExtendedFormEntry, value: any) {
    if (['dropdown', 'check'].includes(field?.question.questionType?.value)) {
      if (_.isArray(value)) {
        value = value.map((r) => r.value ?? r.text);
      }
      if (_.isObject(value) && (value as any)?.text) {
        value = (value as any)?.value ?? (value as any)?.text;
      }
    }
    field.value = value;
    this.emitStateChange();
  }

  async continue() {
    if (!this.isValid) {
      return;
    }
    try {
      this.uploadingFiles = true;
      for (let row = 0; row < this.layout.length; row++) {
        for (let column = 0; column < this.layout[row].length; column++) {
          const field = this.layout[row][column];
          if (
            field.message?.responseRequest?.type === 'file' &&
            field?.value?.length &&
            this.shouldRenderQuestion(row, column)
          ) {
            await this.saveFile(field);
          }
          field.value =
            !this.shouldRenderQuestion(row, column) || _.isNil(field.value)
              ? ''
              : field.value;
        }
      }
      this.uploadingFiles = false;
    } catch (err) {
      this.uploadingFiles = false;
      console.error(err);
      return;
    }
    const aE: any = document.activeElement;
    if (aE?.blur) {
      aE.blur();
    }
    this.answered.emit();
  }

  private async saveFile(field: ExtendedFormEntry) {
    for (let i = 0; i < field.value.length; i++) {
      const fileRef = parseFileReference(field.value[i]);
      if (!fileRef || typeof fileRef === 'string') {
        return;
      }
      if (!fileRef.id.match(/^<<<PENDING::(.*)>>>$/m)) {
        return;
      }
      let file = getPendingFile(fileRef.id)?.file as File;
      if (!(file instanceof File)) {
        // How could this have happened? This shouldn't happen at all 🤔
        throw new Error("Can't upload a generated document using a Form");
      }
      if (!file) {
        const text = parseWithPlaceholders(
          field.question?.questionText?.value,
          buildFormTempDataStore(this.layout, this.parser?.thread?.dataStore)
        );
        this.snackBar.open(
          `Es gab ein Problem beim Upload der Datei: ${text.body?.innerText ?? 'Unbekannt'
          }. Bitte überprüfen Sie die Datei und fügen Sie im Zweifelsfall erneut hinzu.`
        );
        throw new Error('Pending file not found');
      }
      const path =
        field.message.responseRequest?.params?.targetFilePath ?? undefined;
      if (this.auth.loggedIn) {
        await new Promise<void>((resolve, reject) => {
          uploadFile(
            file,
            this.service.stateId,
            {
              path,
              nodeid: this.parser?.visitor?.currentNode?._id,
              threadid: this.parser?.thread.id,
            },
            this.files
          )
            .then((fileIdentifierObject) => {
              const value = toFileReference(
                file,
                fileIdentifierObject.file_identifier,
                path,
                this.parser?.thread.id,
                this.parser?.visitor.currentNode._id
              );
              field.value[i] = value;
              removePendingFile(fileRef.id.match(/<<<PENDING::(.*)>>>/m)?.[1]);
              resolve();
            })
            .catch((error) => {
              if (error.file_identifier) {
                const value = toFileReference(
                  file,
                  error.file_identifier,
                  path,
                  this.parser?.thread.id,
                  this.parser?.visitor.currentNode._id
                );
                field.value = value;
                removePendingFile(
                  fileRef.id.match(/<<<PENDING::(.*)>>>/m)?.[1]
                );
                resolve();
              } else {
                const text = parseWithPlaceholders(
                  field.question?.questionText?.value,
                  buildFormTempDataStore(this.layout, this.parser?.dataStore)
                );
                this.snackBar.open(
                  `Es gab ein Problem beim Upload der Datei: ${text.body?.innerText ?? 'Unbekannt'
                  }. Bitte überprüfen Sie die Datei und fügen Sie im Zweifelsfall erneut hinzu.`
                );
                throw new Error(error);
              }
            });
        });
      } else {
        const value = toFileReference(
          file,
          undefined,
          path,
          this.parser?.thread.id,
          this.parser?.visitor.currentNode._id
        );
        field.value[i] = value;
        removePendingFile(fileRef.id.match(/<<<PENDING::(.*)>>>/m)?.[1]);
      }
    }
  }

  emitStateChange() {
    const intermediateState = _.flatten(this.layout).map((field) => {
      return { ...field, value: _.isNil(field.value) ? '' : field.value };
    });
    this.formStateChange.emit(intermediateState);
  }

  renderQuestionsCache: boolean[][] | undefined = undefined;

  shouldRenderQuestion(row: number, column: number): boolean {
    if (this.renderQuestionsCache?.[row]?.[column] === undefined) {
      this.updateRenderQuestionsCache();
    }
    return this.renderQuestionsCache[row][column];
  }

  updateRenderQuestionsCache() {
    this.renderQuestionsCache = createRenderQuestionsCache(
      this.layout,
      this.previousDataStore ?? this.parser?.thread?.dataStore
    );
  }
}

export function buildFormTempDataStore(
  layout: ExtendedFormEntry[][],
  previousMessage?: DataStore
): DataStore {
  const message = new DataStore();
  message.parseFrom(_.cloneDeep(previousMessage?.toJSON() ?? {}));
  _.flatten(layout).forEach((entry) => {
    message.set(entry.refId, entry.value);
  });
  return message;
}

export function createRenderQuestionsCache(
  layout: ExtendedFormEntry[][],
  dataStore: DataStore
) {
  return layout.map((row) => {
    return row.map((field) => {
      if (!field.condition?.length) {
        return true;
      }
      const message = buildFormTempDataStore(layout, dataStore);
      return field.condition.some(
        (
          conditiongroup: {
            refId: string;
            operator: keyof typeof KNOWN_OPERATORS;
            value: any;
          }[]
        ) => {
          return conditiongroup.every((condition) => {
            if (!condition?.refId?.length) return true;
            return applyComparisonToDataStore(
              condition.refId,
              condition.operator,
              condition.value,
              message,
              []
            );
          });
        }
      );
    });
  });
}
