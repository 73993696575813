<div class="label" *ngIf="label" (click)="onLabelClick()">
  <mat-icon *ngIf="!required">
    {{active ? 'check_box' : 'check_box_outline_blank' }}
  </mat-icon>
  {{ label }}
</div>
<div class="timer-wrapper" *ngIf="required || active">
  <ng-container *ngTemplateOutlet="numberInput; context: {target: _hour, max: 23}"></ng-container>
  <span class="separator">:</span>
  <ng-container *ngTemplateOutlet="numberInput; context: {target: _minute, max: 59}"></ng-container>
</div>


<ng-template #numberInput let-target="target" let-max="max">
  <div class="sec-wrapper">
    <input type="string" [value]="valueFor(target)" (keydown)="onKeyDown($event, target, max)"
      (focus)="onClick($event)" />
    <div class="actions">
      <button (click)="increase(target, max)" tabindex="-1"><mat-icon>arrow_drop_up</mat-icon></button>
      <button (click)="decrease(target)" tabindex="-1"><mat-icon>arrow_drop_down</mat-icon></button>
    </div>
  </div>
</ng-template>