import { Component, Input, OnInit } from '@angular/core';
import { ProcessNode } from 'advoprocess';

@Component({
  selector: 'app-condition-edit',
  templateUrl: './condition-edit.component.html',
  styleUrls: ['./condition-edit.component.scss'],
})
export class ConditionEditComponent implements OnInit {
  @Input() currentCondition: {
    cond: any[];
    elmnt: HTMLElement;
    selection?: any;
    marker?: any;
  } = null;

  @Input() dataSource: ProcessNode[];

  @Input() parent: {
    addCondition: () => void;
    saveCondition: (a: HTMLElement, b: any[]) => void;
    previewById: (a: string, b: string) => void;
    removeCurrentCondition: () => void;
  };

  constructor() {}

  ngOnInit() {
    if (
      this.currentCondition?.elmnt === null &&
      this.currentCondition?.selection
    ) {
      this.parent?.addCondition();
    }
  }
}
