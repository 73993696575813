/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccessPermission { 
    entity?: AccessPermission.EntityEnum;
    full_name?: string;
    id?: string;
    mail?: string;
    read?: boolean;
    write?: boolean;
}
export namespace AccessPermission {
    export type EntityEnum = 'lawyer' | 'client';
    export const EntityEnum = {
        Lawyer: 'lawyer' as EntityEnum,
        Client: 'client' as EntityEnum
    };
}


