import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AdornmentsService } from '../adornments.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[header]',
})
export class HeaderDirective implements OnInit, OnDestroy {
  constructor(
    private adornmentsService: AdornmentsService,
    private el: ElementRef<any>
  ) {}

  ngOnInit(): void {
    this.adornmentsService.setHeaderTo(this.el);
  }

  ngOnDestroy(): void {
    this.adornmentsService.removeHeader(this.el);
  }
}
