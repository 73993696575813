import { AvailableFilter } from 'advoprocess/lib/types/filter';

export const analyticsDataViewFilters: AvailableFilter[] = [
  {
    id: 'analytics_name',
    label: 'lawyer.dashboard.dataViews.table.name',
    internal_name: 'name',
    icon: 'drive_file_rename_outline',
    type: 'string',
  },
  {
    id: 'analytics_created_at',
    label: 'lawyer.dashboard.dataViews.table.createdAt',
    internal_name: 'created_at',
    icon: 'history',
    type: 'date',
  },
  {
    id: 'analytics_id',
    label: 'lawyer.dashboard.dataViews.table.id',
    internal_name: 'id',
    icon: 'tag',
    type: 'string',
  },
];
