/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Template { 
    createdAt?: string;
    createdBy?: string;
    id?: string;
    is_folder?: boolean;
    name?: string;
    nodeType?: string;
    parent?: string;
    type?: string;
}

