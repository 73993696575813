export * from './addOns.service';
import { AddOnsService } from './addOns.service';
export * from './analytics.service';
import { AnalyticsService } from './analytics.service';
export * from './assets.service';
import { AssetsService } from './assets.service';
export * from './clients.service';
import { ClientsService } from './clients.service';
export * from './corporateIdentity.service';
import { CorporateIdentityService } from './corporateIdentity.service';
export * from './enhancedFeatures.service';
import { EnhancedFeaturesService } from './enhancedFeatures.service';
export * from './events.service';
import { EventsService } from './events.service';
export * from './execution.service';
import { ExecutionService } from './execution.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './httpstat.service';
import { HttpstatService } from './httpstat.service';
export * from './internal.service';
import { InternalService } from './internal.service';
export * from './lawyer.service';
import { LawyerService } from './lawyer.service';
export * from './legalProcess.service';
import { LegalProcessService } from './legalProcess.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './permissions.service';
import { PermissionsService } from './permissions.service';
export * from './public.service';
import { PublicService } from './public.service';
export * from './roles.service';
import { RolesService } from './roles.service';
export * from './securityGuest.service';
import { SecurityGuestService } from './securityGuest.service';
export * from './settings.service';
import { SettingsService } from './settings.service';
export * from './templates.service';
import { TemplatesService } from './templates.service';
export * from './tenants.service';
import { TenantsService } from './tenants.service';
export const APIS = [AddOnsService, AnalyticsService, AssetsService, ClientsService, CorporateIdentityService, EnhancedFeaturesService, EventsService, ExecutionService, FilesService, HttpstatService, InternalService, LawyerService, LegalProcessService, NotificationService, PermissionsService, PublicService, RolesService, SecurityGuestService, SettingsService, TemplatesService, TenantsService];
