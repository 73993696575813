<h2 id="page-title" [attr.aria-label]="config?.title ?? ('aria.label.noTitle' | translate)" >{{ config?.title | translate }}</h2>
<div class="filter-pagination" *ngIf="inited" aria-labelledby="page-title">
  <div class="selection-actions" *ngIf="config?.selectionActionBtn && config.selection?.selected?.length > 0">
    <button mat-raised-button
            [color]="config.selectionActionBtn.color"
            (click)="config.selectionActionBtn.onClick()"
            [attr.aria-label]="config.selectionActionBtn.text | translate">
      <mat-icon aria-hidden="true">{{ config.selectionActionBtn.icon }}</mat-icon>
      {{ config.selectionActionBtn.text | translate }}
    </button>
  </div>
  <div class="top-part">
    <div class="search-container" [attr.display-mode]="config?.searchBarDisplay ?? 'FULL'">
      <div class="search-area" *ngIf="availableFilters?.length">
        <app-search-menu [entries]="queryFilters.bind(this)" (entrySelected)="selectFilter($event)"
          *ngIf="config?.searchBarDisplay !== 'SIMPLE'">
          <button mat-button class="filterTypeSelector" [attr.aria-label]="'aria.label.filterTypeSelector' | translate">
            <mat-icon *ngIf="searchForm.get('type')?.value?.icon" aria-hidden="true">{{
              searchForm.get("type")?.value?.icon
              }}</mat-icon><span>{{
              getNameLabelAndGroupFor(searchForm.get("type")?.value)[0]
              | translate
              }}</span><mat-icon aria-hidden="true">arrow_drop_down</mat-icon>
          </button>
        </app-search-menu>
        <ng-container *ngIf="!searchForm.get('type')?.value?.options?.length">
          <input [formControl]="searchForm.get('query')" (keydown.enter)="search()" [attr.aria-label]="'aria.label.searchQuery' | translate"
            placeholder="{{ 'common.label.inputSearchTerm' | translate }}"
            (blur)="config?.searchBarDisplay === 'SIMPLE' ? search() : null"
            (keyup)="config?.autoSearch ? search() : null" #searchField
            *ngIf="searchForm.get('type')?.value?.type !== 'date'" type="search" />
          <span class="date-selector" [dueAtSelector]="true" (dueAtChanged)="dueAtChanged($event)" [useTime]="true"
            [initialDate]="searchForm.get('query')?.value" [allowEndTime]="false"
            *ngIf="searchForm.get('type')?.value?.type === 'date'" [attr.aria-label]="'aria.label.dateSelector' | translate">
            {{ searchForm.get('query')?.value ? (searchForm.get('query')?.value | date: 'dd.MM.YYYY HH:mm') :
            '----.--.-- --:--' }}
          </span>
        </ng-container>
        <ng-container *ngIf="searchForm.get('type')?.value?.options?.length">
          <mat-select [formControl]="searchForm.get('query')" [attr.aria-label]="'aria.label.filterOptions' | translate">
            <mat-option *ngFor="let option of searchForm.get('type')?.value?.options" [value]="option.value">
              {{ option.label | translate }}
            </mat-option>
          </mat-select>
        </ng-container>
        <button mat-icon-button (click)="search()" [disabled]="
            isNil(searchForm.get('query')?.value) ||
            (isString(searchForm.get('query')?.value) &&
              !searchForm.get('query')?.value.length)
          " [attr.aria-label]="'aria.label.executeSearch' | translate">
          <mat-icon aria-hidden="true">search</mat-icon>
        </button>
      </div>
    </div>
    <div class="pagination-actions">
      <div class="pagination" *ngIf="!config?.hidePagination" [attr.aria-label]="'aria.label.paginationControls' | translate">
        <app-table-paginator [pagination]="viewConfig.pagination" (paginate)="onPaginate()"
          [totalElements]="totalElements" [disablePageSizeSelect]="config?.disablePageSizeSelect"></app-table-paginator>
      </div>
    </div>
  </div>
  <div class="search-breadcrumbs" *ngIf="config?.searchBarDisplay !== 'NONE' && config?.searchBarDisplay !== 'SIMPLE'">
    <app-search-breadcrumbs [filters]="filters" (filtersChanged)="filtersChanged($event)"
      [availableFilters]="availableFilters" [configId]="config.id"></app-search-breadcrumbs>
  </div>
</div>

<!-- Actual table -->
<div class="table-container" *ngIf="inited" role="region">
  <table mat-table [dataSource]="dataSource" *ngIf="viewConfig && dataSource" cdkDropList cdkDropListLockAxis="x"
    cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropListDropped($event)">

    <!--  Select column, appears only if the selection input is not null  -->
    <ng-container matColumnDef="select">
      <!-- Header Checkbox/ select all (desktop only) -->
      <th mat-header-cell *matHeaderCellDef class="select-th">
        <mat-checkbox
          (change)="masterToggle()"
          [checked]="isAllSelected()"
          [indeterminate]="config.selection?.hasValue() && !isAllSelected()"
          [aria-label]="'aria.label.selectAll' | translate">
        </mat-checkbox>
      </th>

      <td mat-cell class="select-td" *matCellDef="let row" (click)="$event.stopPropagation()"
          (touchend)="$event.stopPropagation()">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="config.selection?.toggle(row)"
          [checked]="config.selection?.isSelected(row)"
          [aria-label]="'aria.label.selectRow' | translate">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="column.internal_name" *ngFor="
        let column of allColumns;
        let colIndex = index
      ">
      <ng-container
        *ngIf="column.internal_name !== '_actions(INTERNAL)' && column.internal_name !== '_notifications(INTERNAL)'">
        <th mat-header-cell *matHeaderCellDef cdkDrag class="sf-table-header" role="columnheader"
          [cdkDragDisabled]="column?.fixed || config?.disableColumnChange" aria-sort="none">
          <button mat-icon-button class="sort-indicator" [class.active]="
              viewConfig?.view?.sort_by?.by === column.internal_name
            " (click)="sortBy(column)" [matTooltip]="'common.label.sort' | translate" [attr.aria-label]="('aria.label.sortBy' | translate) + ' ' + (column.display_name | translate)">
            <mat-icon [@fadeAnimation] *ngIf="viewConfig?.view?.sort_by?.by !== column.internal_name" aria-hidden="true">sort</mat-icon>
            <mat-icon [@fadeAnimation] *ngIf="viewConfig?.view?.sort_by?.by === column.internal_name && viewConfig?.view?.sort_by?.direction === 'desc'"
              aria-hidden="true">arrow_upward</mat-icon>
            <mat-icon [@fadeAnimation] *ngIf="viewConfig?.view?.sort_by?.by === column.internal_name && viewConfig?.view?.sort_by?.direction !== 'desc'"
              aria-hidden="true">arrow_downward</mat-icon>
          </button>

          <!-- Column labels (header) -->
          <span *ngIf="!column?.hideLabel">{{
            column.display_name | translate
            }}</span>

          <div class="th-actions">
            <button mat-icon-button (click)="removeColumn(column)"
              *ngIf="columnNames?.length > 1 && !column?.fixed && !config?.disableColumnChange" [attr.aria-label]="('aria.label.removeColumn' | translate) + ' ' + column.display_name | translate">
              <mat-icon aria-hidden="true">close</mat-icon>
            </button>
          </div>
        </th>
        <ng-container *matCellDef="let element">

          <!-- Cell outlet for mobile -->
          <td mat-cell class="mobile" [attr.colspan]="columnNames.length" role="cell" (click)="handleClick(element, column)"
            (dblclick)="handleDblClick(element, column)" [class.clickable]="config.onRowClick || config.onRowDblClick"
            *ngIf="config?.enableNotificationBubbles ? colIndex === 1 : colIndex === 0" tabindex="0">
            <ng-container *ngTemplateOutlet="mobileCellContent; context: { element: element }"></ng-container>
          </td>

          <!-- Cell outlet for desktop -->
          <td mat-cell (click)="handleClick(element, column)" (dblclick)="handleDblClick(element, column)"
            [class.clickable]="config.onRowClick || config.onRowDblClick" role="cell" tabindex="0"
            [style.width]="column?.width ? column.width : null" [attr.colindex]="colIndex"
            [class.last]="colIndex === columnNames.length - 1">
            <ng-container *ngTemplateOutlet="cellContent; context: {element: element, column: column}"></ng-container>
        </ng-container>
      </ng-container>

      <!-- Notification bubble for desktop -->
      <ng-container *ngIf="column.internal_name === '_notifications(INTERNAL)'">
        <th mat-header-cell *matHeaderCellDef role="columnheader" style="width: 20px;" [attr.aria-label]="'aria.label.notifications' | translate"></th>
        <td mat-cell *matCellDef="let element" class="notifications" role="cell" tabindex="0"
          [class.has-notification]="elementHasNotification(element)" [class.last]="colIndex === columnNames.length - 1"
          [attr.colindex]="colIndex">
          <div class="notification-bubble" *ngIf="elementHasNotification(element)"
            [matTooltip]="config.notificationTooltip?.(element, column) | translate" [attr.aria-label]="('aria.label.notifications' | translate) + ': ' + element.notifications">
            <ng-container *ngIf="!config.notificationIcon">{{ element.notifications }}</ng-container>
            <mat-icon *ngIf="!!config.notificationIcon" aria-hidden="true">{{ config.notificationIcon }}</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="column.internal_name === '_actions(INTERNAL)'">
        <th mat-header-cell *matHeaderCellDef role="columnheader" [attr.aria-label]="'aria.label.actions' | translate"></th>
        <td mat-cell *matCellDef="let element" class="actions" role="cell" tabindex="0" [class.last]="colIndex === columnNames.length - 1"
          [attr.colindex]="colIndex">
          <div class="actions">
            <ng-container *ngFor="let action of config.actions">
              <button mat-icon-button [disabled]="action.disableIf && action.disableIf(element)"
                (click)="$event.stopPropagation(); action.handler(element, dataSourceObj)"
                (keyup.enter)="$event.stopPropagation()" *ngIf="!action?.hideIf || !action.hideIf(element)" [matTooltip]="action.name | translate" [attr.aria-label]="action.name | translate">
                <mat-icon aria-hidden="true">{{ isFunction(action.icon) ? action.icon(element) : action.icon }}</mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </ng-container>
    </ng-container>
    <ng-container matColumnDef="groupHeader">
      <td mat-cell *matCellDef="let group" [attr.colspan]="columnNames.length" class="group-header" role="cell" tabindex="0">
        <div class="group-header-content" [attr.aria-label]="('aria.label.group' | translate) + ' ' + group.groupHeader">
          <span>{{ group.groupHeader }}</span>
          <button mat-icon-button [attr.aria-label]="'aria.label.toggleGroup' | translate">
            <mat-icon *ngIf="group.closed">expand_less</mat-icon>
            <mat-icon *ngIf="!group.closed">expand_more</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnNames" role="row"></tr>
    <tr [class.clickable]="config.onRowClick || config.onRowDblClick" [class.selected]="selectedRow === row" (click)="
        !config.onRowClick && config.onRowDblClick ? (selectedRow = row) : null
        " mat-row *matRowDef="let row; columns: columnNames" [class.hidden]="isGroupClosed(row)"
      [attr.tabindex]="config.onRowClick || config.onRowDblClick ? '0' : none" role="row"
      (keyup.enter)="config.onRowClick ? handleClick(row) : config.onRowDblClick ? handleDblClick(row) : null"
      (touchend)="handleTouchEnd($event, row)" (touchstart)="handleTouchStart($event, row)"
      (blur)="handleRowBlur($event, row)" (contextmenu)="$event.preventDefault(); $event.stopPropagation(); $event.stopImmediatePropagation()"
      [attr.draggable]="isDraggable(row) ? 'true' : null" (dragstart)="dragStart($event, row)"
      (dragover)="dragOver($event)" (dragend)="dragEnd($event)" (drop)="drop($event, row)"
      [class.dropzone]="isDropZone(row) && dragging"></tr>
    <tr (click)="row.closed = !row.closed" (keyup.enter)="row.closed = !row.closed" mat-row class="group-header-row"
      *matRowDef="let row; columns: ['groupHeader']; when: isGroup" tabindex="0" role="row"></tr>
  </table>
  <app-search-menu [entries]="queryColumns.bind(this)" (entrySelected)="addColumn($event)" class="new-col-menu">
    <button mat-icon-button class="new-col-button" [matTooltip]="'common.button.addColumn' | translate"
      *ngIf="availableColumns.length && !config?.disableColumnChange" [attr.aria-label]="'aria.label.addColumn' | translate">
      <mat-icon aria-hidden="true">add</mat-icon>
    </button>
  </app-search-menu>
  <div class="no-content" *ngIf="!dataSource?.data?.length" tabindex="0">
    <mat-icon aria-hidden="true">manage_search</mat-icon>
    <span>{{ "common.label.noContent" | translate }}</span>
  </div>
</div>

<div class="loading-overlay" *ngIf="loading" aria-live="polite">
  <mat-spinner [diameter]="40" [attr.aria-label]="'aria.label.loading' | translate"></mat-spinner>
</div>

<!-- Row for desktop -->
<ng-template #cellContent let-column="column" let-element="element">
  <span class="cell-content">
    <mat-icon *ngIf="
    column.icon &&
    column.icon(element) !== null &&
    !column.icon(element)?.initials
  " aria-hidden="true">{{ column.icon(element) }}</mat-icon>
    <span class="initials" *ngIf="
    column.icon &&
    column.icon(element) !== null &&
    column.icon(element)?.initials
  " [matTooltip]="column.icon(element).tooltip" [attr.aria-label]="column.icon(element)?.tooltip">
      {{ column.icon(element).initials }}
    </span>
    <span *ngIf="!column.hideContent" class="content-content">
      <span class="no-content" tabindex="0" *ngIf="cellHasNoContent(element, column)">{{
        'common.label.empty' | translate }}</span>
      <app-read-receipt *ngIf="config.readReceipt && config.readReceipt.column === column.internal_name && config.readReceipt.getStatus(element) !== null"
        [status]="config.readReceipt.getStatus(element)">
      </app-read-receipt>

      {{ element[column.display_name] | handleTableCell }}
    </span></span>
</ng-template>

<!-- Row for mobile -->
<ng-template #mobileCellContent let-element="element">
  <!-- Column iterator -->
  <div class="mobile-cell-content" [class.settingsOpen]="focusedSettings === element">
    <ng-container *ngFor="let column of allColumns; let colIndex = index">
      <div class="mobile-column" *ngIf="!config?.enableNotificationBubbles || colIndex !== 0"
        [class.first-column]="colIndex === 0">

        <!-- Content column -->
        <ng-container
          *ngIf="column.internal_name !== '_actions(INTERNAL)' && column.internal_name !== '_notifications(INTERNAL)'">
          <!-- First column (printed boldly) -->
          <ng-container *ngIf="config?.enableNotificationBubbles ? colIndex === 1 : colIndex === 0">
            <span class="main-element-title">
              <ng-container *ngTemplateOutlet="cellContent; context: {element: element, column: column}"></ng-container>
            </span>

            <!-- Notification bubble for mobile -->
            <div class="notification-bubble" *ngIf="elementHasNotification(element) && config.enableNotificationBubbles"
              [matTooltip]="config.notificationTooltip?.(element, column) | translate" [attr.aria-label]="('aria.label.notifications' | translate) + ': ' + element.notifications">
              <mat-icon *ngIf="!!config.notificationIcon" aria-hidden="true">{{ config.notificationIcon }}</mat-icon>
              <ng-container *ngIf="!config.notificationIcon">{{ element.notifications }}</ng-container>
            </div>
          </ng-container>

          <!-- All the other columns -->
          <ng-container *ngIf="colIndex > (config?.enableNotificationBubbles ? 1 : 0)">
            <span class="mobile-column-label" *ngIf="!column?.hideLabel">{{
              column.display_name | translate }}:</span>
            <span class="mobile-cell-value">
              <ng-container *ngTemplateOutlet="cellContent; context: {element: element, column: column}"></ng-container>
            </span>
          </ng-container>
        </ng-container>

        <!-- Row actions for mobile -->
        <ng-container *ngIf="column.internal_name === '_actions(INTERNAL)'">
          <div class="mobile-actions-bar" (touchstart)="$event.stopPropagation(); $event.stopPropagation()"
            (touchend)="$event.stopPropagation(); $event.stopPropagation()">

            <ng-container *ngFor="let action of config.actions">
              <button mat-icon-button [disabled]="action.disableIf && action.disableIf(element)"
                (click)="action.handler(element, dataSourceObj)" *ngIf="!action?.hideIf || !action.hideIf(element)"
                [matTooltip]="action.name | translate" [attr.aria-label]="action.name | translate">
                <mat-icon aria-hidden="true">{{ isFunction(action.icon) ? action.icon(element) : action.icon }}</mat-icon>
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
