<div class="attachments-list" *ngIf="config?.value?.length">
  <div class="attachment" *ngFor="let attachment of config.value; let attachIdx = index"
    (click)="previewAttachment(attachment)">
    <div class="icon" [style.color]="iconFor({ mime: attachment.type }).color">
      <mat-icon>{{ iconFor({ mime: attachment.type }).icon }}</mat-icon>
    </div>
    <span title="{{attachment.name}}">{{ attachment.name }}</span>
    <ng-container *ngIf="attachment.type === 'generated-document'">
      <button mat-icon-button [matMenuTriggerFor]="pdfExportModeMenu"
        [matTooltip]="'common.button.pdfMode' | translate">
        <mat-icon *ngIf="attachment.format === 'default'">picture_as_pdf</mat-icon>
        <img *ngIf="attachment.format === 'DATEV'" src="/assets/DATEV-Logo.png" class="adv-export-logo-icon" />
        <img *ngIf="attachment.format === 'Advolux'" src="/assets/Advolux-Logo.png" class="adv-export-logo-icon" />
      </button>
      <mat-menu #pdfExportModeMenu="matMenu">
        <button mat-menu-item (click)="pdfMode(i, 'default')">
          <mat-icon>picture_as_pdf</mat-icon><span [innerHTML]="'document.modeDefaultPDF' | translate"></span>
        </button>
        <button mat-menu-item (click)="pdfMode(i, 'DATEV')">
          <img src="/assets/DATEV-Logo.png" class="adv-export-logo-icon" />
          {{ "document.modeDATEV" | translate }}
        </button>
        <button mat-menu-item (click)="pdfMode(i, 'Advolux')">
          <img src="/assets/Advolux-Logo.png" class="adv-export-logo-icon" />
          {{ "document.modeAdvolux" | translate }}
        </button>
      </mat-menu>
    </ng-container>
    <div class="delete" (click)="removeAttachment(attachIdx)">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>