/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BooleanOperator } from './booleanOperator';
import { FilterCriterium } from './filterCriterium';


export interface DataViewTableColumnDefinition { 
    cell_content?: string;
    column_filter?: Array<FilterCriterium | BooleanOperator>;
    column_type?: DataViewTableColumnDefinition.ColumnTypeEnum;
    display_name?: string;
    _function?: DataViewTableColumnDefinition.FunctionEnum;
    hide?: boolean | null;
    internal_name?: string;
    questionConfig?: any | null;
    width?: number | null;
}
export namespace DataViewTableColumnDefinition {
    export type ColumnTypeEnum = 'PLAIN' | 'CALCULATED' | 'AGGREGATION';
    export const ColumnTypeEnum = {
        Plain: 'PLAIN' as ColumnTypeEnum,
        Calculated: 'CALCULATED' as ColumnTypeEnum,
        Aggregation: 'AGGREGATION' as ColumnTypeEnum
    };
    export type FunctionEnum = 'COUNT' | 'PERCENTAGE' | 'SUM' | 'MIN' | 'MAX' | 'AVG' | 'VARIABLE' | 'MATH';
    export const FunctionEnum = {
        Count: 'COUNT' as FunctionEnum,
        Percentage: 'PERCENTAGE' as FunctionEnum,
        Sum: 'SUM' as FunctionEnum,
        Min: 'MIN' as FunctionEnum,
        Max: 'MAX' as FunctionEnum,
        Avg: 'AVG' as FunctionEnum,
        Variable: 'VARIABLE' as FunctionEnum,
        Math: 'MATH' as FunctionEnum
    };
}


