import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { iconFor } from 'src/app/views/process/process-files/helpers';
import {
  FileOverlayDialogComponent,
  FileOverlayDialogConfig,
} from '../../views/document/file-overlay-dialog/file-overlay-dialog.component';
import { ProcessService } from 'src/app/views/process/process.service';

@Component({
  selector: 'app-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss'],
})
export class AttachmentListComponent {
  @Input() config: { value: any[] } = { value: [] };

  @Input() service?: ProcessService;

  @Output() attachmentRemoved = new EventEmitter<any>();

  constructor(private matDialog: MatDialog) { }

  iconFor(file: { mime?: string }) {
    return iconFor(file);
  }

  previewAttachment(ref: {
    name: string;
    id: string;
    type: string;
    size?: number;
  }) {
    this.matDialog.open<FileOverlayDialogComponent, FileOverlayDialogConfig>(
      FileOverlayDialogComponent,
      {
        panelClass: 'slide-in-dialog',
        position: {
          right: '0',
        },
        data: {
          file: {
            ...ref,
            size: ref.size.toString(),
            uuid: ref.id,
          },
          service: this.service,
        },
      }
    );
  }

  removeAttachment(index: number) {
    const value = this.config?.value?.[index];
    this.config?.value.splice(index, 1);
    this.attachmentRemoved.emit(value);
  }
}
