/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ThreadRequest { 
    assigned?: Array<string>;
    chat?: Array<object>;
    currentNode?: string | null;
    dataStore?: Array<object>;
    id?: string;
    jwt?: string | null;
    lastNode?: string | null;
    lastTargetOutput?: string | null;
    meta?: object;
    methodKey?: string | null;
    methodParams?: any | null;
    name?: string;
    nodeTemplate?: Array<object>;
    status?: ThreadRequest.StatusEnum;
    widgets?: Array<object>;
}
export namespace ThreadRequest {
    export type StatusEnum = 'OPEN' | 'COMPLETED' | 'CLOSED';
    export const StatusEnum = {
        Open: 'OPEN' as StatusEnum,
        Completed: 'COMPLETED' as StatusEnum,
        Closed: 'CLOSED' as StatusEnum
    };
}


