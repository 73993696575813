import { BooleanOperator, FilterCriterium, Permission } from 'src/api';
import { buildErrorString, isBooleanOperator } from '../common/helpers';
import { AuthService } from './auth.service';
import * as _ from 'lodash';
import { DialogService } from '../widgets/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { AvailableFilter } from 'advoprocess/lib/types/filter';

export const FILTER_REGEX =
  /^([^\(]+)(?:\(([^\)]+)\s+([^\)]+)\s+([^\)]+)\))?$/m;

export function isPermission(p: Permission | any): p is Permission {
  return !!(p as any).policies && !!(p as any).name;
}

export function splitRelevantErrors(
  errors: (FilterCriterium | BooleanOperator)[],
  targetPaths: string[]
): {
  relevant: (FilterCriterium | BooleanOperator)[];
  other: (FilterCriterium | BooleanOperator)[];
} {
  let relevant: (FilterCriterium | BooleanOperator)[] = [];
  let other: (FilterCriterium | BooleanOperator)[] = [];
  for (const err of errors) {
    if (isBooleanOperator(err)) {
      const subRelevant = splitRelevantErrors(err.filters, targetPaths);
      if (subRelevant.relevant.length) {
        if (subRelevant.relevant.length === 1) {
          relevant.push(subRelevant.relevant[0]);
        } else {
          relevant.push({
            operator: err.operator,
            filters: subRelevant.relevant,
          });
        }
      }
      if (subRelevant.other.length) {
        if (subRelevant.other.length === 1) {
          other.push(subRelevant.other[0]);
        } else {
          other.push({
            operator: err.operator,
            filters: subRelevant.other,
          });
        }
      }
    } else {
      if (targetPaths.includes(err.operand)) {
        relevant.push(err);
      } else {
        other.push(err);
      }
    }
  }
  // Deduplicate entries
  relevant = relevant.filter(
    (r, i) => !relevant.some((r2, j) => j > i && _.isEqual(r, r2))
  );
  other = other.filter(
    (r, i) => !other.some((r2, j) => j > i && _.isEqual(r, r2))
  );
  return { relevant, other };
}

export function getModelField(
  instance: any,
  path: string,
  auth: AuthService
): any {
  if (path.length <= 0) return;
  let current: any = instance;
  const splitPath: string[] = path.split('.');
  let i = 0;
  for (let pathPartRaw of splitPath) {
    const withFilter = pathPartRaw.match(FILTER_REGEX);
    const pathPart = withFilter[1];

    if (_.isArray(current) && isNaN(pathPart as any)) {
      const mapped = current.map((el) => {
        return getModelField(el, splitPath.slice(i).join('.'), auth);
      });
      if (mapped.length === 1) {
        return mapped[0];
      }
      return mapped;
    } else {
      try {
        current = current[pathPart];
      } catch {
        return undefined;
      }
    }
    if (_.isArray(current) && !!withFilter[2]) {
      const operand = withFilter[2].replace(/::/gm, '.');
      const operator = withFilter[3];
      if (
        Object.values(FilterCriterium.OperatorEnum).includes(
          operator as FilterCriterium.OperatorEnum
        )
      ) {
        const value = withFilter[4];
        current = current.filter((el) => {
          const compareVal = getModelField(el, operand, auth);
          return applyFilterCondition(
            {
              operand,
              operator: operator as FilterCriterium.OperatorEnum,
              value,
            },
            compareVal,
            auth
          );
        });
      }
    }
    i++;
  }
  return current;
}

export async function setModelField(
  instance: { [key: string]: any },
  path: string,
  value: any,
  auth: AuthService,
  onDecisionNeeded: (
    options: (FilterCriterium | BooleanOperator)[]
  ) => Promise<number>
) {
  if (path.length <= 0) return;
  let lastCurrent = null;
  let pathPart = null;
  let current: any = instance;
  const splitPath: string[] = path.split('.');
  let i = 0;
  for (let pathPartRaw of splitPath) {
    const withFilter = pathPartRaw.match(FILTER_REGEX);
    pathPart = withFilter[1];

    if (_.isArray(current) && isNaN(pathPart as any)) {
      if (!current.length) {
        current.push({});
      }
      for (const el of current) {
        await setModelField(
          el,
          splitPath.slice(i).join('.'),
          value,
          auth,
          onDecisionNeeded
        );
      }
      return;
    } else {
      lastCurrent = current;
      try {
        current = lastCurrent[pathPart];
      } catch {
        current = undefined;
      }
      if (current == undefined) {
        if (withFilter[2] || !isNaN(splitPath[i + 1] ?? ('' as any))) {
          lastCurrent[pathPart] = [];
        } else {
          lastCurrent[pathPart] = {};
        }
        current = lastCurrent[pathPart];
      }
    }
    if (_.isArray(current) && !!withFilter[2]) {
      const operand = withFilter[2].replace(/::/gm, '.');
      const operator = withFilter[3];
      if (
        Object.values(FilterCriterium.OperatorEnum).includes(
          operator as FilterCriterium.OperatorEnum
        )
      ) {
        const value = withFilter[4];
        const f = {
          operand,
          operator: operator as FilterCriterium.OperatorEnum,
          value,
        };
        const found = current.filter((el) => {
          const compareVal = getModelField(el, operand, auth);
          return applyFilterCondition(f, compareVal, auth);
        });
        if (!found?.length) {
          const newEntry = {};
          await applyFiltersToModel([f], newEntry, auth, onDecisionNeeded);
          current.push(newEntry);
        } else {
          current = found;
        }
        lastCurrent = null;
      }
    }
    i++;
  }
  if (current && lastCurrent && pathPart) {
    lastCurrent[pathPart] = value;
  }
  return current;
}

export async function applyFiltersToModel(
  requirements: (FilterCriterium | BooleanOperator)[],
  model: { [key: string]: any },
  auth: AuthService,
  onDecisionNeeded: (
    options: (FilterCriterium | BooleanOperator)[]
  ) => Promise<number>
): Promise<void> {
  for (const req of requirements) {
    if (isBooleanOperator(req)) {
      switch (req.operator) {
        case 'and':
          await applyFiltersToModel(req.filters, model, auth, onDecisionNeeded);
        case 'or':
          // Ask the user what they want
          const targetIndex = await onDecisionNeeded(req.filters);
          await applyFiltersToModel(
            [req.filters[targetIndex]],
            model,
            auth,
            onDecisionNeeded
          );
      }
    } else {
      switch (req.operator) {
        case 'eq':
          await setModelField(
            model,
            req.operand,
            preprocessFilterValue(req.value, auth),
            auth,
            onDecisionNeeded
          );
      }
    }
  }
}

function preprocessFilterValue(filter: any, auth: AuthService): any {
  if (filter === '{{user_id}}') {
    return auth.userId;
  }
  if (filter === '{{role_ids}}') {
    return []; // ToDo
  }
  return filter;
}

export function applyFilterCondition(
  filter: FilterCriterium,
  compareVal: any,
  auth: AuthService
): boolean {
  if (typeof compareVal === 'string') {
    compareVal = compareVal.toLowerCase();
  }
  let srcVal = preprocessFilterValue(filter?.value, auth);
  if (typeof srcVal === 'string') {
    srcVal = srcVal.toLowerCase();
  }
  if (
    (filter.operator === 'eq' && srcVal !== compareVal) ||
    (filter.operator === 'ne' && srcVal === compareVal) ||
    (filter.operator === 'contains' && !compareVal?.includes(srcVal)) ||
    (filter.operator === 'containsNot' && compareVal?.includes(srcVal)) ||
    (filter.operator === 'startsWith' &&
      (typeof compareVal !== 'string' ||
        typeof srcVal !== 'string' ||
        !compareVal?.startsWith(srcVal))) ||
    (filter.operator === 'includedIn' &&
      (!_.isArray(srcVal) ||
        !srcVal
          .map((v) => (typeof v === 'string' ? v.toLowerCase() : v))
          .includes(compareVal))) ||
    (filter.operator === 'notIncludedIn' &&
      (!_.isArray(srcVal) ||
        srcVal
          .map((v) => (typeof v === 'string' ? v.toLowerCase() : v))
          .includes(compareVal)))
  ) {
    return false;
  }
  return true;
}

export async function userDecisionHandler(
  options: (FilterCriterium | BooleanOperator)[],
  dialog: DialogService,
  translator: TranslateService,
  knownFilters: AvailableFilter[]
): Promise<number> {
  // ToDo: Temporary for ADVD launch, fix later
  return Promise.resolve(0);
  return dialog.confirm({
    text: 'common.message.decideText',
    title: 'common.message.decideTitle',
    width: 'unset',
    buttons: options.map((v, i) => ({
      text: buildErrorString(translator, v, knownFilters),
      value: i,
      raised: true,
      accept: true,
    })),
  });
}
