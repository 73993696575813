<button mat-button class="file-preview" *ngFor="let file of files; let i = index"
  (click)="openDocumentForSigning(file, i)" [class.done]="!!finishedDocuments[i]"
        [attr.aria-label]="(finishedDocuments[i] ? 'common.tooltip.clickToView' : 'common.tooltip.clickToSign') | translate"
        [attr.aria-pressed]="!!finishedDocuments[i]">
  <mat-icon *ngIf="!finishedDocuments[i]" aria-hidden="true">description</mat-icon>
  <mat-icon *ngIf="!!finishedDocuments[i]" aria-hidden="true">check_circle</mat-icon>
  <div>
    <span class="name">{{file.fileName}}</span>
    <span class="description" aria-hidden="true">{{ (!!finishedDocuments[i] ? 'common.tooltip.clickToView' : 'common.tooltip.clickToSign')
      | translate }}</span>
  </div>
</button>
