/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A default user for the tenant
 */
export interface TenantUser { 
    api_access?: boolean;
    /**
     * Email address of the user
     */
    email?: string;
    /**
     * First name of the user
     */
    first_name?: string;
    is_technical?: boolean;
    /**
     * Last name of the user
     */
    last_name?: string;
    /**
     * Whether the user should receive email notifications
     */
    mail_notification?: boolean;
    /**
     * Password for the user, to be changed on first login
     */
    password?: string;
}

