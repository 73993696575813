import {
  Component,
  Output,
  EventEmitter,
  Input,
  HostBinding,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SignOnPDFComponent } from './sign-on-pdf/sign-on-pdf.component';
import { TranslateService } from '@ngx-translate/core';
import { ProcessService } from '../../../process.service';
import {
  FileOrGeneratedDocument,
  GeneratedDocument,
  ProcessParser,
  toFileReference,
  uploadFile,
} from 'advoprocess';
import { FilesService } from 'src/api';
import {
  FileOverlayDialogComponent,
  FileOverlayDialogConfig,
} from 'src/app/views/document/file-overlay-dialog/file-overlay-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-signature-input',
  templateUrl: './signature-input.component.html',
  styleUrls: ['./signature-input.component.scss'],
})
export class SignatureInputComponent {
  @Input() data: string;
  @Input() message: any;
  @Input() parser: ProcessParser;

  @Output() finished = new EventEmitter<string[]>();

  files: any[];

  @HostBinding('class.data')
  get _() {
    return !!this.data;
  }

  parsedDocument: { value: string; options?: any };

  finishedDocuments: (FileOrGeneratedDocument | undefined)[] = [];

  constructor(
    private matDialog: MatDialog,
    private translator: TranslateService,
    private service: ProcessService,
    private filesService: FilesService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.files = this.message.responseRequest.params.files;
    if (!this.files) {
      this.finished.emit([]);
    }
  }

  openDocumentForSigning(file, index: number): void {
    if (!!this.finishedDocuments[index]) {
      const finishedFile = this.finishedDocuments[index] as File;
      this.matDialog.open<FileOverlayDialogComponent, FileOverlayDialogConfig>(
        FileOverlayDialogComponent,
        {
          panelClass: 'slide-in-dialog',
          position: {
            right: '0',
          },
          data: {
            file: {
              can_write: false,
              mime: 'application/pdf',
              name: finishedFile.name,
              size: finishedFile.size.toString(),
              url: window.URL.createObjectURL(finishedFile),
            },
            service: this.service,
          },
        }
      );
      return;
    } else {
      const dialogRef = this.matDialog.open(SignOnPDFComponent, {
        width: '100%',
        panelClass: 'sign-on-pdf-dialog-panel',
        data: {
          file,
          parser: this.parser,
        },
      });
      dialogRef.afterClosed().subscribe((result: File | null | undefined) => {
        if (result === null || result === undefined) {
          return;
        }
        this.finishedDocuments[index] = result;
        this.checkFinishedAll();
      });
    }
  }

  private checkFinishedAll() {
    for (let i = 0; i < this.files.length; i++) {
      if (!this.finishedDocuments[i]) return;
    }
    this.uploadAllDocuments().then((fileRefs) => {
      this.finished.emit(fileRefs);
    });
  }

  private async uploadAllDocuments(): Promise<string[]> {
    const fileRefs: string[] = [];
    for (let file of this.finishedDocuments) {
      const originalFile = this.files[this.finishedDocuments.indexOf(file)];
      // Upload the signed PDF to the server
      let fileRef: string;
      if (this.auth.loggedIn) {
        await uploadFile(
          file as File,
          this.service.stateId,
          {
            path: isUUID(originalFile.fileFolder)
              ? null
              : originalFile.fileFolder,
            folder: isUUID(originalFile.fileFolder)
              ? originalFile.fileFolder
              : null,
            threadid: this.parser?.thread.id,
            nodeid: this.parser?.visitor.currentNode._id,
          },
          this.filesService,
          [],
          false
        )
          .then((fileIdentifierObject) => {
            file = file as File;
            fileRef = toFileReference(
              {
                name: file.name,
                size: file.size,
                type: 'application/pdf',
              } as File,
              fileIdentifierObject.file_identifier,
              originalFile.fileFolder,
              this.parser?.thread.id,
              this.parser?.visitor.currentNode._id
            );
          })
          .catch((error) => {
            if (error.file_identifier) {
              file = file as File;
              fileRef = toFileReference(
                {
                  name: originalFile.fileName,
                  size: file.size,
                  type: 'application/pdf',
                } as File,
                error.file_identifier,
                originalFile.fileFolder,
                this.parser?.thread.id,
                this.parser?.visitor.currentNode._id
              );
            } else {
              console.error(error);
            }
          });
      } else {
        fileRef = toFileReference(
          file as GeneratedDocument,
          null,
          originalFile.fileFolder,
          this.parser?.thread.id,
          this.parser?.visitor.currentNode._id
        );
      }
      if (fileRef) {
        fileRefs.push(fileRef);
      }
    }
    return fileRefs;
  }
}

function isUUID(s: string): boolean {
  return Boolean(
    s.match(
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gm
    )
  );
}
