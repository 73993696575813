<ng-container *ngTemplateOutlet="filterList; context: {filters: filters, joinString: initialJoinString}"></ng-container>

<ng-template #filterList let-filters="filters" let-joinString="joinString">
  <ng-container *ngFor="let filter of filters; let i = index">
    <div class="search-breadcrumb" *ngIf="isFilterCriterium(filter)">
      <mat-icon *ngIf="filter?.icon">{{ filter.icon }}</mat-icon>
      <app-search-menu [entries]="queryFilters.bind(this)" (entrySelected)="updateFilter(filters, filter, $event)">
        <span class="menu-trigger filter-name" tabindex="0" [matTooltip]="getLabelForFilter(filter) | translate">{{
          getLabelForFilter(filter) | translate
          }}</span>
      </app-search-menu>
      <button class="operator" [matMenuTriggerFor]="operatorMenu">{{ operatorSignFor(filter) }}</button>
      <ng-container
        *ngTemplateOutlet="filterValueInput; context: {filter: filter, availableFilter: availableFilterFor(filter)}"></ng-container>
      <button mat-icon-button (click)="removeFilter(filters, filter)">
        <mat-icon>close</mat-icon>
      </button>
      <mat-menu #operatorMenu>
        <button mat-menu-item *ngFor="let operator of getPossibleOperations(availableFilterFor(filter))"
          [class.active]="operator === filter.operator"
          (click)="filter.operator = operator; filtersChanged.emit(filters)">
          {{ ('common.label.operators.' + operator) | translate }}
        </button>
      </mat-menu>
    </div>
    <div class="boolean-container" *ngIf="!isFilterCriterium(filter)">
      <ng-container
        *ngTemplateOutlet="filterList; context: {filters: filter.filters, joinString: ('common.label.' + filter.operator)}"></ng-container>
    </div>
    <span *ngIf="joinString?.length && i < filters.length - 1" class="join-string">
      {{ joinString | translate}}
    </span>
  </ng-container>
  <app-search-menu [entries]="queryFilters.bind(this)" (entrySelected)="addFilter(filters, $event)"
    *ngIf="availableFilters">
    <button class="add-filter-button" mat-button>
      <ng-container *ngIf="!joinString?.length">
        <mat-icon>add</mat-icon>
        <span>{{ 'common.button.addSearchFilter' | translate }}</span>
      </ng-container>
      <span>{{ joinString | translate }}</span>
    </button>
  </app-search-menu>
</ng-template>

<ng-template #filterValueInput let-filter="filter" let-availableFilter="availableFilter">
  <input class="filter-value" type="string" [(ngModel)]="filter.value" (change)="filtersChanged.emit(filters)"
    *ngIf="availableFilter?.type === 'string'" [style.width]="(filter.value.length + 1) + 'ch'"
    [class.empty]="!filter.value.length" [matTooltip]="filter.value">
  <input class="filter-value" type="number" [(ngModel)]="filter.value" (change)="filtersChanged.emit(filters)"
    *ngIf="availableFilter?.type === 'number'" [matTooltip]="filter.value">
  <mat-checkbox class="filter-value" *ngIf="availableFilter?.type === 'boolean'" (change)="filtersChanged.emit(filters)"
    [(ngModel)]="filter.value">
  </mat-checkbox>
  <span class="filter-value" *ngIf="availableFilter?.type === 'date'" [dueAtSelector]="true"
    (dueAtChanged)="dueAtChanged(filter, $event)" [useTime]="true" (close)="filtersChanged.emit(filters)"
    [initialDate]="filter.value" [allowEndTime]="false">
    {{ filter.value | date: 'dd.MM.YYYY HH:mm' }}
  </span>
  <span class="filter-value"
    *ngIf="availableFilter?.type !== 'boolean' && availableFilter?.type !== 'string' && availableFilter?.type !== 'number' && availableFilter?.type !== 'date'">{{
    isBoolean(filter?.value)
    ? ((filter.value ? "common.label.yes" : "common.label.no")
    | translate)
    : filter.value
    }}
  </span>
</ng-template>