import { Pipe, PipeTransform } from '@angular/core';
import { ProcessNode } from 'advoprocess';

@Pipe({ name: 'document' })
export class nodeDocumentPipe implements PipeTransform {
  constructor() {}

  transform(node: ProcessNode): { value: string; options: any } {
    const doc = node?.node?.config?.content;
    const options = {
      margins: node?.node?.config?.margin?.value ?? [20, 13, 20, 13],
      letterhead: node?.node?.config?.letterhead ?? undefined,
      pageNumbering: node?.node?.config?.pageNumbering?.value ?? false,
    };
    if (doc) {
      doc['options'] = options;
    }
    return doc || { value: '<p></p>', options: {} };
  }
}
