/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentCreateHandle { 
    assigned?: Array<string>;
    content?: object;
    duplicate_behavior?: DocumentCreateHandle.DuplicateBehaviorEnum;
    name?: string;
    patch_id?: string;
    patch_link?: string;
    path?: string;
    stateid?: string;
    threadid?: string;
}
export namespace DocumentCreateHandle {
    export type DuplicateBehaviorEnum = 'replace' | 'error' | 'increment';
    export const DuplicateBehaviorEnum = {
        Replace: 'replace' as DuplicateBehaviorEnum,
        Error: 'error' as DuplicateBehaviorEnum,
        Increment: 'increment' as DuplicateBehaviorEnum
    };
}


