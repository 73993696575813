import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'handleTableCell' })
export class handleTableCellPipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    if (
      typeof value === 'string' &&
      value.match(/\d+\.\d+\.\d\d\d\d\s+\d+:\d+[\d:\.]*/gm)
    ) {
      return value.slice(0, 16);
    }
    return value;
  }
}
