import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AddOnsService } from './api/addOns.service';
import { AnalyticsService } from './api/analytics.service';
import { AssetsService } from './api/assets.service';
import { ClientsService } from './api/clients.service';
import { CorporateIdentityService } from './api/corporateIdentity.service';
import { EnhancedFeaturesService } from './api/enhancedFeatures.service';
import { EventsService } from './api/events.service';
import { ExecutionService } from './api/execution.service';
import { FilesService } from './api/files.service';
import { HttpstatService } from './api/httpstat.service';
import { InternalService } from './api/internal.service';
import { LawyerService } from './api/lawyer.service';
import { LegalProcessService } from './api/legalProcess.service';
import { NotificationService } from './api/notification.service';
import { PermissionsService } from './api/permissions.service';
import { PublicService } from './api/public.service';
import { RolesService } from './api/roles.service';
import { SecurityGuestService } from './api/securityGuest.service';
import { SettingsService } from './api/settings.service';
import { TemplatesService } from './api/templates.service';
import { TenantsService } from './api/tenants.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
