import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { DialogService } from '../dialog/dialog.service';
import {ContrastModeService} from "../../services/contrast-mode/contrast-mode.service";
import {takeUntil} from "rxjs";
import {DestroyNotifier} from "../../common/destroy-notifier";

const ADVD_FEEDBACK_URL = 'https://form.asana.com/?k=nphLfc-CSp3bAtMYvdtHNQ&d=72232856967712&embed=true';

@Component({
  selector: 'app-profile-chip',
  templateUrl: './profile-chip.component.html',
  styleUrls: ['./profile-chip.component.scss'],
})
export class ProfileChipComponent extends DestroyNotifier implements OnInit {
  showLogin = false;

  ADVD_TENANT_NAME = 'advd';

  isColorContrastEnabled = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    public route: ActivatedRoute,
    public translator: TranslateService,
    private dialog: DialogService,
    private contrastModeService: ContrastModeService
  ) {
    super();
  }

  ngOnInit() {
    this.contrastModeService.getHighContrastEnabled()
      .pipe(takeUntil(this.destroy$))
      .subscribe(enabled => {
        this.isColorContrastEnabled = enabled;
      });
  }

  goToProfile() {
    if (!this.auth.loggedIn || this.auth.isGuest) return;
    if (this.auth.isClient) {
      this.router.navigate([this.realm, 'profile']);
    } else {
      this.router.navigate([
        this.realm,
        'intern',
        'clients',
        'lawyer',
        this.auth.userId,
      ]);
    }
  }

  languages = ['de', 'en'];

  selectLanguage(language: 'de' | 'en') {
    this.auth.setCurrentUserLocale(language).subscribe(() => {
      this.translator.use(language);
    });
  }

  get realm(): string | undefined {
    return (
      this.route.snapshot?.paramMap?.get('realm') ??
      this.route.snapshot?.firstChild?.paramMap?.get('realm')
    );
  }

  openHandbook() {
    let url: string;
    if (this.auth.loggedIn && !this.auth.isClient) {
      url = 'https://silberfluss.notion.site/Handbuch-Mandantenportal-70fc5c94ca4f4ec6b5500f0c5b13112b';
    } else {
      url = 'https://silberfluss.notion.site/Handbuch-Mandantenportal-82cfb8c65dfe4d71b365468763977c05'
    }
    if (this.realm == this.ADVD_TENANT_NAME) {
      url = 'https://silberfluss.notion.site/Handbuch-41543efbf6964cc18be1d013783abfcb?pvs=4';
    }
    window.open(url, '_blank');
  }

  reportError() {
    let url: string = 'https://tally.so/r/mJ0vlJ';
    if (this.auth.isClient) {
      url = 'https://tally.so/r/3xZbRJ';
    }
    if (this.realm === this.ADVD_TENANT_NAME) {
      url = ADVD_FEEDBACK_URL;
    }
    this.dialog.openLink(this.translator.instant('profile.reportError'), url);
  }

  giveFeedback() {
    let url: string = 'https://tally.so/r/w4jVJb';
    if (this.auth.isClient) {
      url = 'https://tally.so/r/3N0N8G';
    }
    if (this.realm === this.ADVD_TENANT_NAME) {
      url = ADVD_FEEDBACK_URL;
    }
    this.dialog.openLink(this.translator.instant('profile.giveFeedback'), url);
  }

  supportHelp() {
    const subject = this.translator.instant('profile.supportMail.subject');
    const body = this.translator.instant('profile.supportMail.body');
    const link = `mailto:support@silberfluss.io?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(link, '_blank');
  }

  toggleColorContrast() {
    this.contrastModeService.toggleHighContrast();
  }
}
