import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  KNOWN_OPERATORS,
  ProcessNode,
  QuestionNode,
  SketchParser,
} from 'advoprocess';
import {
  QuestionConfig,
  getDropDownOptions,
} from 'advoprocess/lib/types/question';
import { NodeOperationsService } from 'src/app/views/lawyer/process/editor/node-operations';

@Component({
  selector: 'app-comparison-input',
  templateUrl: './comparison-input.component.html',
  styleUrls: ['./comparison-input.component.scss'],
})
export class ComparisonInputComponent implements OnInit {
  @Input() condition: { refId: string; operator: string; value: any };

  @Input() dataSource: ProcessNode[];

  @Input() canBeDeleted: boolean;

  @Output() deleted = new EventEmitter();

  @Output() conditionChanged = new EventEmitter();

  @Output() editNode = new EventEmitter<string>();

  inputType = '';

  found = [false, false, false];

  possibleValues = [];

  isValueDynamic = false;

  constructor(
    private snackBar: MatSnackBar,
    private translator: TranslateService,
    private nodeOperationsService: NodeOperationsService
  ) {}

  ngOnInit(): void {
    this.checkRefIds();
  }

  emitChange(): void {
    this.checkRefIds();
    this.conditionChanged.emit();
  }

  get availableTags(): string[] {
    return this.dataSource
      .reduce((p, n) => [...p, ...(n?.node?.refIds ?? [])], [])
      .filter((n) =>
        n.includes(this.condition.refId?.split(/>/gm)?.[0]?.trim())
      );
  }

  get availableTagsForValue(): string[] {
    let value = this.condition.value;
    if (value.startsWith("#")) {
      value = value.substring(1);
    }

    const tags = this.dataSource
      .reduce((p, n) => [...p, ...(n?.node?.refIds ?? [])], [])
      .filter((n) =>
        n.includes(value?.split(/>/gm)?.[0]?.trim())
      );

    tags.forEach((tag, index) => {
      tags[index] = "#" + tag;
    });

    return tags;
  }

  async checkRefIds(): Promise<void> {
    this.found[0] = this.availableTags.includes(
      this.condition.refId?.split(/>/gm)?.[0]?.trim()
    );
    this.found[1] = Object.keys(KNOWN_OPERATORS).includes(
      this.condition.operator
    );
    
    this.found[2] = false;
    
    // check if the value is dynamic and update the isValueDynamic flag
    this.isValueDynamic = this.condition.value?.startsWith('#');
    if (this.isValueDynamic) {
      this.found[2] = this.availableTagsForValue.includes(
        this.condition.value?.split(/>/gm)?.[0]?.trim()
      );
    }
    // if the value is dynamic, the type is always string -> no need to update the input type
    // if the value is not dynamic, we are using the available tags list -> no need tu update the possible values list
    if (this.isValueDynamic) return; 

    if (this.found[0]) {
      const target = this.dataSource.find((n) =>
        n?.node?.refIds?.includes(this.condition.refId)
      );
      if (target) {
        this.inputType = await target.node.mockType(target.node?.refId);
        const correctValue =
          this.inputType === 'number'
            ? this.isNumeric(this.condition.value)
            : true;
        this.found[2] = !!correctValue;
        if (!target) {
          this.possibleValues = [];
        }
        const targetConfig: QuestionConfig =
          target.node.identifier === 'FormNode'
            ? target.node?.config?.questions?.value?.find(
                (q) => q.refId === this.condition.refId
              )?.question
            : target.node?.config;
        const dropdownOptions = getDropDownOptions(targetConfig?.defaultValue);
        let outputNames;
        if (typeof dropdownOptions === 'string') {
          outputNames = [];
        } else {
          outputNames = dropdownOptions.map((t) => t.text);
        }
        if (!outputNames) {
          this.possibleValues = [];
        }
        this.possibleValues = outputNames.map((output) => ({
          key: output,
          value: output,
        }));
      } else {
        this.inputType = '';
        this.possibleValues = [];
      }
    } else {
      this.possibleValues = [];
      this.inputType = '';
    }
  }

  private isNumeric(str: any): boolean {
    if (typeof str !== 'string') {
      return false;
    }
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  }

  operators(): any[] {
    return this.inputType === 'number'
      ? Object.keys(KNOWN_OPERATORS).filter((o) => o !== '~~')
      : ['=', '!=', '~~'];
  }

  addNodeToDataSource(refId: string): void {
    if (!this.dataSource) {
      return;
    }
    const startNode = this.dataSource.find(
      (n) => n.node.identifier === 'StartNode' || n.start
    );
    const oldOut = startNode.outputs[0] ?? null;
    const block = SketchParser.buildNode('QuestionNode', null);

    this.nodeOperationsService.makeRoom(this.dataSource, startNode.y + 70, 350);

    const processNode = this.nodeOperationsService.addNode(
      this.dataSource,
      block,
      startNode.x,
      startNode.y + 70,
      1,
      'string'
    );

    processNode.outputs[0] = oldOut;

    startNode.outputs[0] = processNode._id;

    processNode.node.refId = refId;
    this.snackBar.open(
      this.translator.instant('document.edit.added', {
        name: refId,
        type: this.translator.instant('node.names.' + block.typeName),
      }),
      '',
      { duration: 3000 }
    );
  }

  /**
   * returns the value of the condition without the # if it starts with it
   * otherwise returns the value as is
  */
  getTrimmedValue(): string {
    if (this.condition.value.startsWith('#')) return this.condition.value.substring(1);
    return this.condition.value;
  }
}

