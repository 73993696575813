/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientProcessOption } from './clientProcessOption';


export interface ClientProcessParam { 
    autocomplete?: boolean;
    check?: ClientProcessParam.CheckEnum;
    labels?: Array<object>;
    max?: number;
    min?: number;
    options?: Array<ClientProcessOption>;
    possibleValues?: Array<string>;
    prefix?: string;
    questions?: Array<string>;
    steps?: number;
    unit?: string;
}
export namespace ClientProcessParam {
    export type CheckEnum = 'email' | 'phone';
    export const CheckEnum = {
        Email: 'email' as CheckEnum,
        Phone: 'phone' as CheckEnum
    };
}


