import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { CustomToolTipComponent } from './custom-tool-tip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subject, fromEvent, race, timer } from 'rxjs';
import _ from 'lodash';
import { delay, filter } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[toolTip]',
})
export class ToolTipRendererDirective implements OnInit, OnDestroy {
  /**
   * This will be used to show tooltip or not
   * This can be used to show the tooltip conditionally
   */
  @Input() showToolTip: boolean = true;

  //If this is specified then the specified text will be shown in the tooltip
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input(`toolTip`) text: string;

  //If this is specified then specified template will be rendered in the tooltip
  @Input() contentTemplate: TemplateRef<any>;

  // Set this to true to be able to click inside the tooltip
  @Input() interactive: boolean = false;

  // Use this to pass a context to the template
  @Input() templateContext: any = {};

  private _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef
  ) {}

  /**
   * Init life cycle event handler
   */
  ngOnInit() {
    if (!this.showToolTip) {
      return;
    }

    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          offsetX: 5,
        },
      ]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  mouseOnPopup$ = new Subject<void>();

  /**
   * This method will be called whenever the mouse enters in the Host element
   * i.e. where this directive is applied
   * This method will show the tooltip by instantiating the CustomToolTipComponent and attaching to the overlay
   */
  @HostListener('pointerenter')
  show() {
    //attach the component if it has not already attached to the overlay
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<CustomToolTipComponent> =
        this._overlayRef.attach(new ComponentPortal(CustomToolTipComponent));
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.templateContext = this.templateContext;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      let clickedInside = false;
      tooltipRef.instance.el.nativeElement.addEventListener(
        'pointerenter',
        () => {
          this.mouseOnPopup$.next();
        }
      );
      tooltipRef.instance.el.nativeElement.addEventListener(
        'pointerleave',
        () => (clickedInside ? null : this.closeToolTip())
      );
      tooltipRef.instance.el.nativeElement.addEventListener(
        'pointerdown',
        () => (clickedInside = true)
      );
      tooltipRef.instance.el.nativeElement.addEventListener(
        'click',
        (event) => {
          event.stopPropagation();
          event.preventDefault();
        }
      );
      tooltipRef.instance.el.nativeElement.addEventListener('mouseleave', () =>
        clickedInside ? null : this.closeToolTip()
      );
    }
  }

  @HostListener('keydown', ['$event'])
  showWithKey(event) {
    if (event.key !== 'Enter') return;
    // ToDo: This doesn't work
    this.show();
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    this.closeToolTip();
  }

  /**
   * This method will be called when the mouse goes out of the host element
   * i.e. where this directive is applied
   * This method will close the tooltip by detaching the overlay from the view
   */
  @HostListener('pointerleave', ['$event'])
  hide() {
    if (!this.interactive) {
      this.closeToolTip();
      return;
    }
    race(this.mouseOnPopup$, timer(100))
      .pipe(filter((e) => !_.isNil(e)))
      .subscribe((v) => {
        this.closeToolTip();
      });
  }

  /**
   * Destroy lifecycle event handler
   * This method will make sure to close the tooltip
   */
  ngOnDestroy() {
    this.closeToolTip();
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  private closeToolTip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
