import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ContextMenuComponent,
  ContextMenuItem,
} from './context-menu.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[contextMenu]',
})
export class ContextMenuDirective implements OnInit, OnDestroy {
  @Input('contextMenu') contextMenuItems: ContextMenuItem[] = [];

  @Input() contextValue: any = undefined;

  private _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef
  ) {}

  ngOnInit() {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  @HostListener('contextmenu', ['$event'])
  show(event) {
    event.preventDefault();
    if (!this.contextMenuItems?.length) return;
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const originRect = this._elementRef.nativeElement.getBoundingClientRect();
      const diffx = event.clientX - originRect.left;
      const diffy = event.clientY - originRect.top;
      const tooltipRef: ComponentRef<ContextMenuComponent> =
        this._overlayRef.attach(new ComponentPortal(ContextMenuComponent));
      tooltipRef.instance.posX = `${diffx}px`;
      tooltipRef.instance.posY = `${diffy}px`;
      tooltipRef.instance.contextMenuItems = this.contextMenuItems;
      tooltipRef.instance.contextValue = this.contextValue;
    }
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    this.closeContextMenu();
  }

  @HostListener('document:contextmenu', ['$event'])
  otherContextMenu(event) {
    if (!this._elementRef.nativeElement.contains(event.target))
      this.closeContextMenu();
  }

  ngOnDestroy() {
    this.closeContextMenu();
  }

  private closeContextMenu() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
