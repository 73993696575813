/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmailFileAttachments } from './emailFileAttachments';


/**
 * An email object
 */
export interface Email { 
    bcc?: Array<string>;
    cc?: Array<string>;
    file_attachments?: Array<EmailFileAttachments>;
    message?: string;
    recipient?: Array<string>;
    subject?: string;
}

