import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import dayjs from 'dayjs';
import * as weekday from 'dayjs/plugin/weekday';
import * as _ from 'lodash';

export interface QuickAccessOption {
  prefix?: string;
  suffix?: string;
  dynamicNumber?: boolean;
  value?: number;
  onSelect: (value?: number) => Date;
  isActive?: (value?: number) => boolean;
}

@Component({
  selector: 'app-due-at-selector',
  templateUrl: './due-at-selector.component.html',
  styleUrls: ['./due-at-selector.component.scss'],
})
export class DueAtSelectorComponent implements OnInit {
  @ViewChild('calendar', { static: false }) calendar: MatCalendar<Date>;

  @Output() dueAtChanged = new EventEmitter<
    | {
        date: Date;
        durationMinutes?: number;
      }
    | undefined
  >();
  @Output() close = new EventEmitter<void>();

  @Input() initialDate?: Date | string = undefined;
  @Input() initialDurationMinutes?: number = undefined;

  @Input() useTime = false;
  @Output() useTimeChange = new EventEmitter<boolean>();

  @Input() allowDelete = false;
  @Input() allowEndTime = true;

  endTimeActive = false;

  timeStart = {
    hours: 15,
    minutes: 30,
  };
  timeEnd = {
    hours: 16,
    minutes: 0,
  };

  quickaccessOptions: QuickAccessOption[] = [
    {
      prefix: 'schedule.label.nextWeek',
      onSelect: () => {
        return dayjs().weekday(8).toDate();
      },
      isActive: () => {
        return dayjs(this.selectedDate).diff(dayjs().weekday(8), 'days') === 0;
      },
    },
    {
      prefix: 'schedule.label.nextMonth',
      onSelect: () => {
        return dayjs().add(1, 'month').date(1).toDate();
      },
      isActive: () => {
        return (
          dayjs(this.selectedDate).diff(
            dayjs().add(1, 'month').date(1),
            'days'
          ) === 0
        );
      },
    },
    {
      prefix: 'schedule.label.in',
      suffix: 'schedule.label.days',
      value: 7,
      dynamicNumber: true,
      onSelect: (value) => {
        return dayjs().add(value, 'days').toDate();
      },
      isActive: (value) => {
        return (
          dayjs(this.selectedDate).diff(dayjs().add(value, 'days'), 'days') ===
          0
        );
      },
    },
    {
      prefix: 'schedule.label.in',
      suffix: 'schedule.label.weeks',
      value: 2,
      dynamicNumber: true,
      onSelect: (value) => {
        return dayjs().add(value, 'weeks').toDate();
      },
      isActive: (value) => {
        return (
          dayjs(this.selectedDate).diff(dayjs().add(value, 'weeks'), 'days') ===
          0
        );
      },
    },
    {
      prefix: 'schedule.label.in',
      suffix: 'schedule.label.months',
      value: 3,
      dynamicNumber: true,
      onSelect: (value) => {
        return dayjs().add(value, 'months').toDate();
      },
      isActive: (value) => {
        return (
          dayjs(this.selectedDate).diff(
            dayjs().add(value, 'months'),
            'days'
          ) === 0
        );
      },
    },
  ];

  selectedDate: Date;
  activeOption: QuickAccessOption | undefined;

  constructor(public el: ElementRef) {
    dayjs.extend(weekday);
  }

  ngOnInit(): void {
    if (!!this.initialDate) {
      this.selectedDate = dayjs(
        dayjs(this.initialDate).format('YYYY-MM-DD')
      ).toDate();
      this.timeStart.hours = parseInt(dayjs(this.initialDate).format('HH'));
      this.timeStart.minutes = parseInt(dayjs(this.initialDate).format('mm'));
    }
    if (
      !_.isNil(this.initialDurationMinutes) &&
      this.initialDurationMinutes >= 0 &&
      this.allowEndTime
    ) {
      this.timeEnd.hours =
        this.timeStart.hours + Math.floor(this.initialDurationMinutes / 60);
      this.timeEnd.minutes =
        this.timeStart.minutes + (this.initialDurationMinutes % 60);
    }
    this.endTimeActive = !!this.initialDurationMinutes && !!this.allowEndTime;
  }

  selectQuickAccessOption(option: QuickAccessOption) {
    const date = option.onSelect(option.value);
    this.selectedDate = date;
    this.calendar._goToDateInView(this.selectedDate, 'month');
    this.updateActive();
  }

  calendarChanged(timestamp: Date) {
    this.selectedDate = timestamp;
    this.updateActive();
  }

  updateActive() {
    this.activeOption =
      this.quickaccessOptions.find((o) => o.isActive(o.value)) ?? undefined;
    let date = dayjs(dayjs(this.selectedDate).format('YYYY-MM-DD'));
    let durationMinutes = undefined;
    if (this.useTime) {
      date = date.add(
        this.timeStart.hours * 60 + this.timeStart.minutes,
        'minutes'
      );
      if (!this.endTimeActive) {
        durationMinutes = 0;
      } else {
        durationMinutes =
          this.timeEnd.hours * 60 +
          this.timeEnd.minutes -
          (this.timeStart.hours * 60 + this.timeStart.minutes);
      }
    }
    this.dueAtChanged.emit({
      date: date.toDate(),
      durationMinutes,
    });
  }
}
