import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Jodit, IJodit, Select } from 'jodit-pro';

@Component({
  selector: 'app-rich-setting-editor',
  templateUrl: './rich-setting-editor.component.html',
  styleUrls: ['./rich-setting-editor.component.scss'],
})
export class RichSettingEditorComponent implements OnInit, OnChanges {
  @Input() value: string = '';

  @Output() valueChanged = new EventEmitter<string>();

  @ViewChild('editorContainer', { static: true }) editorContainer: ElementRef;

  @Input() buttons: string =
    'bold, italic, underline,|, ul, ol,|, paragraph, hr, table, link, |, undo, redo';

  editor: IJodit;

  constructor() {}

  ngOnInit(): void {
    this.initializeEditor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.value) {
      return;
    }
    this.updateText();
  }

  private initializeEditor(): void {
    const config: IJodit['options'] = {
      allowResizeY: false,
      allowResizeX: false,
      showPlaceholder: false,
      colorPickerDefaultTab: 'foreground',
      language: 'de',
      toolbarAdaptive: false,
      toolbar: true,
      buttons: this.buttons,
      showXPathInStatusbar: false,
      style: {
        fontFamily: 'Arial',
      },
      allowResizeTags: ['img', 'table', 'placeholder', 'jodit', 'iframe'],
      cleanHTML: {
        removeEmptyElements: true,
        denyTags: {
          script: true,
        },
      },
    };
    this.editor = Jodit.make(this.editorContainer.nativeElement, config);
    this.editor.e.on('blur', (event) => {
      this.valueChanged.emit(this.editor.value);
    });
    this.updateText();
  }

  private updateText(): void {
    if (!this.editor) {
      return;
    }
    this.editor.setElementValue(this.value);
  }
}
