/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Event { 
    autothrow?: boolean;
    created_at?: string;
    created_by_client?: string;
    created_by_lawyer?: string;
    done?: boolean;
    due_at?: string;
    durationMinutes?: number | null;
    event_content?: any | null;
    event_type?: string;
    id?: string;
    priority?: number;
}

