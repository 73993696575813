import { Component, Inject, Optional, OnInit, Injector } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileAttachment, ProcessNode, uploadFile } from 'advoprocess';
import { FilesService, UploadHandle } from 'src/api';
import { DialogService } from './dialog.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ProcessService } from 'src/app/views/process/process.service';
import { checkFileDuplicationInDossier } from 'src/app/common/helpers';
import { TranslateService } from '@ngx-translate/core';
import { getAllowedFileString, getMaxUploadSize, getUploadSizeHumanReadable } from 'src/app/common/files';

export interface DialogConfig {
  stateId?: string;
  isPublic?: boolean;
  folder?: string;
  title: string;
  service?: ProcessService;
}

@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.scss'],
})
export class FileDialogComponent implements OnInit {
  filePreview: SafeUrl;

  files: File[] = [];

  objectKeys = Object.keys;

  uploadProgress: number = -1;

  maxFileSize = getMaxUploadSize();

  ALLOWED_FILE_STRING = getAllowedFileString();

  constructor(
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    public service: DialogService,
    private api: FilesService,
    public auth: AuthService,
    private injector: Injector,
    private translator: TranslateService,
    @Optional()
    public dialogRef: MatDialogRef<FileDialogComponent, FileAttachment>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogConfig
  ) { }

  ngOnInit() {
    this.service.saveFile(this.saveFile.bind(this));
  }

  async saveFile(): Promise<void> {
    this.uploadProgress = -1;
    if (!this.files?.length) {
      return;
    }
    let duplicateBehavior: UploadHandle.DuplicateBehaviorEnum | 'no_conflict' = 'error';
    if (this.data?.service?.stateId) {
      duplicateBehavior = await checkFileDuplicationInDossier(this.data.service, this.injector, this.files[0].name, this.data.folder ? this.data.folder : undefined);
      if (duplicateBehavior === UploadHandle.DuplicateBehaviorEnum.Error) {
        this.dialogRef.close();
        return;
      }
    }
    this.uploadProgress = 0;
    this.dialogRef.disableClose = true;
    uploadFile(
      this.files[0],
      this.data?.stateId,
      {
        folder: this.data.folder ?? '',
        duplicateBehavior: duplicateBehavior === 'no_conflict' ? 'error' : duplicateBehavior
      },
      this.api,
      [],
      this.data?.isPublic ?? false,
      (progress) => {
        this.uploadProgress = progress;
      }
    )
      .then((fileIdentifierObject) => {
        this.dialogRef.close({
          id: fileIdentifierObject.file_identifier,
          name: this.files[0].name,
          mime: this.files[0].type,
          type: 'upload',
          size: this.files[0].size,
        });
      })
      .catch((error) => {
        this.dialogRef.disableClose = false;
        if (error.hasOwnProperty('file_identifier')) {
          this.dialogRef.close({
            id: error.file_identifier,
            name: this.files[0].name,
            mime: this.files[0].type,
            type: 'upload',
            size: this.files[0].size,
          });
        } else if (error?.status === 402) {
          this.snackBar.open(this.translator.instant('common.error.noStorageSpaceLeft'));
        } else {
          this.snackBar.open(error?.error?.error ?? 'ERROR');
        }
      });
  }

  onSelectFile(event): void {
    this.filePreview = null;
    if (this.files.length > 0) {
      this.snackBar.open('Es kann nur genau eine Datei ausgewählt werden.');
    } else {
      this.files.push(...event.addedFiles);
      if (event.addedFiles.length) {
        if (event.addedFiles[0].type.match(/image\/.+/)) {
          this.filePreview = this.sanitizer.bypassSecurityTrustUrl(
            URL.createObjectURL(event.addedFiles[0])
          );
        }
      }
    }
    if (event.rejectedFiles.length) {
      this.snackBar.open(
        this.translator.instant('common.error.file.tooLargeGeneral', {
          fileSize: getUploadSizeHumanReadable()
        })
      );
    }
  }

  onFileRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
