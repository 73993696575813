export function binarySearch(ar, el, compareFn): any {
  if (ar.length <= 0) return 0;
  if (el.x < ar[0].x) {
    return 0;
  }
  if (el.x > ar[ar.length - 1].x) {
    return ar.length;
  }
  let m = 0;
  let n = ar.length - 1;
  while (m <= n) {
    const k = (n + m) >> 1;
    const cmp = compareFn(el, ar[k]);
    if (cmp > 0) {
      m = k + 1;
    } else if (cmp < 0) {
      n = k - 1;
    } else {
      return k;
    }
  }
  return -m - 1;
}

export function binaryGreaterSearch(ar, y): any {
  if (y < ar[0].y) {
    return 0;
  }
  if (y > ar[ar.length - 1].y) {
    return ar.length;
  }
  let m = 0;
  let n = ar.length - 1;
  let ans = -1;
  while (m <= n) {
    const k = (n + m) >> 1;
    if (ar[k].y <= y) {
      m = k + 1;
    } else {
      ans = k;
      n = k - 1;
    }
  }
  return ans;
}
