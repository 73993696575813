import {
  Component,
  ElementRef,
  HostListener,
  Injector,
  Input,
  Renderer2,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { CustomCIService } from 'src/app/services/customCI.service';
import { headerToken } from './token';
import { ComponentType } from '@angular/cdk/portal';
import { AdornmentsService } from 'src/app/adornments.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() isEmbed: boolean;

  public activeComponents: Array<ElementRef<any>> = [];

  constructor(
    private ci: CustomCIService,
    private router: Router,
    public auth: AuthService,
    private service: AdornmentsService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.service.registerHeader(this);
  }

  get customLogo(): SafeResourceUrl {
    return this.ci.customLogo;
  }

  goHome(): void {
    this.router.navigateByUrl('/intern');
  }

  replaceHeader(component: ElementRef<any>) {
    this.activeComponents.push(component);
    this.renderer.appendChild(this.el.nativeElement, component.nativeElement);
  }

  removeHeader(element: ElementRef<any>) {
    const targetIdx = this.activeComponents.findIndex((el) => el === element);
    if (targetIdx === -1) return;
    const nativeElement = this.activeComponents[targetIdx].nativeElement;
    this.activeComponents.splice(targetIdx, 1);
    this.renderer.removeChild(this.el.nativeElement, nativeElement);
  }
}
