<ng-container *ngIf="!loading; else loadingOverlay">
  <div class="errors" *ngIf="validated !== true">
    <mat-error [innerHTML]="validated | sanitizeHtml"></mat-error>
  </div>
  <div class="view-settings"  role="toolbar" aria-label="View settings">
    <div class="view-tab" role="tablist" *ngFor="let tab of openTabs; let i = index" [class.active]="tab === currentTab" (click)="selectTab(tab)" role="tab" [attr.aria-selected]="tab === currentTab" [id]="'tab-' + i"  (keydown.enter)="selectTab(tab)">
      <mat-icon *ngIf="tab.format === 'table'" aria-hidden="true">table_view</mat-icon>
      <mat-icon *ngIf="tab.format === 'form'" aria-hidden="true">dashboard</mat-icon>
      <span class="tab-name" tabindex="0">{{ tab.name }}</span>
      <span class="close-button" *ngIf="!tab.fixed" (click)="closeTab(tab)" role="button" tabindex="0" [attr.aria-label]="('aria.label.closeTab' | translate) + ': ' + tab.name " (keydown.enter)="closeTab(tab)">
        <mat-icon aria-hidden="true">close</mat-icon>
      </span>
    </div>
    <app-search-menu [entries]="queryViewTabs.bind(this)" (entrySelected)="addTab($event)"
      *ngIf="!auth.isClient && !hasDefaultTab">
      <button mat-icon-button class="add-bttn" [attr.aria-label]="'aria.label.addNewTab' | translate">
        <mat-icon aria-hidden="true">add</mat-icon>
      </button>
    </app-search-menu>
  </div>
  <div class="data-render-content">
    <div class="form-view" *ngIf="currentTab.format === 'form'">
      <div class="missing-fields-indicator" *ngIf="!!parsedTabDefinition && !!missingRequiredFields?.length"
        [class.open]="missingRequiredFieldsOpen">
        <div class="fields-header">
          <span><mat-icon>warning</mat-icon>{{ (missingRequiredFields.length === 1 ?
            'common.message.missingFieldsHeaderSingular' : 'common.message.missingFieldsHeader') | translate:
            {fieldCount:
            missingRequiredFields.length} }}</span>
          <button mat-icon-button (click)="missingRequiredFieldsOpen = !missingRequiredFieldsOpen">
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
        <div class="fields-list">
          <ul>
            <li *ngFor="let f of missingRequiredFields" [innerHTML]="f.question.questionText.value | sanitizeHtml"></li>
          </ul>
        </div>
      </div>
      <app-form-renderer [fields]="parsedTabDefinition" [readOnly]="false" [hideContinue]="true"
        *ngIf="parsedTabDefinition" (formStateChange)="formValueChange.next($event)" #formRenderer
        [previousDataStore]="formTempDataStore">
      </app-form-renderer>
    </div>
    <table class="data-render-table" *ngIf="currentTab.format === 'table'">
      <tr class="header-row">
        <td colspan="4">
          <div class="search-bar" role="search">
            <mat-icon aria-hidden="true">search</mat-icon>
            <!-- Visually hidden label for the input -->
            <label for="search-input" class="sr-only">
              {{ 'common.label.search' | translate }}
            </label>
            <input id="search-input" [formControl]="filterForm.get('queryString')"
              [placeholder]="('common.label.search' | translate) + ' ...'" />
          </div>
        </td>
      </tr>
      <ng-container *ngTemplateOutlet="
        dataRows;
        context: { values: filteredData, data: data, inSub: false, path: [] }
      "></ng-container>
    </table>
  </div>
</ng-container>

<ng-template #loadingOverlay>
  <div class="loading">
    <mat-spinner [diameter]="20"></mat-spinner>
  </div>
</ng-template>

<ng-template #dataRows let-values="values" let-data="data" let-inSub="inSub" let-path="path">
  <ng-container *ngFor="let value of values">
    <ng-container *ngIf="value?.type !== 'subdata'">
      <ng-container *ngTemplateOutlet="
          normalDataRows;
          context: { value: value, inSub: inSub, group: data, path: path }
        "></ng-container>
    </ng-container>
    <ng-container *ngIf="value?.type === 'subdata'">
      <tr class="subprocess-header" [class.expanded]="value?.subtreeExpanded">
        <td colspan="4">
          <div>
            <button mat-icon-button (click)="value.subtreeExpanded = !value.subtreeExpanded">
              <mat-icon>expand_more</mat-icon>
            </button>
            <span class="subprocess-title">
              <mat-icon>account_tree</mat-icon>
              {{ value?.name }}
            </span>
          </div>
        </td>
      </tr>
      <ng-container *ngIf="value?.subtreeExpanded">
        <ng-container *ngTemplateOutlet="
          dataRows;
          context: { values: value?.value?.data, inSub: true, data: value?.value?.data, path: path.concat(value.name)}
        "></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <tr *ngIf="fieldChange.observers.length > 0" [class.inSub]="inSub">
    <td colspan="4" class="add-row">
      <button mat-button (click)="addDataRow(data, path)">
        <mat-icon>add</mat-icon>
        {{ "common.button.addDataPoint" | translate }}
      </button>
    </td>
  </tr>
</ng-template>

<ng-template #normalDataRows let-value="value" let-group="group" let-inSub="inSub" let-path="path">
  <tr [class.highlighted]="
      !!highlightDataPoint && highlightDataPoint === value.name
    " [class.inSub]="inSub" [attr.data-point-name]="value.name">
    <td>
      <mat-icon [matTooltip]="
          'common.types.' + (value?.value?.type ?? value?.type) | translate
        ">{{ iconFor(value?.value?.type ?? value?.type) }}</mat-icon>
    </td>
    <td (dblclick)="
        value.editName || fieldChange.observers.length <= 0
          ? null
          : onEditField('name', value, group, path)
      " [attr.tabindex]="value?.options?.length ? '-1' : '0'" [class.disabled]="value?.options?.length"
      (keydown.enter)="
        value.editName || fieldChange.observers.length <= 0
          ? null
          : onEditField('name', value, group, path)
      ">
      <input class="edit-input" [value]="value.name" [attr.forValue]="value.name" *ngIf="value.editName"
        (blur)="onBlurField('name', value, $event.target.value, group, path)"
        (keydown.enter)="onBlurField('name', value, $event.target.value, group, path)" />
      <span *ngIf="!value.editName"><mat-icon *ngIf="value?.options?.length === 1">lock</mat-icon><span
          *ngIf="!!value?.new" [matTooltip]="'common.tooltip.notYetSaved' | translate">*</span><span
          class="value-name">{{ value.name }}</span></span>
    </td>
    <td (dblclick)="
        value.editValue || fieldChange.observers.length <= 0
          ? null
          : onEditField('value', value, group, path)
      " [attr.tabindex]="value?.options?.length === 1 ? '-1' : '0'" [class.disabled]="value?.options?.length === 1"
      (keydown.enter)="
        value.editValue || fieldChange.observers.length <= 0
          ? null
          : onEditField('value', value, group, path)
      ">
      <div [innerHTML]="
          (value?.showAll || value?.type === 'file' ? value.value : value.value?.slice(0, 1500))
            | sanitizeHtml
        " *ngIf="value.value?.type !== 'json' && !value.editValue" class="value-name"></div>
      <button mat-raised-button color="primary" (click)="value.showAll = !value.showAll" *ngIf="
          value.value?.type !== 'json' &&
          value.type !== 'file' &&
          !value.editValue &&
          value?.value?.length > 1500
        ">
        <mat-icon>{{ value.showAll ? "expand_less" : "expand_more" }}</mat-icon>
        {{
        (value.showAll ? "common.button.showLess" : "common.button.showAll")
        | translate
        }}
      </button>
      <ng-container *ngIf="!!value.editValue">
        <ng-container
          *ngTemplateOutlet="valueEditor; context: { value: value, group: group, path: path }"></ng-container>
      </ng-container>
      <div *ngIf="value.value?.type === 'json' && !value.editValue">
        <ng-container *ngTemplateOutlet="
            jsonRenderer;
            context: { data: value.value.data, path: [value.name] }
          "></ng-container>
      </div>
    </td>
    <td class="actions">
      <span role="group" [attr.aria-label]="'aria.label.actionButtons' | translate">
        <button mat-icon-button (click)="value.showHistory = !value.showHistory"
          [disabled]="!value?.history?.length || value.history.length <= 1"
          [matTooltip]="'common.tooltip.showHistory' | translate" [attr.aria-label]="'common.tooltip.showHistory' | translate">
          <mat-icon aria-hidden="true">history</mat-icon>
        </button>
        <button mat-icon-button (click)="onDeleteField(value, group, path)" [disabled]="!!value?.options?.length"
          *ngIf="!!fieldChange.observers.length" [attr.aria-label]="'aria.label.deleteField' | translate">
          <mat-icon aria-hidden="true">delete</mat-icon>
        </button>
      </span>
    </td>
  </tr>
  <tr class="history" *ngIf="!!value?.history?.length && !!value.showHistory" [@historyAnimation]>
    <td></td>
    <td>
      <div class="history-title">
        <mat-icon>history</mat-icon><span>{{ "common.label.history" | translate }}</span>
      </div>
    </td>
    <td>
      <div class="history-entries">
        <table>
          <tr *ngFor="let entry of value.history | history">
            <td>
              <div [innerHTML]="entry.value | sanitizeHtml" *ngIf="value.value?.type !== 'json'"></div>
              <div *ngIf="value.value?.type === 'json'">
                <ng-container *ngTemplateOutlet="
                    jsonRenderer;
                    context: { data: entry.value.data, path: [value.name] }
                  "></ng-container>
              </div>
            </td>
            <td>
              {{ entry.timestamp | date: 'dd.MM.YYYY HH:mm' }}
            </td>
            <!-- ToDo: Parse user uuid and show user emblem -->
            <!-- <td>
              {{ entry.postedBy?.uuid }}
            </td> -->
          </tr>
        </table>
      </div>
    </td>
    <td></td>
  </tr>
</ng-template>

<ng-template #jsonRenderer let-data="data" let-path="path">
  <div class="json-parent" *ngFor="let entry of data">
    <div class="json-row" [class.sub]="entry.type === 'json' ? entry.value?.length : false">
      <mat-icon *ngIf="entry.type === 'json' ? entry.value?.length : false" class="expand-more"
        (click)="entry.folded = !entry.folded">{{ entry.folded ? "chevron_right" : "expand_more" }}</mat-icon><span
        class="json-entry-name">{{
        isNumber(entry.key)
        ? parseInt(entry.key) + 1
        : entry.key === "Object"
        ? ("common.label.object" | translate)
        : entry.key
        }}</span>
      <span class="json-value" *ngIf="entry.type !== 'json'">
        {{ entry.value }}
      </span>
      <button mat-icon-button class="copy-path-button" (click)="
          copyObjectPath(entry.skipPath ? path : path.concat([entry.key]))
        ">
        <mat-icon>file_copy</mat-icon>
      </button>
    </div>
    <div class="json-children" *ngIf="entry.type === 'json' && !entry.folded" [@jsonAnimation]>
      <ng-container *ngTemplateOutlet="
          jsonRenderer;
          context: {
            data: entry.value,
            path: entry.skipPath ? path : path.concat([entry.key])
          }
        "></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #valueEditor let-value="value" let-group="group" let-path="path">
  <ng-container *ngIf="value.value?.type !== 'json'">
    <input class="edit-input" [attr.forValue]="value.name" [value]="value.value" *ngIf="!value?.options?.length"
      (blur)="onBlurField('value', value, $event.target.value, group, path)"
      (keydown.enter)="onBlurField('value', value, $event.target.value, group, path)" />
    <input class="edit-input" type="text" *ngIf="value?.options?.length" [value]="value.value" [matAutocomplete]="auto"
      [attr.forValue]="value.name" (blur)="onBlurField('value', value, $event.target.value, group, path)"
      (keydown.enter)="onBlurField('value', value, $event.target.value, group, path)" />
    <mat-autocomplete #auto="matAutocomplete" requireSelection>
      <mat-option [value]="option" *ngFor="let option of value.options"
        (pointerdown)="$event.preventDefault(); $event.stopPropagation()">{{ option }}</mat-option>
    </mat-autocomplete>
  </ng-container>
  <div class="edit-input-json" [attr.forValue]="value.name" *ngIf="value.value?.type === 'json'"></div>
</ng-template>
