/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ExecutionStateParticipant { 
    address?: string;
    fax?: string;
    first_name?: string;
    further_info?: string;
    id?: string;
    last_name?: string;
    mail?: string;
    parent?: string;
    phone?: string;
    profile_picture?: string;
    role?: string;
    source?: ExecutionStateParticipant.SourceEnum;
    website?: string;
}
export namespace ExecutionStateParticipant {
    export type SourceEnum = 'lawyer' | 'client';
    export const SourceEnum = {
        Lawyer: 'lawyer' as SourceEnum,
        Client: 'client' as SourceEnum
    };
}


