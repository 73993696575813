<div class="search-menu-trigger" #trigger role="button" [attr.aria-label]="'aria.label.openSearchMenu' | translate">
  <ng-content></ng-content>
</div>
<div class="hidden-trigger" [matMenuTriggerFor]="menu" #hiddenTrigger="matMenuTrigger" aria-hidden="true"></div>
<mat-menu #menu="matMenu" class="sf-search-menu"
  [class]="asSelectionDropdown ? 'search-menu-selection-dropdown' : null">
  <div class="search" [class.hidden]="hideSearchBar" role="search">
    <input matInput type="text" [placeholder]="'common.label.search' | translate" [formControl]="searchControl"
      (click)="$event.preventDefault(); $event.stopPropagation()" #searchInput (keydown)="enterValue($event, false)" [attr.aria-label]="'aria.label.search' | translate" />
  </div>
  <div class="results-container" role="listbox" [attr.aria-label]="'aria.label.searchResults' | translate">

    <!-- Entry -->
    <ng-container *ngFor="let entry of activeEntries">
      <!-- Actual entry -->
      <ng-container *ngIf="!entry.isGroupHeader">
        <ng-container *ngTemplateOutlet="itemWrapper; context: {entry: entry, layer: 0}"></ng-container>
      </ng-container>

      <!-- Group header -->
      <span class="group-header" *ngIf="entry.isGroupHeader && entry.name?.length" role="group" [attr.aria-label]="entry.name | translate">
        <mat-icon *ngIf="entry.icon" aria-hidden="true">{{ entry.icon }}</mat-icon>
        <span class="name">{{ entry.name | translate }}</span>
      </span>
    </ng-container>
    <div class="loading-indicator" *ngIf="loading" aria-live="polite" role="status">
      <mat-spinner [diameter]="20" [attr.aria-label]="'aria.label.loading' | translate "></mat-spinner>
    </div>
    <div class="no-results" *ngIf="!activeEntries?.length && !loading" role="status" aria-live="polite">
      <mat-icon aria-hidden="true">search</mat-icon>
      <span>{{ "common.label.noResults" | translate }}</span>
    </div>
  </div>
</mat-menu>

<ng-template #itemWrapper let-entry="entry" let-layer="layer">
  <div class="item-wrapper"
    [matTooltip]="entry.tooltip ?? ((entry.name | translate).length > 25 ? (entry.name | translate) : null)"
    matTooltipPosition="right"
    [matTooltipDisabled]="!entry.tooltip?.length && (!entry.name || !(entry.name | translate)?.length)"
       role="option" [attr.aria-selected]="false">
    <button mat-menu-item (click)="selectEntry($event, entry)" (keydown)="enterValue($event)"
      [disabled]="entry.disabled && (isFunction(entry.disabled) ? entry.disabled() : true)" [attr.layer]="layer"
      #entryEl [attr.aria-label]="('aria.label.select' | translate) + ' ' + (entry.name | translate)">
      <mat-icon *ngIf="entry.icon && !isLink(entry.icon)" aria-hidden="true">{{
        entry.icon
        }}</mat-icon>
      <img class="icon" *ngIf="entry.icon && isLink(entry.icon)" [src]="entry.icon" [alt]="('alt.iconFor' | translate) + ' ' + (entry.name | translate)" />
      <div class="entry-body">
        <span class="name">{{ entry.name | translate }}</span>
        <span class="details" *ngIf="entry.details">{{entry.details | translate}}</span>
      </div>
    </button>
    <ng-container *ngIf="entry.children && !entry.filteredChildren?.length">
      <ng-container *ngFor="let entry of entry.children">
        <ng-container *ngTemplateOutlet="itemWrapper; context: {entry: entry, layer: layer + 1}"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="entry.filteredChildren?.length">
      <ng-container *ngFor="let entry of entry.filteredChildren">
        <ng-container *ngTemplateOutlet="itemWrapper; context: {entry: entry, layer: layer + 1}"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
