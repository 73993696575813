import { Component, Input } from '@angular/core';
import { MessageSeenStatus } from 'advoprocess';

@Component({
  selector: 'app-read-receipt',
  templateUrl: './read-receipt.component.html',
  styleUrls: ['./read-receipt.component.scss']
})
export class ReadReceiptComponent {
  @Input() status: MessageSeenStatus = MessageSeenStatus.UNSEEN;

  // Make enum available to the template
  MessageSeenStatus = MessageSeenStatus;

  constructor() { }
}
