import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import _ from 'lodash';
import {
  Observable,
  Subject,
  fromEvent,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import {
  FilterCriterium,
  FilterViewPagination,
  TableViewColumnDefinition,
  TableViewGroupDefinition,
  TableViewParametersSortBy,
} from 'src/api';
import { DialogService } from '../dialog/dialog.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { filterTerm } from 'src/app/common/helpers';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import dayjs, { Dayjs } from 'dayjs';
import { captureException } from '@sentry/angular-ivy';
import {
  AvailableFilter,
  ExtendedFilterCriterium,
  ExtendedGroupingCondition,
} from 'advoprocess/lib/types/filter';
import { SettingsService } from 'src/app/common/settings.service';
import {
  defaultOperatorFor,
  queryAvailableFilters,
} from './search-breadcrumbs.component';
import { SelectionModel } from '@angular/cdk/collections';
import {MessageSeenStatus} from "advoprocess";

export interface DataSourceObject {
  update: () => void;
  setLoading: (loading: boolean) => void;
}

function isFilterCriterium(
  inpt: string | FilterCriterium[]
): inpt is FilterCriterium[] {
  return typeof inpt !== 'string';
}

export interface TableAction<T> {
  id: string;
  name: string;
  handler: (element: T, dataSource: DataSourceObject) => void;
  icon?: string | ((element: T) => string);
  disableIf?: (element: T) => boolean;
  hideIf?: (element: T) => boolean;
}

export type ExtendedTableViewColumnDefinition<T> = TableViewColumnDefinition & {
  icon?: (element: T) => string;
  hideLabel?: boolean;
  width?: string;
  fixed?: boolean;
  hideContent?: boolean;
};

export type ExtendedTableViewParameters<T> = {
  displayed_columns?: Array<ExtendedTableViewColumnDefinition<T>>;
  hidden_columns?: Array<ExtendedTableViewColumnDefinition<T>>;
  group?: TableViewGroupDefinition;
  sort_by?: TableViewParametersSortBy;
};

export enum SearchBarDisplayMode {
  NONE = 'NONE',
  SIMPLE = 'SIMPLE',
  FULL = 'FULL',
}

export interface TableConfig<T> {
  id: string;
  title?: string;
  fetch: (params: FilterViewPagination) => Observable<{
    view?: ExtendedTableViewParameters<T>;
    total_entries: number;
    data: T[];
  }>;
  availableFilters?: AvailableFilter[];
  view?: ExtendedTableViewParameters<T>;
  additionalColumns?: (TableViewColumnDefinition & { front?: boolean })[];
  hidePagination?: boolean;
  onRowClick?: (element: T, column: TableViewColumnDefinition) => void;
  onRowDblClick?: (element: T, column: TableViewColumnDefinition) => void;
  actions?: TableAction<T>[];
  searchBarDisplay?: SearchBarDisplayMode;
  autoSearch?: boolean;
  disablePageSizeSelect?: boolean;
  disableColumnChange?: boolean;
  enableNotificationBubbles?: boolean;
  /** Replaces the bubble with a number inside with a custom icon */
  notificationIcon?: string;
  /** Defines a tooltip based on the element that should be shown on hover */
  notificationTooltip?: (element: T, column: TableViewColumnDefinition) => string | null;
  selectionActionBtn?: {
    onClick: Function;
    text: string;
    color: 'primary' | 'accent' | 'warn';
    icon: string;
  },
  selection?: SelectionModel<T>;
  /** enables showing read receipts in specific columns */
  readReceipt?: {
    /** Function to get the read status for an element */
    getStatus: (element: T) => MessageSeenStatus | null;
    /** Which column to show the read receipt in (by internal_name) */
    column: string;
  }
}

export interface FilterSelection {
  label: string | undefined;
  internal_name: string;
  filters: AvailableFilter[];
  parent: FilterSelection | undefined;
}

interface SearchForm {
  query: FormControl<string>;
  type: FormControl<AvailableFilter>;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.1)' }),
        animate('150ms', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('150ms', style({ opacity: 0, transform: 'scale(0.1)' })),
      ]),
    ]),
  ],
})
export class TableComponent implements OnInit {
  @Input() isDraggable: (element: any) => boolean = () => false;
  @Input() isDropZone: (element: any) => boolean = () => false;
  @Output() itemDropped: EventEmitter<{ dragged: any; droppedInto: any }> =
    new EventEmitter();
  @Output() dragChange: EventEmitter<boolean> = new EventEmitter();
  dragging = false;

  dataSource = new MatTableDataSource();
  columnNames: string[];
  allColumns: ExtendedTableViewColumnDefinition<any>[];

  selectedRow;

  @ViewChild('searchField') searchField: ElementRef<HTMLInputElement>;

  isFunction = _.isFunction;

  focusedSettings = undefined;

  searchForm = new FormGroup<SearchForm>({
    query: new FormControl(''),
    type: new FormControl(),
  });

  filters: (ExtendedFilterCriterium & { originalFilter?: AvailableFilter })[] =
    [];

  dragdropIndex: number;

  loading = false;

  viewConfig: Omit<FilterViewPagination, 'view'> & {
    view: ExtendedTableViewParameters<any>;
  } = {
      filter: [],
      pagination: {
        page: 1,
        rows_per_page: 25,
      },
      view: {
        displayed_columns: [],
        group: {},
        sort_by: {},
      },
    };
  totalElements = 0;
  inited = false;

  @Input() config: TableConfig<any>;

  dataSourceObj: DataSourceObject = {
    update: () => {
      this.updateDataSource();
    },
    setLoading: (loading: boolean) => {
      this.loading = loading;
    },
  };

  constructor(
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private translator: TranslateService,
    private activatedRoute: ActivatedRoute,
    private settings: SettingsService,
    private injector: Injector
  ) { }

  ngOnInit(): void {
    this.init();
  }

  private async init() {
    this.inited = false;
    let previousConfig;
    try {
      const realm = this.activatedRoute.snapshot.paramMap.get('realm');
      previousConfig = localStorage.getItem(
        `sf-table-configuration-${realm ?? 'sf'}-${this.config.id}`
      );
    } catch { }
    if (previousConfig) {
      this.viewConfig = JSON.parse(previousConfig);
      this.afterRestoreConfig();
    } else {
      const setting = await this.settings.getSetting(
        `view.tableConfiguration.${this.config.id}`
      );
      if (!_.isObject(setting?.value)) {
        if (this.config.view) {
          this.viewConfig.view = this.config.view;
        }
      } else {
        this.viewConfig = _.cloneDeep(setting.value as any);
        this.afterRestoreConfig();
      }
    }
    if (this.config?.availableFilters?.length) {
      this.searchForm.get('type').setValue(this.config.availableFilters[0]);
      const setFilterParents = (
        filters: AvailableFilter[],
        parent?: AvailableFilter
      ) => {
        filters.forEach((f) => {
          f.parent = parent;
          setFilterParents(f.children ?? [], f);
        });
      };
      setFilterParents(this.config.availableFilters);
    }

    if (!this.config.additionalColumns) {
      this.config.additionalColumns = [];
    }

    if (
      this.config.actions?.length > 0 &&
      !this.config?.additionalColumns?.some(
        (col) => col.internal_name === '_actions(INTERNAL)'
      )
    ) {
      this.config.additionalColumns.push({
        internal_name: '_actions(INTERNAL)',
        display_name: '_actions(INTERNAL)',
      });
    }

    this.updateNotificationBubblesActive();

    this.filters = this.viewConfig?.filter;
    this.updateDataSource();
    this.inited = true;
  }

  updateNotificationBubblesActive() {
    const name = '_notifications(INTERNAL)';
    if (this.config.enableNotificationBubbles) {
      if (this.config.additionalColumns.some(c => c.internal_name === name)) return;
      this.config.additionalColumns.push({
        internal_name: name,
        display_name: name,
        front: true
      });
    } else {
      this.config.additionalColumns = this.config.additionalColumns.filter(c => c.internal_name !== name);
    }
    this.updateColumns();
  }

  private afterRestoreConfig() {
    this.viewConfig.view.displayed_columns.forEach((column, i) => {
      const inputColumn = this.config.view.displayed_columns.find(
        (c2) =>
          column.display_name === c2.display_name &&
          column.internal_name == c2.internal_name
      );
      if (!inputColumn) return;
      this.viewConfig.view.displayed_columns[i] = inputColumn;
    });
    if (
      this.config?.searchBarDisplay === SearchBarDisplayMode.SIMPLE &&
      this.viewConfig.filter?.length
    ) {
      const currentFilterValue = (this.viewConfig.filter[0] as FilterCriterium)
        .value;
      if (!!currentFilterValue?.length) {
        this.searchForm.get('query').setValue(currentFilterValue);
      }
    }
  }

  private updateDataSource(): void {
    this.loading = true;
    this.viewConfig.filter = this.filters;
    this.config.fetch(this.viewConfig).subscribe(
      (data) => {
        this.totalElements = data.total_entries;
        this.dataSource.data = this.wrapInGroups(data.data);
        this.viewConfig.view = data.view;
        this.updateColumns();
        this.loading = false;
      },
      (error) => {
        this.snackBar.open(JSON.stringify(error));
        captureException(error);
        this.loading = false;
      }
    );
  }

  private wrapInGroups(data: any[]) {
    if (!this.viewConfig?.view?.group?.property_name_1) return data;
    return _.flatten(
      Object.entries(
        data.reduce((p, c) => {
          let groupName = c.group;
          if (!groupName) {
            c.group = this.translator.instant('common.label.noGroup');
            groupName = c.group;
          }
          if (!p[groupName]) p[groupName] = [];
          p[groupName].push(c);
          return p;
        }, {})
      ).map((e) => {
        return [
          {
            groupHeader: e[0],
          },
          ...(e[1] as any[]),
        ];
      })
    );
  }

  handleClick(element: any, column: any): void {
    if (this.config.onRowClick) {
      this.config.onRowClick(element, column);
      return;
    }
  }

  handleDblClick(element: any, column: any): void {
    if (this.config.onRowDblClick) {
      this.config.onRowDblClick(element, column);
      return;
    }
  }

  get availableFilters(): AvailableFilter[] {
    return this.config.availableFilters;
  }

  get availableColumns() {
    return this.config.availableFilters.filter(
      (f) =>
        !this.viewConfig?.view?.displayed_columns.some(
          (c) => c.display_name == f.label || c.internal_name == f.internal_name
        )
    );
  }

  get availableGroups() {
    const shouldBeShown = (f: AvailableFilter): boolean => {
      return (
        Boolean(f.grouping_clause) || f.children?.some((c) => shouldBeShown(c))
      );
    };
    return this.config.availableFilters?.filter((f) => {
      return shouldBeShown(f);
    });
  }

  lastResults: MenuEntry<AvailableFilter>[] = [];
  abortQuery$ = new Subject<void>();

  queryColumns(searchTerm: string): Observable<MenuEntry<AvailableFilter>[]> {
    if (!this.availableColumns?.length) return of([]);

    return queryCore(
      searchTerm,
      this.availableColumns,
      this.translator,
      this.injector,
      this.abortQuery$,
      { data: this.lastResults }
    );
  }

  queryFilters(searchTerm: string): Observable<MenuEntry<AvailableFilter>[]> {
    if (!this.availableFilters?.length) return of([]);

    return queryCore(
      searchTerm,
      this.availableFilters,
      this.translator,
      this.injector,
      this.abortQuery$,
      { data: this.lastResults }
    );
  }

  async selectFilter(entry: MenuEntry<AvailableFilter>) {
    const filter = entry.value;
    if (filter.parameters?.length) {
      for (const param of filter.parameters) {
        const paramVal = await this.dialog.prompt(param.label).catch(() => { });
        if (!paramVal) return;
        param.value = paramVal;
      }
    }
    this.searchForm.get('type').setValue(filter);
    if (this.searchField?.nativeElement) {
      this.searchField.nativeElement.focus();
    }
  }

  search() {
    if (this.config.searchBarDisplay === SearchBarDisplayMode.NONE) return;
    if (this.config.searchBarDisplay !== SearchBarDisplayMode.SIMPLE) {
      if (
        _.isNil(this.searchForm.get('query')?.value) ||
        (this.isString(this.searchForm.get('query')?.value) &&
          !this.searchForm.get('query')?.value?.length)
      )
        return;
    }
    const activeFilter = this.searchForm.get('type').value as AvailableFilter;
    let [label, internal_name, group] =
      this.getNameLabelAndGroupFor(activeFilter);
    if (isFilterCriterium(internal_name)) {
      this.filters = this.filters.concat(
        internal_name.map((f) => ({
          ...f,
          icon: activeFilter.icon,
          filterId: activeFilter.id,
        }))
      );
    } else {
      this.filters.push({
        icon: activeFilter.icon,
        value: this.searchForm.get('query').value,
        label,
        operand: internal_name,
        operator: defaultOperatorFor(activeFilter.type),
        filterId: activeFilter.id,
        originalFilter: _.omit(activeFilter, 'parent', 'children'),
      });
    }
    if (this.config.searchBarDisplay === SearchBarDisplayMode.SIMPLE) {
      this.filters = [this.filters[this.filters.length - 1]];
      const currentFilterValue = (this.filters[0] as FilterCriterium).value;
      if (!currentFilterValue?.length) {
        this.filters = [];
      } else {
        this.searchForm.get('query').setValue(currentFilterValue);
      }
    } else {
      this.searchForm.get('query').setValue('');
    }
    // trigger change detection for the search-breadcrumbs component
    this.filters = [...this.filters];

    this.viewConfig.pagination.page = 1;
    this.saveTableConfiguration();
    this.updateDataSource();
  }

  private getNameLabelAndGroupFor(filter: AvailableFilter): [
    string, // the display name a.k.a the label
    string | Array<FilterCriterium>, // the "internal_name" or path
    string | ExtendedGroupingCondition // the group of this filter
  ] {
    return [
      getLabelForFilter(filter),
      getInternalNameForFilter(filter, this.searchForm.get('query').value),
      getGroupForFilter(filter, this.searchForm.get('query').value),
    ];
  }

  sortBy(column: TableViewColumnDefinition) {
    const currentSort = this.viewConfig?.view?.sort_by;
    if (currentSort?.by === column.internal_name) {
      if (currentSort?.direction === 'desc') {
        this.viewConfig.view.sort_by = {};
      } else {
        this.viewConfig.view.sort_by.direction = 'desc';
      }
    } else {
      this.viewConfig.view.sort_by = {
        by: column.internal_name,
        direction: 'asc',
      };
    }
    this.saveTableConfiguration();
    this.updateDataSource();
  }

  async removeColumn(column: ExtendedTableViewColumnDefinition<any>) {
    if (column?.fixed) return;
    const confirm = await this.dialog.confirm({
      text: this.translator.instant('common.message.deleteColumn', {
        columnName: this.translator.instant(column.display_name),
      }),
    });
    if (!confirm) return;
    this.viewConfig.view.displayed_columns.splice(
      this.viewConfig.view.displayed_columns.indexOf(column),
      1
    );
    this.updateColumns();
    this.saveTableConfiguration();
    this.updateDataSource();
  }

  updateColumns() {
    this.allColumns = _.cloneDeep(this.viewConfig.view.displayed_columns);
    for (const col of this.config?.additionalColumns ?? []) {
      if (col.front) {
        this.allColumns.unshift(col);
      } else {
        this.allColumns.push(col)
      }
    }
    this.columnNames = this.allColumns.map((row) => row.internal_name);

    if (this.config.selection && this.dataSource.data.length > 0) {
      this.columnNames.unshift('select');
    }
  }

  async addColumn(entry: MenuEntry<AvailableFilter>) {
    const column = entry.value;
    if (column.parameters?.length) {
      for (const param of column.parameters) {
        const paramVal = await this.dialog.prompt(param.label).catch(() => { });
        if (!paramVal) return;
        param.value = paramVal;
      }
    }
    let [label, internal_name, group] = this.getNameLabelAndGroupFor(column);
    const currentCols = this.viewConfig.view.displayed_columns;
    if (isFilterCriterium(internal_name)) {
      if (
        internal_name.some((internal) =>
          currentCols.some(
            (c) =>
              c.display_name == this.translator.instant(label) ||
              c.internal_name == internal.operand
          )
        )
      )
        return;
      for (const f of internal_name) {
        this.viewConfig.view.displayed_columns.push({
          display_name: label,
          internal_name: f.operand,
        });
      }
    } else {
      if (
        currentCols.some(
          (c) =>
            c.display_name == this.translator.instant(label) ||
            c.internal_name == internal_name
        )
      )
        return;
      this.viewConfig.view.displayed_columns.push({
        display_name: this.translator.instant(label),
        internal_name,
      });
    }
    this.saveTableConfiguration();
    this.updateDataSource();
  }

  cellHasNoContent(element: any, column: TableViewColumnDefinition): boolean {
    const val = element?.[column.display_name];
    if (typeof val === 'boolean') {
      return _.isNil(val);
    }
    if (!!val && (typeof val === 'string' || Array.isArray(val))) {
      return !val.length;
    }
    return !val && val !== 0;
  }

  async setGrouping(entry: MenuEntry<AvailableFilter | null>) {
    const column = entry.value;
    if (!column) {
      this.viewConfig.view.group = undefined;
    } else {
      if (column.parameters?.length) {
        for (const param of column.parameters) {
          const paramVal = await this.dialog
            .prompt(param.label)
            .catch(() => { });
          if (!paramVal) return;
          param.value = paramVal;
        }
      }
      let [label, _, group] = this.getNameLabelAndGroupFor(column);
      if (typeof group == 'string') {
        (this.viewConfig.view.group as ExtendedGroupingCondition) = {
          property_name_1: group,
          label,
        };
      } else {
        this.viewConfig.view.group = group;
      }
    }
    this.saveTableConfiguration();
    this.updateDataSource();
  }

  dropListDropped(event) {
    if (event) {
      moveItemInArray(
        this.viewConfig.view.displayed_columns,
        event.previousIndex,
        event.currentIndex
      );
      this.updateColumns();
      this.saveTableConfiguration();
    }
  }

  saveTableConfiguration() {
    const realm = this.activatedRoute.snapshot.paramMap.get('realm');
    try {
      localStorage.setItem(
        `sf-table-configuration-${realm ?? 'sf'}-${this.config.id}`,
        JSON.stringify({
          ...this.viewConfig,
          filter: this.filters,
        })
      );
    } catch { }
  }

  isGroup(index, item): boolean {
    return item.groupHeader;
  }

  isGroupClosed(element): boolean {
    if (!this.viewConfig?.view?.group?.property_name_1) return false;
    if (!element?.group) return false;
    return (
      this.dataSource.data.find(
        (e: any) => e.groupHeader === element.group
      ) as any
    )?.closed;
  }

  onPaginate() {
    this.config.selection?.clear();
    this.saveTableConfiguration();
    this.updateDataSource();
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    console.log(event);
  }

  isString(s: any): boolean {
    return typeof s === 'string';
  }

  isNil = _.isNil;

  filtersChanged(event: ExtendedFilterCriterium[]) {
    this.filters = event;
    this.saveTableConfiguration();
    this.updateDataSource();
  }

  touchStartTime: Map<EventTarget, Dayjs> = new Map();

  handleTouchStart(event: TouchEvent, row: any) {
    this.touchStartTime.set(event.target, this.currentTime());
    timer(400)
      .pipe(takeUntil(fromEvent(event.target, 'touchend')))
      .subscribe(() => {
        if (this.config.actions?.length) {
          this.focusedSettings = row;
        }
        if (this.config.onRowDblClick) {
          this.selectedRow = row;
          return;
        } else {
          return;
        }
      });
  }

  handleTouchEnd(event: TouchEvent, row: any) {
    const previousStartTime = this.touchStartTime.get(event.target);
    this.touchStartTime.delete(event.target);
    if (event.changedTouches.length > 1) return;
    if (!previousStartTime) {
      return;
    }
    const targetElement = document.elementFromPoint(
      event.changedTouches[0].clientX,
      event.changedTouches[0].clientY
    );
    if (targetElement !== event.target) return;
    if (previousStartTime.diff(dayjs(), 'milliseconds') < -200) {
      return;
    }
    if (this.config.onRowClick) {
      this.handleClick(row, null);
    } else if (this.config.onRowDblClick) {
      this.handleDblClick(row, null);
    }
  }

  handleRowBlur(event: Event, row: any) {
    if (this.focusedSettings === row) {
      this.focusedSettings = undefined;
    }
  }

  currentTime() {
    return dayjs();
  }

  dragStart(event: DragEvent, item: any) {
    event.dataTransfer?.setData('application/json', JSON.stringify(item));
    event.dataTransfer!.effectAllowed = 'move';
    this.dragging = true;
    this.dragChange.emit(this.dragging);
  }

  dragEnd(event: DragEvent) {
    this.dragging = false;
    this.dragChange.emit(this.dragging);
  }

  dragOver(event: DragEvent) {
    event.preventDefault();

    if (this.isElementADropZone(event.target as HTMLElement)) {
      event.dataTransfer!.effectAllowed = 'move';
      event.dataTransfer!.dropEffect = 'move';
    } else {
      event.dataTransfer!.effectAllowed = 'none';
      event.dataTransfer!.dropEffect = 'none';
    }
  }

  drop(event: DragEvent, droppedInto: any) {
    const dragged = JSON.parse(event.dataTransfer?.getData('application/json'));
    if (this.isDropZone(droppedInto)) {
      this.itemDropped.emit({ dragged, droppedInto });
    }
  }

  isElementADropZone(element: HTMLElement) {
    while (element && element.nodeName !== 'TABLE') {
      if (element.classList && element.classList.contains('dropzone')) {
        return true;
      }
      element = element.parentNode as HTMLElement;
    }
    return false;
  }

  dueAtChanged(
    event:
      | {
        date: Date;
        durationMinutes?: number;
      }
      | undefined
  ) {
    this.searchForm
      .get('query')
      .setValue(dayjs(event?.date).format('YYYY-MM-DD HH:mm'));
  }

  elementHasNotification(el: any) {
    return Object.keys(el).indexOf('notifications') !== -1;
  }

  isAllSelected(): boolean {
    return this.config.selection?.selected.length === this.dataSource.data.length && this.dataSource.data.length > 0;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.config.selection?.clear();
    } else {
      this.dataSource.data.forEach(row => this.config.selection?.select(row));
    }
  }
}

export function getParameters(f: AvailableFilter): { [key: string]: string } {
  const own =
    f.parameters?.reduce((p, c) => {
      p[c.name] = c.value;
      return p;
    }, {}) ?? {};
  if (f.parent) {
    const p = getParameters(f.parent);
    return { ...p, ...own };
  } else {
    return { ...own };
  }
}

export function getLabelForFilter(filter: AvailableFilter): string {
  const paramObj = getParameters(filter);
  let label: string;
  if (_.isFunction(filter.label)) {
    label = filter.label(paramObj);
  } else {
    label = filter.label;
  }
  return label;
}

export function getInternalNameForFilter(
  filter: AvailableFilter,
  searchValue: string
): string | Array<FilterCriterium> {
  const paramObj = getParameters(filter);
  let internal_name;
  if (_.isFunction(filter.internal_name)) {
    internal_name = filter.internal_name(searchValue, paramObj);
  } else {
    if (filter.parent) {
      const parentName = getInternalNameForFilter(filter.parent, searchValue);
      internal_name = `${parentName}.${filter.internal_name}`;
    } else {
      internal_name = filter.internal_name;
    }
  }

  return internal_name;
}

export function getGroupForFilter(
  filter: AvailableFilter,
  searchValue: string
): string | ExtendedGroupingCondition {
  const paramObj = getParameters(filter);
  let group;

  if (_.isFunction(filter.grouping_clause)) {
    group = filter.grouping_clause(searchValue, paramObj);
  } else {
    if (filter.parent) {
      const parentName = getGroupForFilter(filter.parent, searchValue);
      group = `${parentName}.${filter.grouping_clause}`;
    } else {
      group = filter.grouping_clause;
    }
  }
  return group;
}

export function filterToMenuEntry(
  f: AvailableFilter
): MenuEntry<AvailableFilter> {
  return {
    name: getLabelForFilter(f),
    icon: f.icon ?? '',
    value: f,
    children: f.children
      ? f.children.map((f2) => {
        return filterToMenuEntry(f2);
      })
      : [],
  };
}

export function getDefaultFilterValue(filter: AvailableFilter): any {
  let searchValue: any = '';
  switch (filter.type) {
    case 'boolean':
      searchValue = true;
      break;
    case 'date':
      searchValue = dayjs().toDate();
      break;
    case 'number':
      searchValue = 0;
    default:
      searchValue = '';
  }
  return searchValue;
}

export function queryCore(
  searchTerm: string,
  availableFilters: AvailableFilter[],
  translator: TranslateService,
  injector: Injector,
  abortQuery$: Subject<void>,
  lastResults: { data: MenuEntry<AvailableFilter>[] }
): Observable<MenuEntry<AvailableFilter>[]> {
  abortQuery$.next();
  return timer(1000).pipe(
    switchMap(() =>
      queryAvailableFilters(availableFilters, searchTerm, injector)
    ),
    map((entries) =>
      entries.filter((entry) => {
        return filterTerm(searchTerm, entry, translator);
      })
    ),
    tap((x) => (lastResults.data = x)),
    startWith(
      lastResults.data.filter((e) => filterTerm(searchTerm, e, translator))
    ),
    takeUntil(abortQuery$)
  );
}
