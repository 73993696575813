import { Subject, Observable, merge } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { NotificationType } from 'src/api';

export interface PushNotification {
    dossierName: string;
    threadName: string;
    route: string;
    isSameDossier: boolean;
    notificationAction: string;
    meta: any;
}

export class PushNotificationManager {
    private static debounceTimeConfig = {
        newMessage: 2000,
        newMention: 2000,
        participantAdded: 2000,
        participantRemoved: 2000,
        fileUpdated: 5000
    }

    private static newMessageNotification$ = new Subject<PushNotification>();
    private static newMentionNotification$ = new Subject<PushNotification>();
    private static participantAddedNotification$ = new Subject<PushNotification>();
    private static participantRemovedNotification$ = new Subject<PushNotification>();
    private static fileUpdatedNotification$ = new Subject<PushNotification>();

    static get onNewMessageNotification$(): Observable<PushNotification> {
        return this.newMessageNotification$.asObservable().pipe(
            debounceTime(this.debounceTimeConfig.newMessage)
        );
    }

    static get onNewMentionNotification$(): Observable<PushNotification> {
        return this.newMentionNotification$.asObservable().pipe(
            debounceTime(this.debounceTimeConfig.newMention)
        );
    }

    static get onParticipantAddedNotification$(): Observable<PushNotification> {
        return this.participantAddedNotification$.asObservable().pipe(
            debounceTime(this.debounceTimeConfig.participantAdded)
        );
    }

    static get onParticipantRemovedNotification$(): Observable<PushNotification> {
        return this.participantRemovedNotification$.asObservable().pipe(
            debounceTime(this.debounceTimeConfig.participantRemoved)
        );
    }

    static get onFileUpdatedNotification$(): Observable<PushNotification> {
        // don't show push notifications for no_access and deleted files
        return this.fileUpdatedNotification$.asObservable().pipe(
            filter((notification) => !(notification.notificationAction.includes('no_access') || notification.notificationAction.includes('deleted'))),
            debounceTime(this.debounceTimeConfig.fileUpdated)
        );
    }

    static buildCombinedNotificationStream(): Observable<PushNotification> {
        return merge(
            this.onNewMessageNotification$,
            this.onNewMentionNotification$,
            this.onParticipantAddedNotification$,
            this.onParticipantRemovedNotification$,
            this.onFileUpdatedNotification$
        );
    }

    static dispatchNotification(notification: PushNotification): void {
        switch (notification.meta.notificationType) {
            case NotificationType.NewMessage:
                this.newMessageNotification$.next(notification);
                break;
            case NotificationType.NewMention:
                this.newMentionNotification$.next(notification);
                break;
            case NotificationType.ParticipantAdded:
                this.participantAddedNotification$.next(notification);
                break;
            case NotificationType.ParticipantRemoved:
                this.participantRemovedNotification$.next(notification);
                break;
            case NotificationType.FileUpdated:
                this.fileUpdatedNotification$.next(notification);
                break;
        }
    }
}