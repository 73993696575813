import { saveAs } from 'file-saver';
import { asBlob } from 'html-docx-js-typescript';

export function downloadAsDocX(
  el: HTMLElement,
  document: { value: string; options?: any }
): Promise<void> {
  return new Promise(async (resolve) => {
    const { convertMillimetersToTwip } = await import('docx');
    const buffer = el.innerHTML;
    applyComputedStylesAsInline(el);
    asBlob(
      `<html>
    <head>
      <meta charset="UTF-8">
    </head>
    <body>
    <div style="position: absolute; width: 100%; height: 100%; background: red">
    </div>
    ${el.innerHTML}</body>
  </html>`,
      document.options?.margins
        ? {
            margins: {
              top: convertMillimetersToTwip(document.options.margins[0] ?? 20),
              left: convertMillimetersToTwip(document.options.margins[1] ?? 20),
              bottom: convertMillimetersToTwip(
                document.options.margins[2] ?? 20
              ),
              right: convertMillimetersToTwip(
                document.options.margins[3] ?? 20
              ),
            },
          }
        : {}
    ).then(async (data) => {
      saveAs(data, 'test.docx');
      el.innerHTML = buffer;
      resolve();
    });
  });
}

function applyComputedStylesAsInline(element) {
  // Get all children of the element
  const children = element.children;

  // Loop through each child and apply computed styles as inline styles
  for (const child of children) {
    const computedStyles = getComputedStyle(child);
    const inlineStyle = Array.from(computedStyles)
      .map((prop) => {
        const value = computedStyles.getPropertyValue(prop);
        return `${prop}:${value}`;
      })
      .join(';');

    child.setAttribute('style', inlineStyle);
    if (child.children) {
      applyComputedStylesAsInline(child);
    }
  }
}
