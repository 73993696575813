import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExecutionStateParticipant } from 'src/api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-peek-overlay',
  templateUrl: './user-peek-overlay.component.html',
  styleUrls: ['./user-peek-overlay.component.scss'],
})
export class UserPeekOverlayComponent implements OnInit {
  @Input() user: ExecutionStateParticipant;

  loading = false;

  API_URL = environment.API_URL;

  constructor(
    public el: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  viewUserDetails() {
    this.router.navigate([
      this.realm,
      'intern',
      'clients',
      this.user.source,
      this.user.id,
    ]);
  }

  get realm(): string {
    return (
      this.activatedRoute.snapshot.paramMap.get('realm') ??
      this.activatedRoute.firstChild.snapshot.paramMap.get('realm')
    );
  }
}
