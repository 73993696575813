import { OnDestroy, Directive } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Directive()
export abstract class DestroyNotifier implements OnDestroy {
  private _destroy$: Subject<void>;
  destroy$: Observable<void>;

  constructor() {
    this._destroy$ = new Subject<void>();
    this.destroy$ = this._destroy$.asObservable();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
