<div class="kc-overlay" *ngIf="keycloakLoginUrl" role="dialog" aria-modal="true" [attr.aria-label]="'aria.label.KeycloakLoginDialog' | translate">
  <div class="kc-dialog" [@fadeAnimation]>
    <div class="header">
      <button mat-icon-button (click)="close.emit()" [attr.aria-label]="'aria.label.closeLoginDialog' | translate">
        <mat-icon aria-hidden="true">close</mat-icon>
      </button>
    </div>
    <iframe [src]="keycloakLoginUrl" (load)="iframeLoad($event)" title="Keycloak Login Form" name="keycloak-login-frame"></iframe>
  </div>
</div>
