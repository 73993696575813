import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Optional,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ProcessNode } from 'advoprocess';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import { Observable } from 'rxjs';
import _ from 'lodash';

export type EditDataConfig = {
  value: any;
  type: string;
  name: string;
  tooltip?: string;
  options?: { name: string; value: any }[];
  validator?: (input: string, dataSource: any) => string;
  queryEntities?: (
    searchTerm: string,
    currentValue: any
  ) => Observable<MenuEntry<any>[]>;
  required?: boolean | ((currentConfig: EditDataType) => boolean);
  hideIf?: (currentConfig: EditDataType) => boolean;

  /**
   * Only relevant for type === 'join'. Specifies that only a single element can
   * be joined at any time. Results in a dropdown selection.
   */
  singleChoice?: boolean;
};

export type EditDataType = {
  [key: string]: EditDataConfig;
};

export interface DialogConfig {
  title: string;
  content: string;
  actions?: DialogAction[];
  type?: 'confirm' | 'prompt' | 'alert' | 'edit';
  compInput?: boolean;
  placeholder?: string;
  value?: any;
  validator?: (value: string) => boolean | string;
  dataRef?: ProcessNode[];
  allowRichText?: boolean;
  editData?: EditDataType;
}

export interface DialogAction {
  text: string;
  action?: (dialogRef: MatDialogRef<DialogComponent>) => void;
  icon?: string;
  color?: string;
  raised?: boolean;
  accept?: boolean;
  disableIf?: boolean;
  defaultSelect?: boolean;
  value?: any;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements AfterViewInit {
  dataField: any;

  objectKeys = Object.keys;

  promptValidated: boolean | string = true;

  constructor(
    @Optional() public dialogRef: MatDialogRef<DialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogConfig,
    private el: ElementRef
  ) {
    if (data.value) {
      this.dataField = data.value;
      this.updateValidation();
    }
  }

  ngAfterViewInit(): void {
    if (this.data.value) {
      setTimeout(() => {
        this.el.nativeElement?.querySelector('.main-text-input')?.select();
      }, 50);
    }
  }

  updateValidation() {
    if (!this.data?.validator) {
      this.promptValidated = true;
      return;
    }
    this.promptValidated = this.data.validator(this.dataField);
  }

  closeDialog(button: DialogAction): void {
    if (button.action) {
      button.action(this.dialogRef);
    } else if (this.data.type === 'prompt' && button.accept) {
      this.dialogRef.close(this.dataField);
    } else if (this.data.type === 'confirm' && button.accept) {
      this.dialogRef.close(
        button.value !== null && button.value !== undefined
          ? button.value
          : true
      );
    } else if (this.data.type === 'confirm' && !button.accept) {
      this.dialogRef.close(false);
    } else if (this.data.type === 'edit' && button.accept) {
      this.dialogRef.close(this.data.editData);
    } else {
      this.dialogRef.close(null);
    }
  }

  submit(): void {
    if (!this.dataField || this.dataField.length <= 0) {
      return;
    }
    this.dialogRef.close(this.dataField);
  }

  editNode(): void {
    if (!this.data.dataRef) {
      return;
    }
  }

  get disableSubmit(): boolean {
    switch (this.data.type) {
      case 'prompt':
        return (
          !this.dataField ||
          this.dataField.length <= 0 ||
          (this.data.validator && this.promptValidated !== true)
        );
      case 'edit':
        return Object.keys(this.data.editData).some(
          (key) =>
            this.data.editData[key].type !== 'info' &&
            this.isRequired(this.data.editData[key]) &&
            (!this.data.editData[key].hideIf ||
              !this.data.editData[key].hideIf(this.data.editData)) &&
            (!this.data.editData[key].value ||
              this.data.editData[key].value.length <= 0 ||
              (this.data.editData[key].validator !== null &&
              this.data.editData[key].validator !== undefined
                ? this.data.editData[key].validator(
                    this.data.editData[key].value ?? '',
                    this.data.editData
                  ) ?? false
                : false))
        );
      default:
        return false;
    }
  }

  removeJoinEntry(list: MenuEntry<any>[], entry: MenuEntry<any>) {
    list.splice(list.indexOf(entry), 1);
  }

  private isRequired(data: EditDataConfig): boolean {
    return data.required
      ? _.isFunction(data.required)
        ? data.required(this.data.editData)
        : data.required
      : false;
  }

  queryEntitiesWrapper(
    key: string
  ): (searchTerm: string) => Observable<MenuEntry<any>[]> {
    return (searchTerm: string) => {
      return this.data.editData[key].queryEntities(
        searchTerm,
        this.data.editData[key].value
      );
    };
  }
}
