import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  filterToMenuEntry,
  getDefaultFilterValue,
  getInternalNameForFilter,
  getLabelForFilter,
} from './table.component';
import { BooleanOperator, FilterCriterium } from 'src/api';
import {
  filterTerm,
  isBooleanOperator,
  isFilterCriterium,
} from 'src/app/common/helpers';
import { TranslateService } from '@ngx-translate/core';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import {
  Observable,
  Subject,
  map,
  mergeMap,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
  timer,
  toArray,
} from 'rxjs';
import { DialogService } from '../dialog/dialog.service';
import {
  AvailableFilter,
  ExtendedFilterCriterium,
  FilterOperatorEnum,
} from 'advoprocess/lib/types/filter';
import * as _ from 'lodash';

export function defaultOperatorFor(
  type: AvailableFilter['type']
): FilterOperatorEnum {
  switch (type) {
    case 'boolean':
      return 'eq';
    case 'date':
      return 'greaterThan';
    default:
      return 'contains';
  }
}

@Component({
  selector: 'app-search-breadcrumbs',
  templateUrl: './search-breadcrumbs.component.html',
  styleUrls: ['./search-breadcrumbs.component.scss'],
})
export class SearchBreadcrumbsComponent implements OnInit {
  @Input() filters: ExtendedFilterCriterium[] = [];

  @Input() availableFilters?: AvailableFilter[] = undefined;

  @Input() initialJoinString: string = '';

  @Output() filtersChanged = new EventEmitter<ExtendedFilterCriterium[]>();

  booleanOperatorMenuEntries: MenuEntry<BooleanOperator | AvailableFilter>[] = [
    {
      name: 'common.label.and',
      details: 'common.message.andExplanation',
      value: {
        operator: 'and',
        filters: [],
      },
      group: {
        name: 'common.label.operators.title',
        priority: 10,
      },
    },
    {
      name: 'common.label.or',
      details: 'common.message.orExplanation',
      value: {
        operator: 'or',
        filters: [],
      },
      group: {
        name: 'common.label.operators.title',
        priority: 10,
      },
    },
  ];

  availableOperators = Object.values(FilterCriterium.OperatorEnum);

  constructor(
    private translator: TranslateService,
    private dialog: DialogService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    if (this.availableFilters?.length) {
      const setFilterParents = (
        filters: AvailableFilter[],
        parent?: AvailableFilter
      ) => {
        filters.forEach((f) => {
          f.parent = parent;
          setFilterParents(f.children ?? [], f);
        });
      };
      setFilterParents(this.availableFilters);
    }
  }

  removeFilter(filters: ExtendedFilterCriterium[], filter: FilterCriterium) {
    filters.splice(filters.indexOf(filter), 1);
    const cleanup = (filters: ExtendedFilterCriterium[]) => {
      if (!filters.length) return;
      let emptyBoolIndex = filters.findIndex(
        (s) => isBooleanOperator(s) && !s.filters?.length
      );
      while (emptyBoolIndex !== -1) {
        filters.splice(emptyBoolIndex, 1);
        emptyBoolIndex = filters.findIndex(
          (s) => isBooleanOperator(s) && !s.filters?.length
        );
      }
      filters
        .filter((f) => isBooleanOperator(f))
        .forEach((b: BooleanOperator) => cleanup(b.filters));
    };
    cleanup(this.filters);
    this.filtersChanged.emit(this.filters);
  }

  isBoolean(b: any): boolean {
    return typeof b === 'boolean';
  }

  getLabelForFilter = getLabelForFilter;

  isFilterCriterium(f: ExtendedFilterCriterium) {
    return isFilterCriterium(f);
  }

  operatorSignFor(f: FilterCriterium) {
    return this.translator.instant('common.label.operators.' + f.operator);
  }

  lastResults: MenuEntry<AvailableFilter | BooleanOperator>[] = [];
  abortQuery$ = new Subject<void>();

  queryFilters(
    searchTerm: string
  ): Observable<MenuEntry<AvailableFilter | BooleanOperator>[]> {
    this.abortQuery$.next();
    return timer(1000).pipe(
      switchMap(() =>
        queryAvailableFilters(this.availableFilters, searchTerm, this.injector)
      ),
      map((entries) => {
        return (entries as MenuEntry<AvailableFilter | BooleanOperator>[])
          .concat(this.booleanOperatorMenuEntries)
          .filter((entry) => {
            return filterTerm(searchTerm, entry, this.translator);
          });
      }),
      tap((x) => (this.lastResults = x)),
      startWith(
        this.lastResults.filter((e) =>
          filterTerm(searchTerm, e, this.translator)
        )
      ),
      takeUntil(this.abortQuery$)
    );
  }

  async addFilter(
    filters: (ExtendedFilterCriterium & { originalFilter?: AvailableFilter })[],
    entry: MenuEntry<AvailableFilter | BooleanOperator>
  ) {
    const filter = entry.value;
    if (isBooleanOperator(filter)) {
      filters.push(filter);
      return;
    }
    const label = getLabelForFilter(filter);
    const searchValue = getDefaultFilterValue(filter);
    if (filter.parameters?.length) {
      for (const param of filter.parameters) {
        const paramVal = await this.dialog.prompt(param.label).catch(() => {});
        if (!paramVal) return;
        param.value = paramVal;
      }
    }
    const internal_name = getInternalNameForFilter(filter, searchValue);
    if (typeof internal_name !== 'string') {
      internal_name
        .map((f) => ({
          ...f,
          icon: filter.icon,
          filterId: filter.id,
        }))
        .forEach((f) => {
          filters.push(f);
        });
    } else {
      filters.push({
        icon: filter.icon,
        value: searchValue,
        label,
        operand: internal_name,
        operator: defaultOperatorFor(filter.type),
        filterId: filter.id,
        originalFilter: _.omit(filter, 'parent', 'children'),
      });
    }
    this.filtersChanged.emit(this.filters);
  }

  async updateFilter(
    filters: ExtendedFilterCriterium[],
    filter: ExtendedFilterCriterium,
    entry: MenuEntry<AvailableFilter>
  ) {
    if (!isFilterCriterium(filter)) return;
    const newFilter = entry.value;
    const label = getLabelForFilter(newFilter);
    let searchValue = filter.value;
    if (newFilter.type !== this.availableFilterFor(filter)?.type) {
      searchValue = getDefaultFilterValue(newFilter);
    }
    if (newFilter.parameters?.length) {
      for (const param of newFilter.parameters) {
        const paramVal = await this.dialog.prompt(param.label).catch(() => {});
        if (!paramVal) return;
        param.value = paramVal;
      }
    }
    const internal_name = getInternalNameForFilter(newFilter, searchValue);
    let newFilters: ExtendedFilterCriterium[] = [];
    if (typeof internal_name !== 'string') {
      newFilters = internal_name.map((f) => ({
        ...f,
        icon: filter.icon,
        filterId: newFilter.id,
      }));
    } else {
      newFilters.push({
        ...filter,
        label,
        operand: internal_name,
      });
    }
    filters.splice(filters.indexOf(filter), 1, ...newFilters);
    this.filtersChanged.emit(this.filters);
  }

  availableFilterFor(
    f: ExtendedFilterCriterium & { originalFilter?: AvailableFilter }
  ): AvailableFilter | undefined {
    if (!this.availableFilters || !isFilterCriterium(f)) return undefined;

    if (f.originalFilter) {
      return f.originalFilter;
    }

    const recursiveSearch = (filters: AvailableFilter[]) => {
      for (const available of filters) {
        if (
          available.id === f.filterId ||
          available.internal_name === f.operand
        ) {
          return available;
        }
        const inChildren = recursiveSearch(available.children ?? []);
        if (inChildren) return inChildren;
      }
      return undefined;
    };

    return recursiveSearch(this.availableFilters);
  }

  getPossibleOperations(
    filter: AvailableFilter
  ): FilterCriterium.OperatorEnum[] {
    switch (filter?.type) {
      case 'boolean':
        return ['eq', 'ne'];
      case 'date':
        return ['greaterThan', 'lessThan'];
      case 'number':
        return [
          'eq',
          'ne',
          'includedIn',
          'notIncludedIn',
          'greaterThan',
          'lessThan',
        ];
      case 'string':
        return [
          'contains',
          'eq',
          'containsNot',
          'includedIn',
          'ne',
          'notIncludedIn',
          'startsWith',
        ];
      default:
        return Object.values(FilterCriterium.OperatorEnum);
    }
  }

  dueAtChanged(
    filter: FilterCriterium,
    event:
      | {
          date: Date;
          durationMinutes?: number;
        }
      | undefined
  ) {
    if (!event) return;
    filter.value = event.date;
  }
}

export function queryAvailableFilters(
  filters: AvailableFilter[],
  searchTerm: string,
  injector: Injector
): Observable<MenuEntry<AvailableFilter>[]> {
  if (!filters?.length) return of([]);

  const recursiveReset = (filters: AvailableFilter[]) => {
    filters?.forEach((f) => {
      f.parameters?.forEach((p) => (p.value = undefined));
      recursiveReset(f.children ?? []);
    });
  };
  recursiveReset(filters);

  return of(
    filters.map((f) => {
      if (!!f.fetch) {
        return f.fetch(searchTerm, injector).pipe(map((data) => [...data, f]));
      } else {
        return of([f]);
      }
    })
  ).pipe(
    mergeMap((o) => o),
    mergeMap((o) => o),
    toArray(),
    map((o) => _.flatten(o.map((o) => o.map((o) => filterToMenuEntry(o)))))
  );
}
