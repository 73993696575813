import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import { Subject } from "rxjs";

@Pipe({ name: 'videoProgress' })
export class videoProgressTimePipe implements PipeTransform {
  constructor() {
    dayjs.extend(duration);
  }

  transform(seconds: number): string {
    return dayjs.duration(seconds, 'seconds').format('mm:ss');
  }
}

export class VideoProgressBarHandler {

  public video: HTMLVideoElement;

  private startX: number = -1;

  public positionChange$ = new Subject<number>();

  public wasPaused: boolean = false;

  private getPositionOnBar(event): number {
    const posX = event.clientX;
    const targetObj = event.target.closest('.playbar');
    const targetRect = targetObj.getBoundingClientRect();

    return Math.min(Math.max((posX - targetRect.left) / targetRect.width, 0), 1);
  }

  pointerDown(event: PointerEvent) {
    if (!this.video) return;
    const pos = this.getPositionOnBar(event);
    this.startX = pos;
    this.wasPaused = this.video.paused;
    this.video.pause();
  }

  pointerUp(event: PointerEvent) {
    if (!this.video) return;
    if (this.startX === -1) return;
    const pos = this.getPositionOnBar(event);
    this.video.currentTime = pos * this.video.duration;
    this.startX = -1;
    if (!this.wasPaused) {
      this.video.play();
      this.wasPaused = false;
    }
  }

  pointerMove(event: PointerEvent) {
    if (!this.video) return;
    if (this.startX === -1) return;
    const pos = this.getPositionOnBar(event);
    this.startX = pos;
    this.positionChange$.next(pos);
  }

}