<div>
  <div class="tooltip-conatiner">
    <ng-template #simpleText>
      {{ text }}
    </ng-template>
    <ng-container
      [ngTemplateOutlet]="contentTemplate || simpleText"
      [ngTemplateOutletContext]="templateContext"
    >
    </ng-container>
  </div>
</div>
