<div class="comparison-input">
  <div [matTooltip]="'Knoten bearbeiten'" (click)="editNode.emit(condition.refId)" class="desc-icon"
    [class.found]="found[0]" role="button" tabindex="0" [attr.aria-label]="'aria.label.editNode' | translate" (keyup.enter)="editNode.emit(condition.refId)">
    #
  </div>
  <input matInput type="text" [class.found]="found[0]" [matAutocomplete]="refAuto" [(ngModel)]="condition.refId"
    (ngModelChange)="emitChange()" [attr.aria-label]="'aria.label.conditionReferenceId' | translate" />
  <input matInput type="text" [class.found]="found[1]" [matAutocomplete]="refOperators" [(ngModel)]="condition.operator"
    (ngModelChange)="emitChange()" [attr.aria-label]="'aria.label.conditionOperator' | translate" />
  <input matInput type="text" [class.found]="found[2]" [matAutocomplete]="refValue" [(ngModel)]="condition.value"
    (ngModelChange)="emitChange()" [attr.aria-label]="'aria.label.conditionValue' | translate" />
  <div class="delete-button" *ngIf="canBeDeleted" (click)="deleted.emit()" role="button" tabindex="0" [attr.aria-label]="'aria.label.deleteCondition' | translate" (keyup.enter)="deleted.emit()">
    <mat-icon aria-hidden="true">delete</mat-icon>
  </div>
</div>
<mat-autocomplete #refAuto="matAutocomplete">
  <mat-option *ngFor="let tag of availableTags" [value]="tag">
    {{ tag }}
  </mat-option>
  <mat-option (click)="addNodeToDataSource(condition.refId); emitChange()" [value]="condition.refId"
    *ngIf="!availableTags.includes(condition.refId)"><mat-icon aria-hidden="true">add</mat-icon>#{{ condition.refId }}
    hinzufügen</mat-option>
</mat-autocomplete>
<mat-autocomplete #refOperators="matAutocomplete">
  <mat-option *ngFor="let operator of operators()" [value]="operator">
    {{ operator }}
  </mat-option>
</mat-autocomplete>
<mat-autocomplete #refValue="matAutocomplete">
  <ng-container *ngIf="isValueDynamic; else staticValue">
    <mat-option *ngFor="let tag of availableTagsForValue" [value]="tag">
      {{ tag }}
    </mat-option>
    <mat-option (click)="addNodeToDataSource(getTrimmedValue()); emitChange()" [value]="getTrimmedValue()"
      *ngIf="!availableTagsForValue.includes('#' + getTrimmedValue())"><mat-icon aria-hidden="true">add</mat-icon>#{{ getTrimmedValue() }}
      hinzufügen</mat-option>
  </ng-container>
  <ng-template #staticValue>
    <mat-option *ngFor="let value of possibleValues" [value]="value.value">
      {{ value.value }}
    </mat-option>
  </ng-template>
</mat-autocomplete>
