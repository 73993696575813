import { Component, EventEmitter, Input, Output } from '@angular/core';
import { icons } from './icon-list';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
})
export class IconPickerComponent {
  @Input() value: string = '';

  @Output() valueChange = new EventEmitter<string>();

  @Input() label: string;

  searchTerm = '';

  constructor() {}

  selectIcon(iconName: string) {
    this.value = iconName;
    this.valueChange.emit(this.value);
  }

  get allIcons(): string[] {
    const sm = this.searchTerm?.toLowerCase()?.trim();
    return icons.filter(
      (icon) => !sm?.length || icon.includes(sm) || sm.includes(icon)
    );
  }
}
