import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatLegacyMenu,
  MatLegacyMenuTrigger as MatMenuTrigger,
} from '@angular/material/legacy-menu';
import { TranslateService } from '@ngx-translate/core';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import _ from 'lodash';
import { Observable, isObservable } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { filterTerm } from 'src/app/common/helpers';

@Component({
  selector: 'app-search-menu',
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.scss'],
})
export class SearchMenuComponent implements OnInit, AfterViewInit {
  @Input() entries:
    | MenuEntry<any>[]
    | ((searchTerm: string) => Observable<MenuEntry<any>[]>);
  @Input() asSelectionDropdown: boolean = false;
  @Input() skipCloseOnSelect: boolean = false;
  @Input() hideSearchBar = false;

  @Output() entrySelected = new EventEmitter<MenuEntry<any>>();

  @ViewChild('trigger', { static: true }) menuTriggerContainer: ElementRef;
  @ViewChild('hiddenTrigger', { static: true }) hiddenTrigger: MatMenuTrigger;
  @ViewChild('searchInput') searchInput: ElementRef;

  @ViewChildren('entryEl', { read: ElementRef })
  entryEls: QueryList<ElementRef>

  searchControl = new FormControl<string | undefined>(undefined);

  activeEntries: MenuEntry<any>[] = [];

  loading = false;

  isFunction = _.isFunction;

  constructor(private translator: TranslateService) { }

  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe(() => {
      this.updateList();
    });
  }

  ngAfterViewInit(): void {
    const triggerButton = this.menuTriggerContainer.nativeElement.querySelector(
      'button, .menu-trigger'
    );
    triggerButton?.addEventListener('click', this.onEvent.bind(this));
    triggerButton?.addEventListener('keyup', (event) => {
      if (event.key !== 'Enter') return;
      this.onEvent();
    });
    this.hiddenTrigger.menuOpened.subscribe(() => {
      if (!this.asSelectionDropdown) return;
      const triggerWidth = triggerButton.offsetWidth;
      // Use CDK overlay to get the menu panel element
      const searchMenu = document.querySelector(
        '.sf-search-menu'
      ) as HTMLElement;
      const overlayPane = searchMenu?.parentElement;

      if (overlayPane) {
        // Set the menu panel width to match the trigger element width
        overlayPane.style.width = `${triggerWidth}px`;
      }
    });
    this.hiddenTrigger.menuClosed.subscribe(() => {
      setTimeout(() => {
        const triggerButton = this.menuTriggerContainer.nativeElement.querySelector(
          'button, .menu-trigger'
        );
        triggerButton?.focus();
      }, 250);
    });
  }

  private onEvent() {
    this.hiddenTrigger.openMenu();
    this.searchControl.setValue('');
    this.updateList();

    if (this.searchInput?.nativeElement && !this.hideSearchBar) {
      this.searchInput.nativeElement.focus();
    } else {
      setTimeout(() => this.focusNextElement(), 50);
    }
  }

  private wrapInGroups(data: MenuEntry<any>[]): MenuEntry<any>[] {
    if (!data.some((e) => !!e.group)) return data;
    return _.flatten(
      Object.entries(
        data.reduce((p, c) => {
          let group = c.group;
          if (!group) {
            c.group = { name: '' };
            group = c.group;
          }
          if (!p[group.name]) p[group.name] = [];
          p[group.name].push(c);
          return p;
        }, {})
      )
        .sort((a: [string, MenuEntry<any>], b: [string, MenuEntry<any>]) => {
          // Sort by priority
          const priorityA = a?.[1]?.[0]?.group?.priority ?? 0;
          const priorityB = b?.[1]?.[0]?.group?.priority ?? 0;

          if (priorityA === priorityB || a[0] === '' || b[0] === '') {
            // Sort by name
            return a[0] >= b[0] ? 1 : -1;
          } else {
            return priorityB - priorityA;
          }
        })
        .map((e) => {
          return [
            {
              isGroupHeader: true,
              name: e[0],
              icon: e[1]?.[0]?.group?.icon,
            },
            ...(e[1] as any[]),
          ];
        })
    );
  }

  private updateList() {
    if (_.isFunction(this.entries)) {
      const ret = this.entries(this.searchControl.value);
      if (isObservable(ret)) {
        this.loading = true;
        ret
          .pipe(
            finalize(() => {
              this.loading = false;
            })
          )
          .subscribe((entries) => {
            this.activeEntries = this.wrapInGroups(entries);
            window.dispatchEvent(new Event('resize'));
          });
      } else {
        this.activeEntries = this.wrapInGroups(ret);
        window.dispatchEvent(new Event('resize'));
      }
    } else {
      this.activeEntries = this.wrapInGroups(
        this.entries.filter((entry) => {
          return filterTerm(this.searchControl?.value, entry, this.translator);
        })
      );
      window.dispatchEvent(new Event('resize'));
    }
  }

  selectEntry(event: PointerEvent, entry: MenuEntry<any>) {
    if (this.skipCloseOnSelect) event.stopPropagation();
    this.entrySelected.emit(entry);
    if (this.skipCloseOnSelect) {
      this.updateList();
    }
  }

  /**
   * Handle keyboard navigation
   * 
   * @param event DOM event
   * @param fromEntry Are we called from an actual entry? If so, pressing escape results in
   * returning focus to the search input
   */
  enterValue(event, fromEntry: boolean = true) {
    if (event.key === 'ArrowDown' || event.key === 'Tab') {
      //If arrow down is pressed, focus on next element
      this.focusNextElement();

      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'ArrowUp') {
      //If arrow up is pressed, focus on previous element
      this.focusPreviousElement();

      event.preventDefault();
      event.stopPropagation();
    } else if (fromEntry && !(event.key === 'Enter')) {
      //If any other key is pressed when an entry is focused, set focus back to
      //search input so we can type; Except for enter-key: we need this to confirm an entry selection
      if (!this.hideSearchBar) {
        this.searchInput.nativeElement.focus();
      } else if (event.key === 'Escape') {
        this.hiddenTrigger.closeMenu();
      }

      event.preventDefault();
      event.stopPropagation();
    } else if (!fromEntry && event.key === 'Enter') {
      //Select first element of search result when hitting enter in the search field
      //and close the menu

      const firstEl = this.activeEntries[0];
      if (firstEl !== undefined) {
        this.entrySelected.next(firstEl);
      }

      this.hiddenTrigger.closeMenu();
    }
  }

  isLink(icon: string) {
    return (
      (icon.startsWith('/') &&
        (icon.endsWith('png') || icon.endsWith('jpg'))) ||
      icon.startsWith('http')
    );
  }

  /**
   * Highlights an adjacent element, depending on `next`
   * 
   * @param next Should we focus the next entry (true) or the previous one (false)?
   */
  private focusAdjacentElement(next: boolean) {
    let newFocusEl = this.entryEls.first;

    this.entryEls.forEach((i, idx) => {
      if (i.nativeElement == document.activeElement) {
        const direction = next ? +1 : -1;

        let newIdx = idx + direction;

        if (this.entryEls.length === idx + direction) {
          //If we are at the end of our and we want the next element, skip to beginning
          newIdx = 0;
        } else if (idx + direction < 0) {
          //If we are at the beginning of our list and we want the previous element, skip to end
          newIdx = this.entryEls.length - 1;
        }

        newFocusEl = this.entryEls.toArray()[newIdx];
      }
    });
    newFocusEl?.nativeElement?.focus();
  }

  /**
   * Highlights the previous element
   */
  focusPreviousElement() {
    this.focusAdjacentElement(false);
  }

  /**
   * Highlights the next element
   */
  focusNextElement() {
    this.focusAdjacentElement(true);
  }
}
