import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, fromEvent, race, timer } from 'rxjs';
import _ from 'lodash';
import { debounceTime, filter, switchMap, takeUntil } from 'rxjs/operators';
import { UserPeekOverlayComponent } from './user-peek-overlay.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { ExecutionStateParticipant } from 'src/api';

@Directive({
  selector: '[userPeek]',
})
export class UserPeekRenderDirective implements OnInit, OnDestroy {
  @Input(`userPeek`) user: ExecutionStateParticipant;

  private _overlayRef: OverlayRef;

  private abort$ = new Subject<void>();

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef
  ) {
    fromEvent(this._elementRef.nativeElement, 'pointerenter')
      .pipe(switchMap(() => timer(1000).pipe(takeUntil(this.abort$))))
      .subscribe(() => {
        this.show();
      });
  }

  ngOnInit() {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 5,
        },
      ]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  mouseOnPopup$ = new Subject<void>();

  show() {
    if (!this.user) return;
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const popupRef: ComponentRef<UserPeekOverlayComponent> =
        this._overlayRef.attach(new ComponentPortal(UserPeekOverlayComponent));
      popupRef.instance.user = this.user;
      let clickedInside = false;
      popupRef.instance.el.nativeElement.addEventListener(
        'pointerenter',
        () => {
          this.mouseOnPopup$.next();
        }
      );
      popupRef.instance.el.nativeElement.addEventListener('pointerleave', () =>
        clickedInside ? null : this.closePopup()
      );
      popupRef.instance.el.nativeElement.addEventListener(
        'pointerdown',
        () => (clickedInside = true)
      );
      popupRef.instance.el.nativeElement.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
      popupRef.instance.el.nativeElement.addEventListener('mouseleave', () =>
        clickedInside ? null : this.closePopup()
      );
    }
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    this.closePopup();
  }

  @HostListener('pointerleave', ['$event'])
  hide() {
    race(this.mouseOnPopup$, timer(100))
      .pipe(filter((e) => !_.isNil(e)))
      .subscribe((v) => {
        this.closePopup();
      });
  }

  ngOnDestroy() {
    this.closePopup();
  }

  private closePopup() {
    this.abort$.next();
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
