import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContrastModeService {
  private highContrastEnabled = new BehaviorSubject<boolean>(false);

  constructor() {
    this.initializeContrastMode();
  }

  private initializeContrastMode() {
    // Check if the user has a preference saved in local storage
    const savedPreference = localStorage.getItem('highContrastMode');
    if (savedPreference !== null) {
      this.setHighContrast(JSON.parse(savedPreference));
    } else {
      // If no preference is saved, check the system preference and set it
      const systemPreference = window.matchMedia('(prefers-contrast: more)').matches;
      this.setHighContrast(systemPreference);
    }
  }

  setHighContrast(enable: boolean) {
    // push the new value to the subscribers
    this.highContrastEnabled.next(enable);
    // save the new value to local storage
    localStorage.setItem('highContrastMode', JSON.stringify(enable));
    // apply the high contrast mode to the body
    document.body.classList.toggle('high-contrast', enable);
  }

  toggleHighContrast() {
    this.setHighContrast(!this.highContrastEnabled.value);
  }

  getHighContrastEnabled() {
    return this.highContrastEnabled.asObservable();
  }
}
