import { environment } from "src/environments/environment";

export function humanReadableFileSize(size: number | undefined) {
  if (!size || isNaN(size)) return '0 Bytes';
  if (size < 1000) {
    return `${size} Bytes`;
  } else if (size >= 1000 && size < 1000000) {
    return `${Math.round(size / 1000)} KB`;
  } else if (size >= 1000000 && size < 1e9) {
    return `${Math.round(size / (1000 * 1000))} MB`;
  } else if (size >= 1e9 && size < 1e12) {
    return `${Math.round(size / (1000 * 1000 * 1000))} GB`;
  } else if (size >= 1e12) {
    return `${Math.round(size / (1000 * 1000 * 1000 * 1000))} TB`;
  }
}

export function getMaxUploadSize(): number {
  const UPLOAD_SIZE_MB = environment.MAX_UPLOAD_SIZE_MB;
  return UPLOAD_SIZE_MB * 1024 * 1024; // in bytes
}

export function getUploadSizeHumanReadable() {
  return humanReadableFileSize(getMaxUploadSize())
}

export function getAllowedFileString() {
  return environment.ALLOWED_FILE_FORMATS;
}

export function getAllowedFileFormats() {
  const formats = getAllowedFileString().split(/\s*,\s*/);
  return formats;
}