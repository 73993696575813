<button mat-icon-button [disabled]="pagination?.page <= 1" (click)="paginatePrev()">
  <mat-icon>chevron_left</mat-icon>
</button>
<span class="page-indicator" [matMenuTriggerFor]="disablePageSizeSelect ? null : pageSizeMenu"
  [attr.tabindex]="disablePageSizeSelect ? '-1' : '0'" #pageSizeMenuTrigger="matMenuTrigger"
  (keyup.enter)="pageSizeMenuTrigger.openMenu()" [class.disabled]="disablePageSizeSelect">
  <span class="current-page-range">
    {{ pageRangeLabel }}
  </span>
  /
  <span class="total-page-count">
    {{ totalElements }}
  </span>
</span>
<button mat-icon-button [disabled]="
    pagination.page * pagination.rows_per_page >=
    totalElements
  " (click)="paginateNext()">
  <mat-icon>chevron_right</mat-icon>
</button>
<mat-menu #pageSizeMenu="matMenu">
  <h3 class="table-page-size-label">
    {{ "common.label.pageSize" | translate }}
  </h3>
  <button mat-menu-item *ngFor="let possibleSize of [5, 10, 20, 25, 50]" (click)="setPageSize(possibleSize)">
    {{ possibleSize }}
  </button>
</mat-menu>