<mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
<ng-container *ngIf="!loading">
  <div class="header">
    <div class="profile-picture-container">
      <div class="profile-picture">
        <mat-icon *ngIf="!user.profile_picture">person</mat-icon>
        <img *ngIf="!!user.profile_picture" [src]="
            API_URL +
            '/file/' +
            user.profile_picture +
            '?realm=' +
            realm
          " />
      </div>
    </div>
    <h3>
      <ng-container *ngIf="
      user.first_name?.length || user?.last_name?.length
    ">
        {{
        (user.first_name ?? "") +
        (user.first_name?.length && user?.last_name?.length
        ? " "
        : "") +
        (user.last_name ?? "")
        }}
      </ng-container>
      <ng-container *ngIf="
      !user.first_name?.length &&
      !user?.last_name?.length &&
      user.mail
    ">
        {{ user.mail }}
      </ng-container>
      <ng-container *ngIf="
      !user.first_name?.length &&
      !user?.last_name?.length &&
      !user.mail
    ">
        Gast
      </ng-container>
    </h3>
    <span class="mail" *ngIf="user?.mail && (user.first_name?.length || user.last_name?.length)">{{user.mail}}</span>
  </div>
  <div class="details">
    <table>
      <tr *ngIf="user?.phone">
        <td translate>lawyer.userSettings.contactPhone</td>
        <td><a [href]="'tel:' + user.phone">{{ user.phone }}</a></td>
      </tr>
      <tr *ngIf="user?.fax">
        <td translate>lawyer.userSettings.contactFax</td>
        <td><a [href]="'fax:' + user.fax">{{ user.fax }}</a></td>
      </tr>
      <tr *ngIf="user?.website">
        <td translate>lawyer.userSettings.website</td>
        <td><a [href]="user.website">{{ user.website }}</a></td>
      </tr>
    </table>
  </div>
  <div class="actions">
    <button mat-button (click)="viewUserDetails()"><mat-icon>open_in_new</mat-icon>{{ 'common.button.viewDetails' |
      translate }}</button>
  </div>
</ng-container>