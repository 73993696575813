<div class="actions">
  <button mat-button (click)="closeWithoutSave()">
    <mat-icon>close</mat-icon>
    <span class="close-label">{{ 'common.button.close' | translate }}</span>
  </button>
  <button mat-raised-button color="primary" (click)="saveSignedDocument()" [disabled]="!allDone || loading">
    <mat-icon>save</mat-icon>
    {{ 'common.button.saveAndClose' | translate }}
  </button>
</div>
<ng-container *ngIf="showMockedPDF">
  <app-document #documentPopOver #appDocument [readonly]="true" [(document)]="pendingFile"></app-document>
</ng-container>
<ng-container *ngIf="!showMockedPDF">
  <div class="pdf-container">
    <mat-spinner [diameter]="40" *ngIf="loading"></mat-spinner>
    <pdf-viewer [src]="pdfBlobUrl" [render-text]="true" [zoom]="scale" [show-all]="true" *ngIf="pdfBlobUrl"
      (page-rendered)="onPageRendered($event)" (after-load-complete)="loading = false" #pdfViewer></pdf-viewer>
    <div class="pdf-actions">
      <button mat-icon-button [matTooltip]="'common.button.zoomIn' | translate" (click)="zoomIn()">
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button mat-icon-button [matTooltip]="'common.button.zoomOut' | translate" (click)="zoomOut()">
        <mat-icon>zoom_out</mat-icon>
      </button>
    </div>
  </div>
</ng-container>