import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProcessService } from 'src/app/views/process/process.service';
import { TooltipDefinition, usersTooltip } from '../../helpers';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-poster-badge',
  templateUrl: './poster-badge.component.html',
  styleUrls: ['./poster-badge.component.scss'],
})
export class PosterBadgeComponent implements OnChanges {
  @Input() self: boolean = false;
  @Input() sender: string;
  @Input() service: ProcessService;

  API_URL = environment.API_URL;

  public senderInfo: TooltipDefinition | undefined = undefined;

  constructor(
    private translator: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sender) {
      const tooltip = usersTooltip(
        [this.sender],
        this.translator,
        this.service
      )[0];
      if (tooltip !== '?') {
        this.senderInfo = tooltip;
      }
    }
  }

  get realm(): string {
    return (
      this.activatedRoute.snapshot.paramMap.get('realm') ??
      this.activatedRoute.firstChild.snapshot.paramMap.get('realm')
    );
  }
}
