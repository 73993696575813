/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TableViewParametersSortBy } from './tableViewParametersSortBy';
import { TableViewGroupDefinition } from './tableViewGroupDefinition';
import { TableViewColumnDefinition } from './tableViewColumnDefinition';


export interface TableViewParameters { 
    displayed_columns?: Array<TableViewColumnDefinition>;
    group?: TableViewGroupDefinition;
    hidden_columns?: Array<TableViewColumnDefinition>;
    sort_by?: TableViewParametersSortBy;
}

