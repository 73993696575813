import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { sanitizeHtmlPipe } from './sanitize.pipe';

@Pipe({ name: 'highlight' })
export class highlightSubstringPipe implements PipeTransform {
  constructor(private sanitizeHtmlPipe: sanitizeHtmlPipe) {}

  transform(text: string, args: any[]): SafeHtml {
    const substring = args;
    if (!substring?.length) return this.sanitizeHtmlPipe.transform(text);
    return this.sanitizeHtmlPipe.transform(
      text.replace(new RegExp(`(${substring})`, 'gi'), '<b>$1</b>')
    );
  }
}
