<div class="seen-marker"
     [class.seen]="status === MessageSeenStatus.SEEN"
     [class.partial]="status === MessageSeenStatus.PARTIAL"
     [class.unseen]="status === MessageSeenStatus.UNSEEN"
     [matTooltip]="status === MessageSeenStatus.SEEN ? ('common.tooltip.seen' | translate) :
                    status === MessageSeenStatus.PARTIAL ? ('common.tooltip.seenPartial' | translate) :
                    ('common.tooltip.unseen' | translate)"
     role="status">
  <ng-container *ngIf="status === MessageSeenStatus.UNSEEN">
    <mat-icon class="clock-icon">schedule</mat-icon>
  </ng-container>
  <ng-container *ngIf="status !== MessageSeenStatus.UNSEEN">
    <span class="check-marks" [class.seen]="status === MessageSeenStatus.SEEN">
      <span class="check">✓</span>
      <span class="check second" *ngIf="status === MessageSeenStatus.SEEN">✓</span>
    </span>
  </ng-container>
</div>
