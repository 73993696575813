import { Pipe, PipeTransform } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';

export interface DurationPipeInput {
  dueAt: string | Dayjs;
  durationMinutes?: number;
}

const SEPARATOR_PIPE = ' | ';
const SEPARATOR_DASH = ' - ';

@Pipe({ name: 'appointmentDuration' })
export class appointmentDurationPipe implements PipeTransform {
  constructor() { }

  transform(appointment: DurationPipeInput, parameters: any = {}): string {
    let separator = SEPARATOR_PIPE;
    if (parameters.separator?.length) {
      separator = parameters.separator;
    }
    if (!appointment.dueAt) return '';
    const beginningDate = dayjs(appointment.dueAt);
    let endDate: dayjs.Dayjs;
    if (appointment.durationMinutes) {
      endDate = beginningDate.add(appointment.durationMinutes, 'minutes');
    }
    let outString = beginningDate.format('DD.MM.YYYY');
    if (beginningDate.format('HH:mm') !== '00:00') {
      outString += separator + beginningDate.format('HH:mm');
    }
    if (!!endDate) {
      outString += SEPARATOR_DASH;
      const sameDayDates = endDate.format('DD.MM.YYYY') === beginningDate.format('DD.MM.YYYY');
      if (!sameDayDates) {
        outString += endDate.format('DD.MM.YYYY')
      }
      if (endDate.format('HH:mm') !== '23:59') {
        outString += (sameDayDates ? ' ' : separator) + endDate.format('HH:mm');
      }
    }
    return outString.replace(/\s+-\s+$/, '');
  }
}
