/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UploadHandle { 
    chunkbyteoffset?: number;
    chunkindex?: number;
    duplicate_behavior?: UploadHandle.DuplicateBehaviorEnum;
    file?: Blob;
    folder?: string;
    nodeid?: string;
    patch_id?: string;
    patch_link?: string;
    path?: string;
    _public?: boolean;
    stateid?: string;
    threadid?: string;
    totalchunkcount?: number;
    totalfilesize?: number;
    upload_identifier?: string;
}
export namespace UploadHandle {
    export type DuplicateBehaviorEnum = 'replace' | 'error' | 'increment';
    export const DuplicateBehaviorEnum = {
        Replace: 'replace' as DuplicateBehaviorEnum,
        Error: 'error' as DuplicateBehaviorEnum,
        Increment: 'increment' as DuplicateBehaviorEnum
    };
}


