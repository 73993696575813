import { Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular-ivy';

@Injectable({ providedIn: 'root' })
export class SilberflussErrorHandler extends SentryErrorHandler {
  constructor() {
    super({
      showDialog: false,
    });
  }

  handleError(error: any) {
    if (this.isChunkLoadError(error)) {
      window.location.reload();
    } else {
      super.handleError(error);
    }
  }

  private isChunkLoadError(error: any): boolean {
    // Implement logic to determine if the error is a chunk loading error
    // For example, check the error message or type
    return /Loading chunk [\d]+ failed/.test(error.message);
  }
}
