<ng-container *ngIf="appearance === 'checkbox'">
  <div class="answer-title" *ngIf="inline && message?.content">
    <mat-icon aria-hidden="true">info</mat-icon>
    <p [innerHTML]="message.content | sanitizeHtml" id="checkbox-group-description"></p>
  </div>
  <div class="checkbox-input" role="group" aria-labelledby="checkbox-group-description">
    <mat-checkbox *ngFor="let option of (allOptions ?? []); let i = index" (change)="inline ? logInCheckboxes() : changed.emit()"
      [(ngModel)]="option.selected" [matTooltip]="option.text" [attr.aria-checked]="option.selected" [attr.aria-labelledby]="'checkbox-label-' + i"
    >
      {{ option.text }}
      <span [id]="'checkbox-label-' + i" class="sr-only">{{ option.text }}</span>
    </mat-checkbox>
    <button mat-raised-button color="primary" *ngIf="!inline" (click)="logInCheckboxes()"
      [disabled]="checkboxGoDisabled" [attr.aria-label]="'common.button.continue' | translate">
      {{ "common.button.continue" | translate }}
    </button>
  </div>
</ng-container>
<ng-container *ngIf="appearance === 'inline-checkbox'">
  <ng-container *ngTemplateOutlet="formFieldInput; context: {multiple: true}">
  </ng-container>
</ng-container>
<ng-container *ngIf="appearance === 'dropdown' || appearance === 'horizontal'">
  <ng-container *ngTemplateOutlet="formFieldInput; context: {multiple: false}">
  </ng-container>
</ng-container>
<ng-container *ngIf="appearance === 'buttons'">
  <div class="button-options" role="group" [attr.aria-label]="message.title | translate | stripHtml">
    <button mat-button color="primary" *ngFor="
        let option of (allOptions ?? []);
        let i = index
      " (click)="buttonClick.emit(option)" [class.with-icon]="option?.icon" [class.selected]="value === option.text"
      [class.discarded]="value && value !== option.text" [style.animationDelay]="i / 3 + 's'" [matTooltip]="option.text" [attr.aria-pressed]="value === option.text">
      <mat-icon *ngIf="option?.icon" aria-hidden="true">
        {{ option.icon }}
      </mat-icon>
      {{ option.text }}
    </button>
    <button mat-button color="primary" *ngIf="
        !allOptions ||
        allOptions?.length === 0
      " (click)="buttonClick.emit(value)" [attr.aria-label]="'common.button.continue' | translate">
      {{ "common.button.continue" | translate }}
    </button>
  </div>
</ng-container>
<ng-template #formFieldInput let-multiple="multiple">
  <div class="input-area">
    <app-search-menu [entries]="queryOptions.bind(this)" (entrySelected)="selectOption($event, multiple)"
      [asSelectionDropdown]="true" [skipCloseOnSelect]="multiple" [hideSearchBar]="allOptions.length <= 5" [attr.aria-label]="(message.title | translate | stripHtml) + ' search menu'">
      <button mat-button [class.with-selection]="multiple ? !!formControl?.value?.length : !!formControl?.value"
        type="button">
        <mat-label [matTooltip]="message.title | translate | stripHtml" [innerHtml]="message.title | translate" [class.required]="required" [id]="'search-menu-label'"></mat-label>
        <span class="overflow-ellipsis" [matTooltip]="selectionLabel" [attr.aria-labelledby]="'search-menu-label'">{{selectionLabel}}</span>
        <mat-icon aria-hidden="true">arrow_drop_down</mat-icon>
      </button>
    </app-search-menu>
    <button mat-raised-button color="primary" *ngIf="!inline"
      (click)="appearance === 'inline-checkbox' ? logInCheckboxes() : buttonClick.emit(formControl.value)"
      [disabled]="error" [attr.aria-label]="'common.button.continue' | translate">
      {{ "common.button.continue" | translate }}
    </button>
  </div>
</ng-template>
