import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { sha256 } from 'js-sha256';
import _, { debounce } from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  FormattedDataAnswer,
  JSONRepresentation,
  reverseJSONPreview,
} from 'advoprocess';
import { FormEntry } from 'advoprocess/lib/nodes/default-nodes/form.node';
import { TranslateService } from '@ngx-translate/core';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import DataStore from 'advoprocess/lib/parser/data-store';
import { TemplatesService } from 'src/api';
import { filterTerm } from 'src/app/common/helpers';
import { SettingsService } from 'src/app/common/settings.service';
import { AuthService } from 'src/app/auth/auth.service';
import {
  FormRendererComponent,
  buildFormTempDataStore,
} from 'src/app/views/process/question-answers/form-renderer/form-renderer.component';
import {
  QuestionConfig,
  getQuestionMessageMeta,
  parseQuestionConfig,
} from 'advoprocess/lib/types/question';
import dayjs from 'dayjs';

export interface FieldChangeEvent {
  oldFieldName: string;
  newFieldName: string;
  oldValue: any;
  newValue: any;
  meta?: { [key: string]: any };
  path: string[];
}

export interface DataViewTab {
  name: string;
  templateId?: string;
  fixed?: boolean;
  format: 'table' | 'form';
  definition?: FormEntry[];
}

type ExtendedFormattedDataAnswer = FormattedDataAnswer & {
  options?: any[];
};

interface FilterForm {
  queryString: FormControl<string | undefined>;
}

@Component({
  selector: 'app-data-render-table',
  templateUrl: './data-render-table.component.html',
  styleUrls: ['./data-render-table.component.scss'],
  animations: [
    trigger('jsonAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scaleY(1)' }),
        animate('200ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('historyAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0)' }),
        animate('150ms', style({ opacity: 1, transform: 'scaleY(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scaleY(1)' }),
        animate('150ms', style({ opacity: 0, transform: 'scaleY(0)' })),
      ]),
    ]),
  ],
})
export class DataRenderTableComponent implements OnChanges {
  @Input() data: FormattedDataAnswer[];

  filteredData: ExtendedFormattedDataAnswer[] = [];

  @Input() highlightDataPoint?: string;

  @Input() label?: string;

  @Output() fieldChange = new EventEmitter<FieldChangeEvent>();

  @Input() validator?: (values: FormattedDataAnswer[]) => boolean | string;
  @Output() afterValidate = new EventEmitter<boolean | string>();

  @ViewChild('formRenderer') formRenderer: FormRendererComponent;

  validated: boolean | string = true;

  loading: boolean = false;

  filterForm = new FormGroup<FilterForm>({
    queryString: new FormControl(),
  });

  parseInt = parseInt;

  isNumber(n: any) {
    if (_.isNumber(n)) return true;
    try {
      let asNumber = parseInt(n);
      if (!isNaN(asNumber)) return true;
      return false;
    } catch {
      return false;
    }
  }

  openTabs: DataViewTab[] = [
    {
      format: 'table',
      name: this.translator.instant('common.label.tableView'),
      fixed: true,
    },
  ];
  currentTab: DataViewTab = this.openTabs[0];
  hasDefaultTab: boolean = false;

  @Input() getOptionsFor?: (
    variable: FormattedDataAnswer,
    all: FormattedDataAnswer[]
  ) => any[] | '*';

  parsedTabDefinition: FormEntry[] | undefined;
  formTempDataStore?: DataStore = undefined; // ToDo: Use for form rendering

  formValueChange = new BehaviorSubject<FormEntry[]>([]);

  get missingRequiredFields(): FormEntry[] {
    if (!this.formRenderer) return [];
    return _.flatten(
      this.formRenderer.layout
        .map((row, rowindex) =>
          row.filter(
            (entry, colindex) =>
              this.formRenderer.shouldRenderQuestion(rowindex, colindex) &&
              entry.error
          )
        )
        .filter((r) => !!r?.length)
    );
  }

  missingRequiredFieldsOpen = false;

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private translator: TranslateService,
    private templateAPI: TemplatesService,
    private settings: SettingsService,
    public auth: AuthService
  ) {
    this.filterForm.get('queryString').valueChanges.subscribe(() => {
      this.applyFilter();
    });
    this.initFormChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data || changes.highlightDataPoint) {
      this.applyFilter();
      this.updateParsedTableConfig();
    }
    this.validate();
    if (changes.label) {
      this.initializeTabConfig();
    }
  }

  private lowerCaseCompare(a: string, b: string): boolean {
    return (
      !b?.length ||
      a.toLowerCase().includes(b.toLowerCase()) ||
      b.toLowerCase().includes(a.toLowerCase())
    );
  }

  private applyFilter() {
    if (!this.data) {
      this.filteredData = [];
      return;
    }
    this.filteredData = this.applyFilterToData(this.data);
  }

  private applyFilterToData(
    data: FormattedDataAnswer[]
  ): ExtendedFormattedDataAnswer[] {
    const filterVal = this.filterForm.get('queryString').value;
    const filteredData = data
      .filter(
        (v) =>
          (v.type === 'subdata'
            ? this.lowerCaseCompare(JSON.stringify(v.value), filterVal)
            : this.lowerCaseCompare(v.name, filterVal)) ||
          ((v.value as any)?.type === 'json'
            ? this.lowerCaseCompare(JSON.stringify(v.value), filterVal)
            : this.lowerCaseCompare(v.value.toString(), filterVal))
      )
      .map((v) => {
        if (v.type === 'subdata') {
          v.subtreeExpanded = true;
          if (this.lowerCaseCompare(v.name, filterVal)) {
            return { ...v, originalValue: v };
          }
          return {
            ...v,
            value: {
              ...(v.value as any),
              data: this.applyFilterToData(_.cloneDeep((v.value as any).data)),
            },
            originalValue: v,
          };
        } else if ((v.value as any)?.type === 'json') {
          return {
            ...v,
            value: {
              ...(v.value as any),
              data: this.filterJSONData(_.cloneDeep((v.value as any).data)),
            },
            originalValue: v,
          };
        } else {
          return { ...v, originalValue: v };
        }
      })
      .map((v) =>
        this.getOptionsFor ? { ...v, options: this.optionsFor(v) } : v
      )
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
    return filteredData;
  }

  private optionsFor(v: ExtendedFormattedDataAnswer): any[] {
    const options = this.getOptionsFor(v, this.data);
    return options === '*' ? undefined : options;
  }

  private filterJSONData(jsondata: JSONRepresentation[]): JSONRepresentation[] {
    const valueQuery = this.filterForm.get('queryString').value;
    if (!valueQuery?.length) return jsondata;
    let buffer = [];
    for (const entry of jsondata) {
      if (entry.type === 'json') {
        const fChildren = this.filterJSONData(entry.value);
        if (fChildren?.length) {
          entry.value = fChildren;
          entry.folded = false;
          buffer.push(entry);
        } else if (entry.key && this.lowerCaseCompare(entry.key, valueQuery)) {
          entry.folded = false;
          buffer.push(entry);
        }
      } else {
        if (
          (entry.value?.toString()?.length &&
            this.lowerCaseCompare(entry.value?.toString() ?? '', valueQuery)) ||
          (entry.key && this.lowerCaseCompare(entry.key, valueQuery))
        ) {
          buffer.push(entry);
        }
      }
    }
    return buffer;
  }

  copyObjectPath(path: string[]) {
    const fullPath = `#${path
      .map((el) => (this.isNumber(el) ? parseInt(el) + 1 : el))
      .join('>')}`;
    this.clipboard.copy(fullPath);
    this.snackBar.open(fullPath);
  }

  onEditField(
    type: 'name' | 'value',
    filteredValue: ExtendedFormattedDataAnswer,
    dataSource: FormattedDataAnswer[],
    path: string[]
  ) {
    if (
      filteredValue.options?.length === 1 ||
      (filteredValue?.options?.length && type === 'name')
    ) {
      return;
    }
    const value = filteredValue.originalValue ?? filteredValue;
    const editTarget = type === 'name' ? 'editName' : 'editValue';
    this.resetFocusRecursively(this.data);
    filteredValue[editTarget] = true;
    if (
      type === 'value' &&
      (_.isObject(value.value) || _.isArray(value.value))
    ) {
      const returned = reverseJSONPreview(
        value.value['type'] === 'json' && value.value['data']
          ? (value.value as any).data
          : value.value
      );
      setTimeout(async () => {
        const el = document.querySelector(
          `div.edit-input-json[forvalue="${value.name}"]`
        ) as HTMLInputElement;
        let newValue = value.value;
        const { JSONEditor, Mode } = await import('vanilla-jsoneditor');
        new JSONEditor({
          target: el,
          props: {
            onChange: (content) => {
              if ((content as any).json) {
                newValue = JSON.stringify((content as any).json);
              } else if ((content as any).text) {
                newValue = (content as any).text;
              }
            },
            content: {
              json: returned['Object'],
            },
            mode: Mode.text,
            mainMenuBar: true,
            onBlur: () => {
              this.onBlurField(type, filteredValue, newValue, dataSource, path);
            },
          },
        });
      }, 50);
      return;
    }
    setTimeout(() => {
      const el = document.querySelector(
        `input[forvalue="${value.name}"]`
      ) as HTMLInputElement;
      el?.focus();
      el?.select();
    }, 50);
  }

  private resetFocusRecursively(values: FormattedDataAnswer[]) {
    for (const v of values) {
      if (v.type === 'subdata') {
        this.resetFocusRecursively((v.value as any).data);
      } else {
        v['editName'] = v['editValue'] = false;
      }
    }
  }

  onBlurField(
    type: 'name' | 'value',
    filteredValue: FormattedDataAnswer,
    newValue: string,
    dataSource: FormattedDataAnswer[],
    path: string[],
    meta?: { [key: string]: any }
  ) {
    const value = filteredValue.originalValue ?? filteredValue;
    const editTarget = type === 'name' ? 'editName' : 'editValue';
    this.resetFocusRecursively(this.data);
    filteredValue[editTarget] = false;
    let changed = false;
    const oldValue = value.value;
    const oldName = value.name;
    switch (type) {
      case 'name':
        value.name = newValue;
        if (value.name !== oldName) changed = true;
        break;
      case 'value':
        value.value = newValue;
        if (value.value !== oldValue) changed = true;
        break;
    }
    if (!value.name?.length) {
      dataSource.splice(dataSource.indexOf(value), 1);
      newValue = undefined;
      type = 'name';
      changed = true;
    }
    if (!meta) {
      meta = {};
    }
    const sender = {
      entity: this.auth.isClient ? 'client' : 'lawyer',
      uuid: this.auth.userId,
    };
    meta['__postedAt'] = dayjs().toISOString();
    meta['answeredBy'] = sender;
    if (changed) {
      this.fieldChange.emit({
        oldFieldName: oldName,
        oldValue: oldValue,
        newFieldName: type === 'value' ? oldName : newValue,
        newValue: type === 'name' ? oldValue : newValue,
        path,
        meta,
      });
    }
    this.validate();
    this.applyFilter();
  }

  onDeleteField(
    filteredValue: FormattedDataAnswer,
    dataSource: FormattedDataAnswer[],
    path: string[]
  ) {
    this.onBlurField('name', filteredValue, '', dataSource, path);
  }

  addDataRow(
    dataGroup: FormattedDataAnswer[],
    path: string[],
    skipOnEdit = false
  ): FormattedDataAnswer {
    let id = sha256(Date.now().toString()).slice(5, 15);
    while (dataGroup.some((f) => f.name === id)) {
      id = sha256(Date.now().toString()).slice(5, 15);
    }
    const newVal: FormattedDataAnswer = {
      name: id,
      type: 'string',
      value: '',
    };
    dataGroup.push(newVal);
    this.applyFilter();
    if (!skipOnEdit) {
      setTimeout(() => {
        this.onEditField('name', newVal, dataGroup, path);
      });
    }
    return newVal;
  }

  iconFor(type: string, format?: string): string {
    switch (type) {
      case 'string':
        return 'label';
      case 'number':
        return 'pin';
      case 'dropdown':
        return 'fact_check';
      case 'json':
        return 'code';
      default:
        return 'tag';
    }
  }

  addTab(event: MenuEntry<DataViewTab>) {
    this.openTabs.push(event.value);
    this.saveTabConfiguration();
  }

  closeTab(tab: DataViewTab) {
    if (tab.fixed) return;
    this.openTabs.splice(this.openTabs.indexOf(tab), 1);
    this.saveTabConfiguration();
  }

  private async saveTabConfiguration() {
    if (!this.label) return;
    this.settings
      .setSetting(
        `view.${this.label}.dataTables`,
        this.openTabs.map((t) => t.templateId).filter((t) => !!t),
        'lawyer'
      )
      .then(() => {});
  }

  abortQuery$ = new Subject<void>();
  lastResults: MenuEntry<DataViewTab>[] = [];

  queryViewTabs(searchTerm: string): Observable<MenuEntry<DataViewTab>[]> {
    this.abortQuery$.next();
    return timer(1000).pipe(
      switchMap(() =>
        this.templateAPI.listTemplates({
          filterViewPagination: {
            filter: [
              {
                operand: 'definition.type',
                operator: 'eq',
                value: 'FormNode',
              },
            ],
            pagination: {
              page: 1,
              rows_per_page: 10,
            },
            view: {
              hidden_columns: [],
              displayed_columns: [
                {
                  display_name: 'id',
                  internal_name: 'id',
                },
                {
                  display_name: 'name',
                  internal_name: 'name',
                },
                {
                  display_name: 'questions',
                  internal_name: 'definition.config.questions',
                },
              ],
            },
          },
        })
      ),
      map((resp): MenuEntry<DataViewTab>[] => {
        const list = resp.templates;
        return list.map((t) => ({
          name: t.name,
          icon: 'space_dashboard',
          value: {
            format: 'form',
            name: t.name,
            definition: (t as any).questions,
            templateId: t.id,
          },
        }));
      }),

      tap((x) => (this.lastResults = x)),
      startWith(
        this.lastResults.filter((e) =>
          filterTerm(searchTerm, e, this.translator)
        )
      ),
      takeUntil(this.abortQuery$),
      map((entries) =>
        entries.filter(
          (e) =>
            !this.openTabs.some(
              (open) => open.templateId === e.value.templateId
            )
        )
      )
    );
  }

  selectTab(tab: DataViewTab) {
    this.currentTab = tab;
    this.updateParsedTableConfig();
  }

  private updateParsedTableConfig() {
    if (this.currentTab.format === 'form') {
      this.parsedTabDefinition = this.currentTab.definition.map((e) => {
        const path = e.refId.split(/&gt;>/gm).filter((n) => !!n);
        const field = this.getFormattedAnswerForPath(path);
        let parsedValue = field?.rawValue ?? field?.value ?? '';
        return { ...e, value: parsedValue };
      });
    }
  }

  private getFormattedAnswerForPath(
    path: string[],
    where: FormattedDataAnswer[] = this.data
  ): FormattedDataAnswer | undefined {
    const name = path[0];

    if (!where) return undefined;

    // Get recursively
    for (const entry of where) {
      if (entry.type === 'subdata') {
        const subFound = this.getFormattedAnswerForPath(
          path.slice(1),
          (entry.value as any).data as FormattedDataAnswer[]
        );
        if (subFound) {
          return subFound;
        }
      } else {
        if (entry.name === name) {
          return entry;
        }
      }
    }
    return undefined;
  }

  private validate() {
    if (!this.validator) {
      this.validated = true;
    } else {
      this.validated = this.validator(this.data);
    }
    this.afterValidate.next(this.validated);
  }

  private initFormChange() {
    this.formValueChange
      .pipe(debounceTime(250))
      .subscribe((entries: FormEntry[]) => {
        entries.forEach((entry) => {
          const path = entry.refId.split(/&gt;>/gm).filter((n) => !!n);
          if (!path.length) return;
          let field = this.getFormattedAnswerForPath(path);
          if (_.isNil(entry.value)) return;
          if (!field) {
            field = this.addDataRow(this.data, path, true); // ToDo: This is wrong, we have to take care of the hiearachy
            field.name = path[path.length - 1];
          }
          const { parsedQuestion } = parseQuestionConfig(
            entry.question as QuestionConfig,
            buildFormTempDataStore([entries])
          );
          this.onBlurField(
            'value',
            field,
            entry.value,
            this.data,
            path.slice(0, -1) ?? [],
            _.merge(
              getQuestionMessageMeta(
                entry.value,
                entry.question as QuestionConfig,
                parsedQuestion,
                this.auth.jwtToken$.value
              ),
              {
                questionType: entry.question?.questionType?.value ?? 'string',
              }
            )
          );
        });
      });
  }

  private initializeTabConfig() {
    if (!this.label?.length) return;
    this.loading = true;
    forkJoin([
      this.settings.getSetting(`view.${this.label}.dataTables.default`),
      this.settings.getSetting(`view.${this.label}.dataTables`),
    ])
      .pipe(
        switchMap(([defaultSetting, viewSetting]) => {
          const tabIds: string[] = viewSetting?.value;
          return forkJoin([
            of(defaultSetting),
            ...(tabIds?.map((id) =>
              this.templateAPI.getTemplateById({
                templateid: id,
              })
            ) ?? []),
          ]);
        })
      )
      .subscribe(([defaultSetting, ...tabDefinitions]) => {
        for (const value of tabDefinitions) {
          this.openTabs.push({
            format: 'form',
            name: value.name,
            definition: value.definition?.config?.questions,
            templateId: value.id,
          });
        }
        if (!!defaultSetting?.value) {
          const targetIndex = this.openTabs.findIndex(
            (t) => t.templateId === defaultSetting.value
          );
          if (targetIndex !== -1) {
            const [targetTab] = this.openTabs.splice(targetIndex, 1);
            this.openTabs.unshift(targetTab);
            targetTab.fixed = true;
            this.selectTab(targetTab);
            this.hasDefaultTab = true;
          }
        }
        this.loading = false;
      });
  }
}
