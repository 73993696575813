import { AvailableFilter } from 'advoprocess/lib/types/filter';

export const groupsFilter: AvailableFilter[] = [
  {
    id: 'role_name',
    label: 'role.filter.label.name',
    internal_name: 'name',
    icon: 'label',
    type: 'string',
  },
  {
    id: 'role_description',
    label: 'role.filter.label.description',
    internal_name: 'description',
    icon: 'description',
    type: 'string',
  },
];
