import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';
import {
  NotificationService,
  Notification,
  Event,
  EventsService,
} from 'src/api';
import { CommonService } from 'src/app/common/common.service';
import { notificationFilters } from 'src/app/views/process/filters/notification-filters';
import { parseFromAssigned } from 'src/app/views/process/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtendedFilterCriterium } from 'advoprocess/lib/types/filter';
import { taskFilters } from 'src/app/views/process/filters/task-filters';
import { ScheduledNodeEventType } from 'advoprocess/lib/types/postActions';
import { DEFAULT_EVENT_FIELDS } from 'src/app/views/process/event-parsing';
import { parseWithPlaceholders } from 'advoprocess';
import DataStore from 'advoprocess/lib/parser/data-store';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { AuthService } from 'src/app/auth/auth.service';

export interface Task extends Event {
  stateid: string;
  threadid: string;
  stateName: string;
  parsedContent: string;
  dueAt: Dayjs;
  dueAtWarning?: boolean;
}

@Component({
  selector: 'app-notification-viewer',
  templateUrl: './notification-viewer.component.html',
  styleUrls: ['./notification-viewer.component.scss'],
})
export class NotificationViewerComponent {
  loadingNotifications = true;

  activeView: 'notifications' | 'tasks' = 'notifications';
  expanded = false;

  availableNotificationFilters = notificationFilters;
  notificationFilters: ExtendedFilterCriterium[] = [];

  availableTaskFilters = taskFilters;
  taskFilters: ExtendedFilterCriterium[] = [];

  tasks: Task[] = [];

  constructor(
    private notifications: NotificationService,
    public service: CommonService,
    private translator: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private eventsService: EventsService,
    private auth: AuthService
  ) {
    this.getNotifications();
    this.getTasks();
  }

  filtersChanged() {
    this.getNotifications();
  }

  notificationPagination = {
    total: 100,
    currentPage: 0,
    pageSize: 5,
  };

  eventsPagination = {
    total: 100,
    currentPage: 0,
    pageSize: 5,
  };

  view(target: 'notifications' | 'tasks') {
    this.activeView = target;
    if (this.activeView === 'notifications') {
      this.getNotifications();
    } else {
      this.getTasks();
    }
  }

  onNotificationPagination(event: PageEvent) {
    this.notificationPagination.currentPage = event.pageIndex;
    this.getNotifications();
  }

  onEventsPagination(event: PageEvent) {
    this.eventsPagination.currentPage = event.pageIndex;
    this.getTasks();
  }

  private async getNotifications(): Promise<void> {
    this.loadingNotifications = true;
    this.notifications
      .queryNotifications({
        filterViewPagination: {
          filter: [
            {
              operand: 'seen',
              operator: 'eq',
              value: false,
            },
            ...this.notificationFilters,
          ],
          pagination: {
            page: this.notificationPagination.currentPage + 1,
            rows_per_page: this.notificationPagination.pageSize,
          },
          view: {
            displayed_columns: [
              {
                display_name: 'id',
                internal_name: 'id',
              },
              {
                display_name: 'meta',
                internal_name: 'meta',
              },
              {
                display_name: 'type',
                internal_name: 'type',
              },
              {
                display_name: 'send_at',
                internal_name: 'send_at',
              },
              {
                display_name: 'stateid',
                internal_name: 'state_id',
              },
              {
                display_name: 'stateName',
                internal_name: 'state.file_name',
              },
              {
                display_name: 'threadid',
                internal_name: 'threadid',
              },
            ],
            hidden_columns: [],
            sort_by: {
              by: 'send_at',
              direction: 'desc',
            },
          },
        },
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return of(null);
        })
      )
      .subscribe((response) => {
        this.loadingNotifications = false;
        this.service.notifications = response?.notifications || [];
        this.notificationPagination.total =
          response?.total_entries ?? this.notificationPagination.total ?? 0;
      });
  }

  getTasks() {
    dayjs.extend(customParseFormat);
    this.eventsService
      .queryEvents({
        filterViewPagination: {
          filter: [
            {
              operand: 'due_at',
              operator: 'isNotNull'
            },
            {
              operand: 'event_type',
              operator: 'includedIn',
              value: ScheduledNodeEventType,
            },
            {
              operand: 'done',
              operator: 'ne',
              value: true,
            },
            {
              operator: 'or',
              filters: [
                {
                  operand: 'threads!.lawyers.id',
                  operator: 'eq',
                  value: this.auth.userId,
                },
                {
                  operand: 'states!.lawyers.id',
                  operator: 'eq',
                  value: this.auth.userId,
                },
              ],
            },
            ...this.taskFilters,
          ],
          pagination: {
            page: this.eventsPagination.currentPage + 1,
            rows_per_page: this.eventsPagination.pageSize,
          },
          view: {
            sort_by: {
              by: 'due_at',
              direction: 'asc',
            },
            displayed_columns: [
              ...DEFAULT_EVENT_FIELDS,
              {
                display_name: 'stateName',
                internal_name: 'states!.file_name',
              },
            ],
          },
        },
      })
      .subscribe((result) => {
        this.eventsPagination.total = result.total_entries;
        this.tasks = result.events.map((e) => {
          const parsedContent =
            parseWithPlaceholders(
              e?.event_content?.chat?.[0]?.content ?? 'Ereignis',
              new DataStore(),
              ''
            )?.body?.innerHTML ?? 'Ereignis';
          const dueAt = e.due_at
            ? dayjs(e.due_at.slice(0, 10), 'DD.MM.YYYY')
            : undefined;
          return {
            ...e,
            parsedContent,
            stateid: e['state_ids'],
            threadid: e['thread_ids'],
            dueAt,
            dueAtWarning: dueAt && dueAt.diff(dayjs(), 'days') <= 3,
          } as Task;
        });
      });
  }

  markAsRead(notificationid: string): void {
    this.notifications
      .markAsSeen({
        id: notificationid,
      })
      .subscribe(() => {
        this.service.notifications = this.service.notifications.filter(
          (not) => not.id !== notificationid
        );
        this.notificationPagination.total--;
      });
  }

  markAllAsRead() {
    this.notifications
      .markAllAsSeen({})
      .subscribe(() => {
        this.service.notifications = [];
        this.notificationPagination.total = 0;
      });
  }

  parseSender(notification: Notification): string {
    return parseFromAssigned(
      [(notification.meta as any).sender ?? ''],
      this.translator,
      'unbekannt'
    );
  }

  viewNotification(notification: Notification | Task) {
    const realm = this.activatedRoute.snapshot.params.realm;
    this.router.navigateByUrl(
      `/${realm}/intern/s/${notification.stateid}/messages/${notification.threadid}`
    );
  }
}
