import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { PublicService, Realm } from 'src/api';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-realm-selection',
  templateUrl: './realm-selection.component.html',
  styleUrls: ['./realm-selection.component.scss'],
})
export class RealmSelectionComponent {
  realmData = new UntypedFormGroup({
    realm: new UntypedFormControl(undefined),
  });

  errorMessage: string | undefined = undefined;

  realms: BehaviorSubject<Realm[]> = new BehaviorSubject([]);

  API_URL = environment.API_URL;

  constructor(
    private router: Router,
    private publicService: PublicService,
    private auth: AuthService,
    private translator: TranslateService
  ) {
    this.auth.ready$.subscribe(() => {
      if (this.auth.loggedIn && this.auth.rawDecoded?.realm) {
        this.router.navigate([this.auth.rawDecoded.realm, 'login']);
        return;
      }
    });
    this.realmData
      .get('realm')
      .valueChanges.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((realm) => {
          if (typeof realm === 'string') {
            return this.getRealmsFor(realm);
          } else {
            return of(this.realms.value);
          }
        })
      )
      .subscribe(this.realms);
  }

  private getRealmsFor(realmName: string): Observable<Realm[]> {
    return this.publicService.listRealms({
      name: realmName,
    });
  }

  checkValid() {
    const inList = this.realms.value.some(
      (realm) => realm?.slug == this.realmData.get('realm')?.value?.slug
    );
    const matchesRegex =
      typeof this.realmData.get('realm')?.value === 'string' &&
       this.realmData.get('realm')?.value?.match(/^[a-z\-]+$/m);
    const valid = inList || matchesRegex;
    if (!valid) {
      this.realmData.get('realm').setValue(undefined);
    }
  }

  selectRealm() {
    this.checkValid();
    if (!this.realmData.get('realm').value?.slug?.length) {
      if (!this.realmData.get('realm').value?.length) {
        return;
      }
      const selectedRealm = this.realmData.get('realm').value.trim();
      this.publicService
        .checkRealm({
          realm: selectedRealm,
        })
        .pipe(
          catchError(() => {
            return of(false);
          })
        )
        .subscribe((value) => {
          if (!value) {
            this.errorMessage = this.translator.instant(
              'common.error.noSuchRealm'
            );
            return;
          }
          this.navigateToRealm(selectedRealm);
        });
      return;
    }
    this.navigateToRealm(this.realmData.get('realm').value.slug);
  }

  private navigateToRealm(realmSlug: string) {
    this.router.navigate([realmSlug, 'intern']);
  }

  getRealmName(realm: Realm) {
    return realm?.name;
  }
}
