/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DataViewTableGroupDefinition { 
    /**
     * Relevant for dates: Group at which level (years, days, hours, etc.)
     */
    granularity?: DataViewTableGroupDefinition.GranularityEnum;
    key?: string;
    label?: string;
}
export namespace DataViewTableGroupDefinition {
    export type GranularityEnum = 'year' | 'month' | 'day' | 'hour' | 'minute';
    export const GranularityEnum = {
        Year: 'year' as GranularityEnum,
        Month: 'month' as GranularityEnum,
        Day: 'day' as GranularityEnum,
        Hour: 'hour' as GranularityEnum,
        Minute: 'minute' as GranularityEnum
    };
}


