import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TableComponent } from './table/table.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ComparisonInputComponent } from './comparison-input/comparison-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConditionEditComponent } from './condition-edit/condition-edit.component';
import { RefidInputComponent } from './refid-input/refid-input.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { DataRenderTableComponent } from './data-render-table/data-render-table.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { SettingsViewComponent } from './settings-view/settings-view.component';
import { RichSettingEditorComponent } from './settings-view/rich-setting-editor.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { IconPickerComponent } from './icon-picker/icon-picker.component';
import { ParticipantSelectMenuComponent } from './participant-select-menu/participant-select-menu.component';
import { AttachmentSelectMenuComponent } from './attachment-select-menu/attachment-select-menu.component';
import { AttachmentListComponent } from './attachment-list/attachment-list.component';
import { CustomToolTipComponent } from './custom-tool-tip/custom-tool-tip.component';
import { ToolTipRendererDirective } from './custom-tool-tip/directive';
import { QuestionAnswersModule } from '../views/process/question-answers/question-answers.module';
import { PipesModule } from '../pipes/pipes.module';
import { ContextMenuDirective } from './context-menu/directive';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { UserPeekOverlayComponent } from './user-peek-overlay/user-peek-overlay.component';
import { UserPeekRenderDirective } from './user-peek-overlay/directive';
import { DossierAssignedInputComponent } from './dossier-assigned-input/dossier-assigned-input.component';
import { SearchBreadcrumbsComponent } from './table/search-breadcrumbs.component';
import { HistoryPipe } from './data-render-table/history.pipe';
import { ProfileChipComponent } from './profile-chip/profile-chip.component';
import { KeycloakOverlayComponent } from './keycloak-overlay/keycloak-overlay.component';
import { NotificationViewerComponent } from './notification-viewer/notification-viewer.component';
import { handleTableCellPipe } from './table/handle-table-cell.pipe';
import { TablePaginatorComponent } from './table/table-paginator.component';
import { DueAtSelectorComponent } from './due-at-selector/due-at-selector.component';
import { DueAtSelectorDirective } from './due-at-selector/directive';
import { TimepickerComponent } from './due-at-selector/timepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SearchMenuModule } from './search-menu/search-menu.module';
import {ReadReceiptComponent} from "../views/process/chat-message/read-receipt/read-receipt.component";

@NgModule({
  declarations: [
    TableComponent,
    ReadReceiptComponent,
    ComparisonInputComponent,
    ConditionEditComponent,
    RefidInputComponent,
    DataRenderTableComponent,
    SettingsViewComponent,
    RichSettingEditorComponent,
    IconPickerComponent,
    ParticipantSelectMenuComponent,
    AttachmentSelectMenuComponent,
    AttachmentListComponent,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    ContextMenuDirective,
    ContextMenuComponent,
    UserPeekOverlayComponent,
    UserPeekRenderDirective,
    DossierAssignedInputComponent,
    SearchBreadcrumbsComponent,
    HistoryPipe,
    ProfileChipComponent,
    KeycloakOverlayComponent,
    NotificationViewerComponent,
    handleTableCellPipe,
    TablePaginatorComponent,
    DueAtSelectorComponent,
    DueAtSelectorDirective,
    TimepickerComponent,
  ],
  exports: [
    TableComponent,
    TablePaginatorComponent,
    ComparisonInputComponent,
    ConditionEditComponent,
    DataRenderTableComponent,
    SettingsViewComponent,
    IconPickerComponent,
    ParticipantSelectMenuComponent,
    AttachmentSelectMenuComponent,
    AttachmentListComponent,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    ContextMenuDirective,
    UserPeekRenderDirective,
    DossierAssignedInputComponent,
    SearchBreadcrumbsComponent,
    ProfileChipComponent,
    KeycloakOverlayComponent,
    NotificationViewerComponent,
    RichSettingEditorComponent,
    DueAtSelectorDirective,
    ReadReceiptComponent,
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatDialogModule,
    TranslateModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    DragDropModule,
    CdkTableModule,
    ColorPickerModule,
    QuestionAnswersModule,
    PipesModule,
    MatDatepickerModule,
    SearchMenuModule,
  ],
})
export class WidgetsModule {}
