<h1 mat-dialog-title translate>
  {{ 'process.label.createFile' | translate }}
</h1>

<div class="data-input">
  <mat-form-field appearance="outline">
    <mat-label>{{ 'common.label.name' | translate }}</mat-label>
    <input matInput [formControl]="dataForm.get('name')" cdkFocusRegionStart />
  </mat-form-field>
  <mat-form-field appearance="outline" *ngIf="!data?.hidePath">
    <mat-label>{{ 'node.label.targetFilePath' | translate }}</mat-label>
    <input matInput [formControl]="dataForm.get('path')" />
  </mat-form-field>
  <app-search-menu [entries]="queryTemplates.bind(this)" (entrySelected)="selectTemplate($event)"
    *ngIf="permissions.canRead('templates')">
    <button mat-button class="template-selection">
      <mat-icon>sticky_note_2</mat-icon>
      <ng-container *ngIf="!dataForm.get('template')?.value">
        {{ 'common.button.selectTemplate' | translate }}
      </ng-container>
      <ng-container *ngIf="!!dataForm.get('template')?.value">
        {{ dataForm.get('template').value.name }}
      </ng-container>
    </button>
  </app-search-menu>
</div>

<mat-dialog-actions>
  <button mat-button (click)="dialogRef.close()">
    {{ "common.button.abort" | translate }}
  </button>
  <button mat-raised-button (click)="save()" [disabled]="!dataForm.get('name')?.value?.length">
    <mat-icon> save </mat-icon>
    {{ "common.button.save" | translate }}
  </button>
</mat-dialog-actions>