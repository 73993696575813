import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CorporateIdentityService } from 'src/api/api/corporateIdentity.service';
import { AuthService } from '../auth/auth.service';
import { PublicService } from 'src/api/api/public.service';
import { CorporateIdentity } from 'src/api/model/corporateIdentity';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomCIService {
  customLogo: SafeResourceUrl = null;
  customIdentity?: CorporateIdentity = null;

  // ToDo: This should be set only for specific tenants
  public applyToInternal = true;

  public ciLoaded$ = new Subject<void>();

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    public auth: AuthService,
    private clientService: PublicService,
    private ciService: CorporateIdentityService,
    private activatedRoute: ActivatedRoute,
    private translator: TranslateService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          return this.activatedRoute?.children[0].snapshot.paramMap?.get(
            'realm'
          );
        }),
        distinctUntilChanged()
      )
      .subscribe((realm) => {
        if (this.router.url.indexOf('/print') !== -1) {
          const previousCI = window.opener?.__adv_ci;
          if (previousCI?.ci) {
            this.applyColors(realm);
            this.applyLawFirmInfo(previousCI.ci);
            this.setGlobalValue();
          }
          if (previousCI?.logo) {
            this.customLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
              previousCI.logo
            );
            this.setGlobalValue();
          }
          return;
        }
        const isLawyer =
          window?.location?.pathname?.split('/')?.[2] == 'intern';
        if (realm && (!isLawyer || this.applyToInternal)) {
          this.applyCorporateIdentity(realm);
        } else {
          this.removeColorScheme();
          this.removeCorporateIdentity();
          this.removeCustomLogo();
          this.removeCorporateIdentity();
        }
      });
  }

  async getCorporateIdentity(): Promise<void> {
    if (this.auth.loggedIn) {
      this.customIdentity = await this.ciService
        .getCorporateIdentity({})
        .toPromise();
      this.setGlobalValue();
    }
  }

  async updateCorporateIdentity(
    corporateIdentity: CorporateIdentity
  ): Promise<void> {
    await this.ciService
      .setCorporateIdentity({ corporateIdentity })
      .toPromise();
    this.getCorporateIdentity();
  }

  applyCorporateIdentity(realm: string): void {
    this.customLogo = null;
    this.setGlobalValue();
    // Check if logo exists
    this.clientService.clientGetCorporateLogo({ realm }).subscribe(
      (blob) => {
        this.customLogo = this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(blob)
        );
        this.setGlobalValue();
      },
      () => {
        this.customLogo = null;
        this.setGlobalValue();
      }
    );
    this.applyColors(realm);
    this.clientService.clientGetCorporateIdentity({ realm }).subscribe(
      (resp: CorporateIdentity) => {
        this.applyLawFirmInfo(resp);
      },
      () => {
        this.removeColorScheme();
      }
    );
  }

  private applyColors(realm: string): void {
    this.removeColorScheme();
    document
      .querySelectorAll('.custom-ci-style-css')
      .forEach((e) => e.remove());
    const l = document.createElement('link');
    l.classList.add('custom-ci-style-css');
    l.href = `${environment.API_URL}/public/meta/ci/${realm}/style.css`;
    l.rel = 'stylesheet';
    document.head.appendChild(l);
  }

  private applyLawFirmInfo(info: CorporateIdentity): void {
    this.customIdentity = info;
    this.setGlobalValue();

    // apply translations
    if (info.translations?.length) {
      for (const t of info.translations) {
        this.translator.setTranslation(
          t.language,
          t.translationsOverride,
          true
        );
      }
    }
    this.ciLoaded$.next();
  }

  removeCustomLogo(): void {
    this.customLogo = null;
    this.setGlobalValue();
  }

  removeCorporateIdentity(): void {
    this.customIdentity = null;
    this.setGlobalValue();
    this.translator.reloadLang(this.translator.currentLang);
  }

  removeColorScheme(): void {
    document
      .querySelectorAll('.custom-ci-style-css')
      .forEach((e) => e.remove());
  }

  private hexToHSL(H): number[] {
    let [r, g, b]: any = [0, 0, 0];
    if (H.length === 4) {
      r = '0x' + H[1] + H[1];
      g = '0x' + H[2] + H[2];
      b = '0x' + H[3] + H[3];
    } else if (H.length === 7) {
      r = '0x' + H[1] + H[2];
      g = '0x' + H[3] + H[4];
      b = '0x' + H[5] + H[6];
    }
    r /= 255;
    g /= 255;
    b /= 255;
    const cmin = Math.min(r, g, b);
    const cmax = Math.max(r, g, b);
    const delta = cmax - cmin;
    let [h, s, l]: any = [0, 0, 0];
    if (delta === 0) {
      h = 0;
    } else if (cmax === r) {
      h = ((g - b) / delta) % 6;
    } else if (cmax === g) {
      h = (b - r) / delta + 2;
    } else {
      h = (r - g) / delta + 4;
    }
    h = Math.round(h * 60);
    if (h < 0) {
      h += 360;
    }
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
    return [h, s, l];
  }

  private setGlobalValue() {
    (window as any).__adv_ci = {
      ci: this.customIdentity,
      logo: this.customLogo?.['changingThisBreaksApplicationSecurity'],
    };
  }
}
