<h3>{{ 'schedule.label.selection' | translate }}</h3>
<div class="selector">
  <ng-scrollbar>
    <div class="quickaccess">
      <button class="quickaccess-option" *ngFor="let option of quickaccessOptions"
        (click)="selectQuickAccessOption(option)" [class.active]="activeOption === option">
        <span *ngIf="option.prefix">{{option.prefix | translate}}</span>
        <input *ngIf="option.dynamicNumber" [(ngModel)]="option.value" type="number" min="0" />
        <span *ngIf="option.suffix">{{option.suffix | translate}}</span>
      </button>
    </div>
    <div class="time-selection">
      <mat-checkbox [checked]="useTime" (change)="useTime = $event.checked; useTimeChange.next(useTime)">
        {{ 'schedule.label.withTime' | translate }}
      </mat-checkbox>
      <app-timepicker label="{{ 'schedule.label.startTime' | translate }}" [(hour)]="timeStart.hours"
        [(minute)]="timeStart.minutes" *ngIf="useTime" (hourChange)="updateActive()"
        (minuteChange)="updateActive()"></app-timepicker>
      <app-timepicker label="{{ 'schedule.label.endTime' | translate }}" [(hour)]="timeEnd.hours"
        [(minute)]="timeEnd.minutes" [min]="timeStart.hours * 60 + timeStart.minutes" *ngIf="useTime && !!allowEndTime"
        (hourChange)="updateActive()" (minuteChange)="updateActive()" [active]="endTimeActive"
        (activeChange)="endTimeActive = $event;updateActive()" [required]="false"></app-timepicker>
    </div>
  </ng-scrollbar>
  <div class="calendar">
    <mat-calendar [selected]="selectedDate" (selectedChange)="calendarChanged($event)" #calendar></mat-calendar>
  </div>
</div>
<div class="selection-indicator">
  <button mat-button (click)="dueAtChanged.emit(undefined)" [disabled]="!allowDelete">
    <mat-icon>delete</mat-icon>
    <span>{{ 'schedule.button.clearDueAt' | translate }}</span>
  </button>
  <button mat-icon-button (click)="close.emit()">
    <mat-icon>check</mat-icon>
  </button>
</div>