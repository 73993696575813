import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EnhancedFeaturesService } from 'src/api';
import {
  DocumentExportFormat,
  ProcessNode,
  ProcessParser,
  QuestionNode,
  SketchParser,
} from 'advoprocess';
import {
  PreviewNode,
  RichTextEditorComponent,
} from 'src/app/widgets/rich-text-editor/rich-text-editor.component';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import { getContrastYIQ } from 'src/app/common/helpers';
import { NodeContext } from '../lawyer/process/editor/node/node.component';
import { AuthService } from 'src/app/auth/auth.service';
import { FormNode } from 'advoprocess/lib/nodes/default-nodes/form.node';
import * as _ from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { isBoolean } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { downloadAsDocX } from './export-as-docx';
import { NgScrollbar } from 'ngx-scrollbar';
import DataStore from 'advoprocess/lib/parser/data-store';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  animations: [
    trigger('sideBarAnimation', [
      transition(':enter', [
        style({ marginRight: '-450px' }),
        animate('250ms ease-out', style({ marginRight: '0px' })),
      ]),
      transition(':leave', [
        style({ marginRight: '0px' }),
        animate('250ms ease-out', style({ marginRight: '-450px' })),
      ]),
    ]),
    trigger('scaleAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0)' }),
        animate('250ms ease-out', style({ transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)' }),
        animate('250ms ease-out', style({ transform: 'scale(0)' })),
      ]),
    ]),
  ],
})
export class DocumentComponent implements OnChanges {
  @Input() document: { value: string; options?: any };
  @Input() readonly?: boolean;
  @Input() dataSource?: ProcessNode[];
  @Input() dataStore?: DataStore;
  @Input() ownNode: ProcessNode;
  @Input() fileName?: string;
  @Input() lockEdit: boolean = false;
  @Input() zoom = 1;

  rightPaneOpen = false;
  docSettingsOpen = false;

  loading = false;

  @Output() docChange = new EventEmitter<boolean>();
  @Output() zoomChange = new EventEmitter<number>();

  highlightNode = new BehaviorSubject<PreviewNode | undefined>(undefined);

  tempNodeSettings: { [key: string]: { node: QuestionNode }[] } = {};

  nodeContext: NodeContext = {
    currentEditorLocation: undefined,
  };

  saveInterval = null;

  previewNode: PreviewNode = null;

  oldDocHeight = -1;

  generatingPDF = false;

  @ViewChild('textEditor') textEditor: RichTextEditorComponent;
  @ViewChild('sideScrollBar') scrollBar: NgScrollbar;

  constructor(
    private dialog: DialogService,
    private matSnackBar: MatSnackBar,
    private enhacedAPI: EnhancedFeaturesService,
    public auth: AuthService,
    private el: ElementRef
  ) {
    this.highlightNode
      .pipe(distinctUntilChanged((x, y) => x?.oldRefId === y?.oldRefId))
      .subscribe((prev) => {
        if (this.dataSource && prev) {
          this.scrollToNode(prev.node._id, prev.oldRefId);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && !_.isNil(this.dataSource)) {
      this.buildTempNodeSettings();
    }
    if (changes.zoom) {
      setTimeout(() => {
        this.setZoom(this.zoom);
      }, 50);
    }
  }

  textChange(): void {
    window.clearInterval(this.saveInterval);
    this.saveInterval = window.setTimeout(() => {
      this.docChange.emit(true);
    }, 2500);
  }

  async downloadAsDocX(): Promise<void> {
    const c = this.el?.nativeElement?.querySelector('.jodit-wysiwyg');
    if (!c) return;
    this.loading = true;
    await downloadAsDocX(c, this.document);
    this.loading = false;
  }

  downloadAsPDF(mode: DocumentExportFormat = 'default'): void {
    if (mode === 'word') {
      // ToDo (later)
      return;
    }
    this.generatingPDF = true;
    const code = this.textEditor.editor.value;
    this.enhacedAPI
      .generatePdf({
        fileHTML: {
          html_code: code,
          format: mode,
          options:
            {
              ...this.document?.options,
              letterhead: this.document?.options?.letterhead
                ? _.isString(this.document?.options?.letterhead)
                  ? this.document?.options?.letterhead
                  : this.document?.options?.letterhead?.value?.id
                : undefined,
            } ?? {},
        },
      })
      .pipe(
        catchError((error) => {
          let err;
          let matSnackBar = this.matSnackBar;
          const b = new Blob([error.error], { type: 'application/json' });
          const fr = new FileReader();
          fr.onload = (evt) => {
            err = evt.target.result;
            matSnackBar.open(JSON.parse(err).error, null, {
              duration: 3000,
            });
          };
          fr.readAsText(b);
          return of(false);
        })
      )
      .subscribe((data) => {
        if (!isBoolean(data)) {
          window.open(
            URL.createObjectURL(new Blob([data], { type: 'application/pdf' })),
            '_blank'
          );
        }
        this.generatingPDF = false;
      });
  }

  setRefId(node: ProcessNode) {
    const oldRefId = node?.node?.refId;
    this.dialog
      .prompt('Bezeichnung für diesen Knoten', { value: node.node.refId })
      .then(
        (value) => {
          node.node.refId = value?.replace(/[\s]*/gm, '') || node.node.refId;
          this.textEditor.saveNodeRefId(node, oldRefId);
        },
        () => {}
      );
  }

  setNodeMode(node: ProcessNode, mode: any) {
    node?.node?.onModeChange(mode.value);
    this.textEditor.saveNodeRefId(node, node.node.refId);
  }

  useLightText(color: string): boolean {
    if (!color) {
      return false;
    }
    return getContrastYIQ(color) < 200;
  }

  buildTempNodeSettings() {
    this.dataSource.forEach((node) => {
      if (node.node instanceof FormNode) {
        this.tempNodeSettings[node._id] = node.node.config.questions.value.map(
          (field) => {
            const config = _.cloneDeep(field.question);
            Object.keys(config).forEach((key) => {
              config[key] = config[key].value;
            });
            const questionNode = SketchParser.buildNode(
              'QuestionNode',
              config
            ) as QuestionNode;
            questionNode.refId = field.refId;
            if (!field.condition?.length) {
              field.condition = [[{ refId: '', operator: '=', value: '' }]];
            }
            questionNode.ctx = {
              nodes: this.dataSource,
            } as ProcessParser;
            return { node: questionNode };
          }
        );
      }
    });
  }

  onListScroll(event) {
    const scrollEl: HTMLElement = event.target;
    const scrollPos = scrollEl.scrollTop;
    const elHeight = scrollEl.offsetHeight;

    scrollEl
      .querySelectorAll('.ng-scroll-content > *')
      .forEach((el: HTMLElement) => {
        if (
          el.offsetTop + el.offsetHeight < scrollPos ||
          el.offsetTop > scrollPos + elHeight
        ) {
          el.style.visibility = 'hidden';
        } else {
          el.style.visibility = null;
        }
      });
  }

  scrollToNode(id: string, refId: string) {
    /* Show option parent */
    setTimeout(() => {
      let targetEl = this.scrollBar.nativeElement.querySelector(
        `[node-id="${id}"]`
      );
      if (!targetEl) return;
      if (
        targetEl.parentElement?.classList.contains('form-node') &&
        targetEl.parentElement?.classList.contains('expanded')
      ) {
        targetEl = targetEl.parentElement.querySelector(`[refid="${refId}"]`);
      }
      if (!targetEl) return;
      this.scrollBar.scrollToElement(targetEl as HTMLElement);
      setTimeout(() => {
        targetEl.parentElement?.classList.add('highlight');
      }, 500);
      setTimeout(() => {
        targetEl.parentElement?.classList.remove('highlight');
      }, 500 + 750);
    }, 0);
  }

  setZoom(level: number) {
    this.zoom = Math.max(Math.min(level ?? 1, 4), 0.2);
    this.el.nativeElement.querySelector('.jodit-wysiwyg').style.zoom =
      this.zoom;
    this.zoomChange.emit(this.zoom);
  }
}
